/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {CardContent, FormControl, Grid} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {images} from '../../../constants/image';
import useAuth from '../../../hooks/useAuth';

import {
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
  Modal,
  DateTimePicker,
  AspectSelectImage,
  Loader,
} from '../../../components';

// style
import {
  Card,
  Typography,
  StyledModalTitle,
  StyledIconClose,
  Divider,
} from './ModalUpdate.style';
import MemberDashBoardService from '../../../services/MemberDashboard/member-dashboard.service';
import MobileAssetsService from '../../../services/MobileAssets/mobile-assets.service';
import SimpleSelectFilter from '../../../components/Selects/SimpleSelectFilter/SimpleSelectFilter';
import {CTA_LINK_TYPE} from '../../../constants/status';
import {
  cTALinkTypeOptions,
  radioOptions,
} from '../../../constants/memberDashboardContanst';
import {formatDateTime, formatLocalDateFromUTCTime} from '../../../utils';

interface DataDetail {
  title?: string;
  description?: string;
  gradientColor?: number;
  mobileAssetId?: string;
  appCTALink?: string;
  webCTALink?: string;
  displayOrder?: number | undefined;
  cTALinkType?: number | undefined;
  cTALink?: string;
  image?: string;
  startDateTime?: string;
  endDateTime?: string;
}

interface ModalUpdate {
  id: number;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
  radioOptionsAppCTALink?: any;
}

interface DateTime {
  startDateTime?: string | null;
  endDateTime?: string | null;
}

function ModalUpdate({
  id,
  visible,
  onClose,
  reloadPage,
  radioOptionsAppCTALink,
}: ModalUpdate) {
  const sizeLimit = 1000;
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const formatDateTimePicker = 'MM/dd/yyyy hh:mm aa';
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [memberDashboardAssetImages, setMemberDashboardAssetImages] = useState(
    []
  );
  const [valueRangerPicker, setValueRangerPicker] = useState<any>({
    startDateTime: null,
    endDateTime: null,
  });
  const [showErrorSizeLimite, setShowErrorSizeLimit] = useState(false);
  const [helperTextImage, setHelperTextImage] =
    useState<string>('Upload an image');
  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    gradientColor: 1,
    mobileAssetId: '',
    appCTALink: '',
    webCTALink: '',
    cTALinkType: 0,
    cTALink: '',
    startDateTime: '',
    endDateTime: '',
  });

  const parseCTALink = (values: any) => {
    if (values?.appCTALink !== '') {
      return radioOptionsAppCTALink.find(
        item => item.value === values?.appCTALink
      )?.url;
    }
    if (values?.webCTALink !== '') {
      return values?.webCTALink;
    }
  };

  const parseAppCTALink = value => {
    return value?.cTALinkType === CTA_LINK_TYPE.APP
      ? radioOptionsAppCTALink.find(item => {
          if (item.url !== value?.cTALink) return;
          return item.url === value?.cTALink;
        })?.value
      : '';
  };

  const parseWebCTALink = value => {
    return value?.cTALinkType === CTA_LINK_TYPE.WEB ? value?.cTALink : '';
  };

  const onSubmit = values => {
    setLoading(true);
    new MemberDashBoardService()
      .UpdateMemberDashboardAsset(id, {
        ...values,
        gradientColor: Number(initialValues?.gradientColor),
        mobileAssetId: initialValues?.mobileAssetId,
        appCTALink: undefined,
        webCTALink: undefined,
        cTALink: parseCTALink(values),
        cTALinkType:
          values?.cTALinkType !== 0 ? values?.cTALinkType : undefined,
        image: undefined,
        startDateTime: formatDateTime(values.startDateTime),
        endDateTime: formatDateTime(values.endDateTime),
      })
      .then(res => {
        if (res !== null && res.success) {
          if (
            memberDashboardAssetImages.length > 0 &&
            typeof memberDashboardAssetImages[0] !== 'string'
          ) {
            new MemberDashBoardService()
              .uploadImage(
                res.data.id,
                new File(
                  memberDashboardAssetImages,
                  'mastheadDashboardAssetImages.jpeg'
                )
              )
              .then(resImg => {
                if (resImg) {
                  showNotification({
                    message: 'Masthead updated!',
                  });
                  resetForm();
                  reloadPage();
                }
                setLoading(false);
              });
          } else {
            showNotification({
              message: 'Masthead updated!',
            });
            resetForm();
            reloadPage();
          }
        } else {
          setLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
    onClose();
  };

  const GetDashboardAssetDetail = () => {
    setFormLoading(true);
    new MemberDashBoardService().GetOneMemberDashboardAsset(id).then(res => {
      if (res?.status === 200) {
        const item = res?.data?.data;
        setInitialValues({
          title: item?.title,
          description: item?.description,
          gradientColor: item?.gradientColor,
          mobileAssetId: item?.mobileAssetId,
          cTALinkType: item?.cTALinkType ?? 0,
          status: item?.status,
          image: item?.image,
          startDateTime: item?.startDateTime,
          endDateTime: item?.endDateTime,
          webCTALink: parseWebCTALink(item),
          appCTALink: parseAppCTALink(item),
          cTALink: item?.cTALink,
        });
        setMemberDashboardAssetImages([item?.image]);
        setValueRangerPicker({
          startDateTime: item.startDateTime,
          endDateTime: item.endDateTime,
        });
      }
      setFormLoading(false);
    });
  };

  const resetForm = () => {
    setInitialValues({
      title: '',
      description: '',
      gradientColor: 1,
      mobileAssetId: '',
      cTALink: '',
      status: undefined,
      cTALinkType: 0,
      webCTALink: '',
      appCTALink: '',
      image: '',
      startDateTime: '',
      endDateTime: '',
    });
    setValueRangerPicker({startDateTime: null, endDateTime: null});
    setMemberDashboardAssetImages([]);
  };

  const renderDescCTALink = (value: number) => {
    switch (value) {
      case cTALinkTypeOptions[1].value:
        return 'Select Redirection to 1 of the App page from Masthead';
      case cTALinkTypeOptions[2].value:
        return 'Insert link for Redirection to any Web page from Masthead';
      default:
        return '';
    }
  };

  const onUploadImages = images => {
    setMemberDashboardAssetImages(images);
  };

  useEffect(() => {
    if (showErrorSizeLimite === true) {
      setHelperTextImage(
        `File is too big, please upload an image of a maximum file size 1MB`
      );
    }

    if (
      memberDashboardAssetImages.length === 0 &&
      showErrorSizeLimite !== true
    ) {
      setHelperTextImage(`Upload an image`);
    }
  }, [showErrorSizeLimite, memberDashboardAssetImages]);

  useEffect(() => {
    GetDashboardAssetDetail();
  }, []);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Update Masthead</StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />

        {!formLoading && (
          <DialogContent>
            <Formik
              initialValues={{
                ...initialValues,
              }}
              enableReinitialize
              validationSchema={values => {
                return Yup.object().shape({
                  title: Yup.string()
                    .required('Fill in the title')
                    .max(42, 'Maximum 42 characters including spaces'),
                  description: Yup.string()
                    .required('Fill the description')
                    .max(150, 'Maximum 150 characters including spaces'),
                  gradientColor: Yup.number().integer(),
                  appCTALink:
                    initialValues?.cTALinkType === cTALinkTypeOptions[1].value
                      ? Yup.number().required('Select 1 App page')
                      : Yup.number(),
                  webCTALink:
                    initialValues?.cTALinkType === cTALinkTypeOptions[2].value
                      ? Yup.string().required('Enter link')
                      : Yup.string(),
                  startDateTime: Yup.date()
                    .typeError('Please input a valid start date and time')
                    .required('Select the start date and time'),
                  endDateTime: Yup.date()
                    .typeError('Please input a valid end date and time')
                    .required('Select the end date and time'),
                });
              }}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Card>
                    <CardContent sx={{padding: 0}}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={10}
                        mb="50px"
                      >
                        <Grid item xs={12}>
                          <FormControl fullWidth margin="normal">
                            <Typography variant="subtitle2" mb={2}>
                              Title <span className="mandatory-label">(*)</span>
                              <Typography variant="body2" fontStyle="italic">
                                Maximum 42 characters including spaces
                              </Typography>
                            </Typography>
                            <Input
                              placeholder="Enter Title"
                              size="small"
                              name="title"
                              value={values.title}
                              variant="outlined"
                              helperText={touched.title && errors.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.title && errors.title)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth margin="normal">
                            <Typography variant="subtitle2" mb={2}>
                              Description{' '}
                              <span className="mandatory-label">(*)</span>
                              <Typography variant="body2" fontStyle="italic">
                                Maximum 150 characters including spaces
                              </Typography>
                            </Typography>
                            <TextArea
                              label=""
                              placeholder="Enter Description"
                              maxRows={2}
                              name="description"
                              value={values.description}
                              helperText={
                                touched.description && errors.description
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.description && errors.description
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="standard" fullWidth>
                            <Typography variant="subtitle2" mb={2} mt={3}>
                              Background Gradient Colour
                            </Typography>
                            <RadioGroups
                              name="gradientColor"
                              options={radioOptions}
                              values={initialValues.gradientColor}
                              onChange={e => {
                                handleChange(e);
                                const newInitialValues = initialValues;
                                newInitialValues.gradientColor = e.target.value;
                                setInitialValues(newInitialValues);
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{marginBottom: '20px'}}>
                            <Typography variant="subtitle2" mb={2}>
                              Masthead Asset{' '}
                              <span className="mandatory-label">(*)</span>{' '}
                              <Typography variant="body2" fontStyle="italic">
                                Image must be a minimum of 375 x 600 pixels in
                                standard resolution or a maximum of 750 x 1200
                                pixels in high resolution, in portrait
                                orientation, with a maximum file size of 1MB
                              </Typography>
                            </Typography>
                            <AspectSelectImage
                              minWidth={375}
                              minHeight={600}
                              maxWidth={750}
                              maxHeight={1200}
                              sizeLimit={sizeLimit}
                              setShowErrorSizeLimit={setShowErrorSizeLimit}
                              styleErrorMessage={{
                                width: '100%',
                                margin: '4px 14px 0px 14px',
                                paddingLeft: '12px',
                                display: 'block',
                              }}
                              isUploadImage={true}
                              helperText={helperTextImage}
                              showError={
                                ((values?.image === null ||
                                  values?.image === '') &&
                                  touched.image) ||
                                showErrorSizeLimite
                              }
                              defaultImages={memberDashboardAssetImages}
                              setDefaultImages={images => {
                                onUploadImages(images);
                                setFieldValue('image', images[0]);
                              }}
                            />
                            {(values?.image === null || values?.image === '') &&
                              touched.image && (
                                <Typography
                                  variant="body2"
                                  color="#d32f2f"
                                  sx={{
                                    marginLeft: '14px',
                                    fontSize: '0.6964285714285714rem',
                                  }}
                                  mb={2}
                                >
                                  Masthead Asset is required
                                </Typography>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth sx={{marginBottom: '20px'}}>
                            <Typography variant="subtitle2" mb={2}>
                              CTA Link Type
                            </Typography>
                            <RadioGroups
                              name="cTALinkType"
                              options={cTALinkTypeOptions}
                              values={initialValues.cTALinkType}
                              onChange={e => {
                                setFieldValue(
                                  'cTALinkType',
                                  Number(e.target.value)
                                );
                                setFieldValue('webCTALink', '');
                                setFieldValue('appCTALink', '');
                                const newInitialValues = initialValues;
                                newInitialValues.cTALinkType = Number(
                                  e.target.value
                                );
                                newInitialValues.appCTALink = '';
                                newInitialValues.webCTALink = '';
                                setInitialValues(newInitialValues);
                              }}
                            />
                          </FormControl>
                        </Grid>
                        {values?.cTALinkType ===
                          cTALinkTypeOptions[1].value && (
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                App CTA Link{' '}
                                <Typography
                                  variant="body2"
                                  sx={{display: 'inline-block'}}
                                >
                                  [
                                  {renderDescCTALink(
                                    Number(values?.cTALinkType)
                                  )}
                                  ]
                                </Typography>{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Select
                                labelId="appCTALink"
                                name="appCTALink"
                                size="small"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select link"
                                onChangeSelect={e => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                helperText={
                                  touched.appCTALink && errors.appCTALink
                                }
                                error={Boolean(
                                  touched.appCTALink && errors.appCTALink
                                )}
                                value={values.appCTALink}
                                selectOptions={radioOptionsAppCTALink}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {values?.cTALinkType ===
                          cTALinkTypeOptions[2].value && (
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                Web CTA Link{' '}
                                <Typography
                                  variant="body2"
                                  sx={{display: 'inline-block'}}
                                >
                                  [
                                  {renderDescCTALink(
                                    Number(values?.cTALinkType)
                                  )}
                                  ]
                                </Typography>{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Input
                                placeholder="Input Link"
                                size="small"
                                name="webCTALink"
                                value={values.webCTALink}
                                variant="outlined"
                                helperText={
                                  touched.webCTALink && errors.webCTALink
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.webCTALink && errors.webCTALink
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mb={2}>
                            Schedule Masthead Display{' '}
                            <span className="mandatory-label">(*)</span>{' '}
                          </Typography>
                          <Grid container xs={12}>
                            <Grid item xs={12}>
                              <FormControl fullWidth margin="dense">
                                <Typography variant="body2" mb={2}>
                                  Start Date Time
                                </Typography>
                                <DateTimePicker
                                  required
                                  isEmptyValue={
                                    (values.startDateTime === null ||
                                      values.startDateTime === '') &&
                                    touched.endDateTime
                                  }
                                  isErrorMessage={Boolean(
                                    touched.startDateTime &&
                                      errors.startDateTime
                                  )}
                                  value={valueRangerPicker.startDateTime}
                                  helperText={errors?.startDateTime}
                                  onChange={e => {
                                    if (e >= new Date(values?.endDateTime)) {
                                      setFieldValue(
                                        'startDateTime',
                                        formatDateTime(e, formatDateTimes)
                                      );
                                      setValueRangerPicker({
                                        ...valueRangerPicker,
                                        startDateTime: e,
                                        endDateTime: null,
                                      });
                                      setFieldValue('endDateTime', '');
                                    } else {
                                      setValueRangerPicker({
                                        ...valueRangerPicker,
                                        startDateTime: e,
                                      });
                                      setFieldValue(
                                        'startDateTime',
                                        formatDateTime(e, formatDateTimes)
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth margin="dense">
                                <Typography variant="body2" mb={2}>
                                  End Date Time
                                </Typography>
                                <DateTimePicker
                                  required
                                  isEmptyValue={
                                    (values.endDateTime === null ||
                                      values.endDateTime === '') &&
                                    touched.endDateTime
                                  }
                                  isErrorMessage={Boolean(
                                    touched.endDateTime && errors.endDateTime
                                  )}
                                  disabled={values.startDateTime === null}
                                  value={valueRangerPicker.endDateTime}
                                  helperText={errors?.endDateTime}
                                  minDate={values?.startDateTime || new Date()}
                                  onChange={e => {
                                    setValueRangerPicker({
                                      ...valueRangerPicker,
                                      endDateTime: e,
                                    });
                                    setFieldValue(
                                      'endDateTime',
                                      formatDateTime(e, formatDateTimes)
                                    );
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Button type="submit" loading={loading}>
                        Update Masthead
                      </Button>
                    </Grid>
                  </Card>
                </form>
              )}
            </Formik>
          </DialogContent>
        )}
        {formLoading && (
          <DialogContent sx={{margin: '48px'}}>
            <Loader />
          </DialogContent>
        )}
      </>
    </Modal>
  );
}

export default ModalUpdate;
