/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {Edit, Add} from '@mui/icons-material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {DateRange} from '@mui/lab/DateRangePicker';

import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {path} from '../../routes/path';
import {
  Breadcrumbs,
  Table,
  Chip,
  Tooltips,
  EnhancedTableToolbar,
  Button,
} from '../../components';
import ModalFilter from './components/Modalfilter/ModalFilter';
import ModalAddNewVoucher from './components/ModalAddNewVoucher/ModalAddNewVoucher';
import VoucherService from '../../services/Voucher/voucher.service';
import {
  formatDate,
  formatLocalDateFromUTCTime,
  formatDateTime,
} from '../../utils';
import {ColorType} from '../../types/typeChip';
import IslanderService from '../../services/Islander/islander.service';
import useAuth from '../../hooks/useAuth';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';
import failure from '../../constants/failure';
import queryString from 'query-string';

//style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './Voucher.style';

interface DateTimeRange {
  startOfDateTime?: Date | null;
  endOfDateTime?: Date | null;
}

function Voucher() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const format = 'DD/MM/YYYY';
  const formatTime = 'DD/MM/YYYY HH:mm';
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};

  // states
  const getCurrentDate = new Date().toISOString();
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startOfDateTime: null,
    endOfDateTime: null,
  });

  const [focusId, setFocusId] = useState(null);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAddVoucher, setOpenModalAddVoucher] = useState(false);
  const [islandPartners, setIslandPartners] = useState([]);
  const [nameContainsList, setNameContainsList] = useState([]);
  const [featuredTickets, setFeaturedTickets] = useState([]);
  const [valueFilter, setValueFilter] = useState({
    usageType_eq: searchParams.get('usageType_eq') ?? '',
    merchantIdNumber_eq: searchParams.get('merchantIdNumber_eq') ?? '',
    titleOrExcerpt_eq: searchParams.get('titleOrExcerpt_eq') ?? '',
    expireStartDateTime_gte: searchParams.get('expireStartDateTime_gte') ?? '',
    expireEndDateTime_lte: searchParams.get('expireEndDateTime_lte') ?? '',
    priority_eq: searchParams.get('priority_eq') ?? '',
    ticketCategoryIds_contains:
      searchParams.get('ticketCategoryIds_contains') ?? '',
    orderFeaturedTicket_isnull:
      searchParams.get('orderFeaturedTicket_isnull') ?? '',
    isExclusive_eq: searchParams.get('isExclusive_eq') ?? '',
  });

  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'createdDateTime:desc',
    status_eq: undefined,
    usageType_eq: searchParamsObject.usageType_eq ?? undefined,
    merchantIdNumber_eq: searchParamsObject.merchantIdNumber_eq ?? undefined,
    titleOrExcerpt_eq: searchParamsObject.titleOrExcerpt_eq ?? undefined,
    expireStartDateTime_gte:
      searchParamsObject.expireStartDateTime_gte ?? undefined,
    expireEndDateTime_lte:
      searchParamsObject.expireEndDateTime_lte ?? undefined,
    priority_eq: searchParamsObject.priority_eq ?? undefined,
    ticketCategoryIds_contains:
      searchParamsObject.ticketCategoryIds_contains ?? undefined,
    orderFeaturedTicket_isnull:
      searchParamsObject.orderFeaturedTicket_isnull ?? undefined,
    isExclusive_eq: searchParamsObject.isExclusive_eq ?? undefined,
  });

  const [ticketCategoryContain, setTicketCategoryContain] = useState(
    searchParamsObject?.ticketCategoryIds_contains !== undefined
      ? searchParamsObject?.ticketCategoryIds_contains?.includes(',')
        ? searchParamsObject?.ticketCategoryIds_contains
            ?.split(',')
            .reduce((tags, key) => {
              tags.push(+key);
              return tags;
            }, [])
        : [+searchParamsObject?.ticketCategoryIds_contains]
      : null
  );
  const [ticketCategoryLabel, setTicketCategoryLabel] = useState(
    searchParamsObject?.category_label !== undefined
      ? searchParamsObject?.category_label?.includes(',')
        ? searchParamsObject?.category_label?.split(',').reduce((tags, key) => {
            tags.push(key);
            return tags;
          }, [])
        : [searchParamsObject?.category_label]
      : null
  );

  const [listSortTabs, setListSortTabs] = useState<any[]>([
    {
      value: 'createdDateTime:desc',
      key: 'All',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Available',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Coming',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Expired',
    },
    {
      value: 'updatedDateTime:desc',
      key: 'Deleted',
    },
  ]);

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'voucherCreationID',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Creation ID',
    },
    {
      dataIndex: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Title',
    },
    {
      dataIndex: 'ticketCategories',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Category',
    },
    {
      dataIndex: 'usageType',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Type',
    },
    {
      dataIndex: 'isExclusive',
      numeric: false,
      disablePadding: false,
      label: 'Exclusive Type',
    },
    {
      dataIndex: 'excerpt',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Short Description',
    },
    {
      dataIndex: 'cost',
      numeric: false,
      disablePadding: false,
      label: 'Points Required',
    },
    {
      dataIndex: 'quantity',
      numeric: false,
      disablePadding: false,
      label: 'Qty',
    },
    {
      dataIndex: 'redemptionCount',
      numeric: false,
      disablePadding: false,
      label: 'Get Voucher Count',
    },
    {
      dataIndex: 'usageCount',
      numeric: false,
      disablePadding: false,
      label: 'Use Voucher Count',
    },
    {
      dataIndex: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const handleClean = () => {
    setValueRangePicker({startOfDateTime: null, endOfDateTime: null});
    setTicketCategoryContain(null);
    setTicketCategoryLabel(null);
    setValueFilter({
      usageType_eq: '',
      merchantIdNumber_eq: '',
      titleOrExcerpt_eq: '',
      expireEndDateTime_lte: '',
      expireStartDateTime_gte: '',
      priority_eq: '',
      ticketCategoryIds_contains: '',
      orderFeaturedTicket_isnull: '',
      isExclusive_eq: '',
    });
  };

  const handleFilter = () => {
    if (valueFilter?.usageType_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        usageType_eq: valueFilter?.usageType_eq,
      }));
    }
    if (valueFilter?.merchantIdNumber_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantIdNumber_eq: valueFilter?.merchantIdNumber_eq,
      }));
    }
    if (valueFilter?.titleOrExcerpt_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        titleOrExcerpt_eq: valueFilter?.titleOrExcerpt_eq,
      }));
    }
    if (valueRangePicker.startOfDateTime && valueRangePicker.endOfDateTime) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        expireEndDateTime_lte: formatDateTime(
          valueRangePicker.endOfDateTime,
          formatTime
        ),
        expireStartDateTime_gte: formatDateTime(
          valueRangePicker.startOfDateTime,
          formatTime
        ),
      }));
    } else if (
      valueFilter?.expireStartDateTime_gte !== '' &&
      valueFilter?.expireEndDateTime_lte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        expireEndDateTime_lte: valueFilter?.expireEndDateTime_lte,
        expireStartDateTime_gte: valueFilter?.expireStartDateTime_gte,
      }));
    }
    if (valueFilter?.priority_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        priority_eq: valueFilter?.priority_eq,
      }));
    }
    if (ticketCategoryContain && ticketCategoryContain.length > 0) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        ticketCategoryIds_contains: ticketCategoryContain.join(','),
      }));
    }
    if (valueFilter.orderFeaturedTicket_isnull) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        orderFeaturedTicket_isnull: valueFilter.orderFeaturedTicket_isnull,
      }));
    }
    if (valueFilter.isExclusive_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        isExclusive_eq: valueFilter.isExclusive_eq,
      }));
    }
    toggleModalFilter();
    // handleClean();
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'usageType_eq') {
      deleteSearchParams('usageType_eq');
      setValueFilter({
        ...valueFilter,
        usageType_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        usageType_eq: undefined,
      }));
    }

    if (type === 'merchantIdNumber_eq') {
      deleteSearchParams('merchantIdNumber_eq');
      deleteSearchParams('merchantIdNumber_label');
      setValueFilter({
        ...valueFilter,
        merchantIdNumber_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        merchantIdNumber_eq: undefined,
      }));
    }
    if (type === 'titleOrExcerpt_eq') {
      deleteSearchParams('titleOrExcerpt_eq');
      setValueFilter({
        ...valueFilter,
        titleOrExcerpt_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        titleOrExcerpt_eq: undefined,
      }));
    }
    if (type === 'date') {
      deleteSearchParams('expireStartDateTime_gte');
      deleteSearchParams('expireEndDateTime_lte');
      setValueRangePicker({startOfDateTime: null, endOfDateTime: null});
      setValueFilter({
        ...valueFilter,
        expireEndDateTime_lte: '',
        expireStartDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        expireStartDateTime_gte: undefined,
        expireEndDateTime_lte: undefined,
      }));
    }
    if (type === 'priority_eq') {
      deleteSearchParams('priority_eq');
      setValueFilter({
        ...valueFilter,
        priority_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        priority_eq: undefined,
      }));
    }
    if (type === 'ticketCategoryIds_contains') {
      deleteSearchParams('ticketCategoryIds_contains');
      deleteSearchParams('category_label');
      setValueFilter({
        ...valueFilter,
        ticketCategoryIds_contains: '',
      });
      setTicketCategoryContain(null);
      setTicketCategoryLabel(null);
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        ticketCategoryIds_contains: undefined,
      }));
    }

    if (type === 'orderFeaturedTicket_isnull') {
      deleteSearchParams(
        'tickeorderFeaturedTicket_isnulltCategoryIds_contains'
      );
      setValueFilter({
        ...valueFilter,
        orderFeaturedTicket_isnull: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        orderFeaturedTicket_isnull: undefined,
      }));
    }

    if (type === 'isExclusive_eq') {
      deleteSearchParams('isExclusive_eq');
      setValueFilter({
        ...valueFilter,
        isExclusive_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        isExclusive_eq: undefined,
      }));
    }
  };

  const addToFeaturedVouchers = id => {
    const newFeaturedVoucher = {
      id: id,
      orderFeaturedTicket: featuredTickets.length + 1,
    };
    if (featuredTickets.length >= 6) {
      const renderNotification = setTimeout(() => {
        showNotification({
          message: failure.SAVE_FAILURE,
          variation: 'error',
        });
      }, 100);

      return () => clearTimeout(renderNotification);
    }

    if (featuredTickets.find(item => item?.id === id)) {
      const renderNotification = setTimeout(() => {
        showNotification({
          message: failure.EXIST_VOUCHER,
          variation: 'error',
        });
      }, 100);

      return () => clearTimeout(renderNotification);
    }

    new VoucherService()
      .arrangerFeaturedVoucher({
        featuredTickets: [
          ...featuredTickets.map(item => ({
            id: item?.id,
            orderFeaturedTicket: item?.orderFeaturedTicket,
          })),
          newFeaturedVoucher,
        ],
      })
      .then(res => {
        if (res.success) {
          showNotification({
            message: success.ADD_FEATURED,
          });
          setFeaturedTickets([...featuredTickets, newFeaturedVoucher]);
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Add to Featured Vouchers">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                addToFeaturedVouchers(value);
              }}
            >
              <Add style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                navigate(`/${path.VOUCHER}/${value}`, {
                  state: {
                    ...queryString.parse(searchParams.toString()),
                  },
                });
              }}
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    const isDeleted = value?.status === 2;
    if (
      new Date().toISOString() <=
        new Date(value?.expireEndDateTime).toISOString() &&
      new Date().toISOString() >=
        new Date(value?.expireStartDateTime).toISOString() &&
      !isDeleted
    ) {
      return <Chip label="Available" color={'success' as ColorType} />;
    }
    if (
      new Date(value?.expireStartDateTime).toISOString() >=
        new Date().toISOString() &&
      !isDeleted
    ) {
      return <Chip label="Coming" color={'warning' as ColorType} />;
    }
    if (
      new Date(value?.expireEndDateTime).toISOString() <=
        new Date().toISOString() &&
      !isDeleted
    ) {
      return <Chip label="Expired" color={'error' as ColorType} />;
    }
    if (isDeleted) {
      return <Chip label="Deleted" color={'default' as ColorType} />;
    }
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const toggleModalAdd = () => {
    setOpenModalAddVoucher(!openModalAddVoucher);
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelVouchers({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const renderDate = values => {
    if (values) {
      return (
        <>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(values.expireStartDateTime, formatTime)}{' '}
            -
          </Typography>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(values.expireEndDateTime, formatTime)}
          </Typography>
        </>
      );
    }
  };

  const renderFieldVoucherType = values => {
    if (values) {
      switch (values.usageType) {
        case 1:
          return 'Single';
        case 2:
          return 'Unlimited';
        default:
          return null;
      }
    }
  };

  const renderFieldExclusiveType = values => {
    if (values) {
      switch (values.isExclusive) {
        case true:
          return 'Exclusive';
        default:
          return 'Non-exclusive';
      }
    }
  };

  const renderIslanderPartner = values => {
    if (values?.merchantIds) {
      const newDataMerchantIds = islandPartners?.filter(item => {
        return values?.merchantIds?.includes(item.value);
      });
      if (newDataMerchantIds.length === 0) {
        return 'All IP';
      }
      return newDataMerchantIds.map((item, key) => {
        return (
          <Typography key={key} variant="body1" fontWeight="500">
            {item.title}
          </Typography>
        );
      });
    }
  };

  const renderMerchantLabel = () => {
    if (
      nameContainsList.length === 0 &&
      searchParams.get('merchantIdNumber_label') !== null
    ) {
      return searchParams.get('merchantIdNumber_label');
    }
    return valueFilter?.merchantIdNumber_eq !== ''
      ? nameContainsList
          .concat({label: 'All IP', value: 'All IP'})
          ?.find(item => item.value === valueFilter?.merchantIdNumber_eq)?.label
      : undefined;
  };

  const getListVoucher = () => {
    setIsLoading(true);
    new VoucherService().getAll(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      }
    });
  };

  const getListFeaturedVoucher = () => {
    setIsLoading(true);
    new VoucherService()
      .getAll({
        _page: 0,
        _size: 10,
        status_eq: 1,
        _sort: 'orderFeaturedTicket:asc',
        expireStartDateTime_lte: getCurrentDate,
        expireEndDateTime_gte: getCurrentDate,
        orderFeaturedTicket_gt: 0,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setFeaturedTickets(res.data.items);
        }
      });
  };

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService()
      .getAll({
        _page: 0,
        _size: 2147483647,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setIslandPartners(newData);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        merchantIdNumber_label: renderMerchantLabel(),
        category_label:
          ticketCategoryLabel !== null && ticketCategoryLabel.length > 0
            ? ticketCategoryLabel.toString()
            : undefined,
      })
    );
    getListVoucher();
  }, [params]);

  useEffect(() => {
    getListFeaturedVoucher();
    getIslanderPartners();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Voucher" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        deleteSearchParams={deleteSearchParams}
        ticketCategoryContain={ticketCategoryContain}
        setTicketCategoryContain={setTicketCategoryContain}
        ticketCategoryLabel={ticketCategoryLabel}
        setTicketCategoryLabel={setTicketCategoryLabel}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
        valueRangePicker={valueRangePicker}
        setValueRangePicker={setValueRangePicker}
      />

      <ModalAddNewVoucher
        visible={openModalAddVoucher}
        reloadPage={getListVoucher}
        onClose={toggleModalAdd}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Voucher
          </Typography>

          <Breadcrumbs routes={[typeRoutes('Voucher', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('usageType_eq') !== null && (
              <Chip
                label={
                  searchParams.get('usageType_eq') === '1'
                    ? 'Single'
                    : 'Unlimited'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('usageType_eq');
                }}
              />
            )}

            {searchParams.get('merchantIdNumber_label') !== null && (
              <Chip
                label={searchParams.get('merchantIdNumber_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('merchantIdNumber_eq');
                }}
              />
            )}

            {searchParams.get('titleOrExcerpt_eq') !== null && (
              <Chip
                label={searchParams.get('titleOrExcerpt_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('titleOrExcerpt_eq');
                }}
              />
            )}

            {searchParams.get('orderFeaturedTicket_isnull') && (
              <Chip
                label={
                  searchParams.get('orderFeaturedTicket_isnull') === '1'
                    ? 'Not featured tickets'
                    : 'Featured tickets'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('orderFeaturedTicket_isnull');
                }}
              />
            )}

            {searchParams.get('expireStartDateTime_gte') !== null &&
              searchParams.get('expireEndDateTime_lte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('expireStartDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('expireEndDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}

            {searchParams.get('priority_eq') !== null && (
              <Chip
                label={`${
                  searchParams.get('priority_eq') === 'true'
                    ? 'Priority'
                    : 'Non-priority'
                }`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('priority_eq');
                }}
              />
            )}
            {searchParams.get('category_label') !== null && (
              <Chip
                label={searchParams.get('category_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('ticketCategoryIds_contains');
                }}
              />
            )}

            {searchParams.get('isExclusive_eq') !== null && (
              <Chip
                label={
                  searchParams.get('isExclusive_eq') === 'true'
                    ? 'Exclusive'
                    : 'Non-Exclusive'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('isExclusive_eq');
                }}
              />
            )}
          </StyledStack>
          {/* <ButtonExport
            params={params}
            exportMethod={new VoucherService().exportCSV}
            fileName="VOUCHER_REPORT"
          /> */}
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <Button onClick={toggleModalAdd} loading={isLoading}>
            Add Voucher
          </Button>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        onClickAddNew={() => navigate(`/${path.ADD_NEW_VOUCHER}`)}
        enableDeletedTab
        setParams={setParams}
        listSortTabs={listSortTabs}
      />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        textNodata="There are no voucher(s) matching the filter."
        orderBy={orderBy}
        isLoading={isLoading}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {dataSource?.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell align="left">{row.numberId}</TableCell>
                <TableCell component="th" id={labelId} scope="row">
                  {row.title}
                </TableCell>
                <TableCell
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html: row.ticketCategories
                      .map(item => item.title)
                      .join('<br/>'),
                  }}
                ></TableCell>
                <TableCell align="left">
                  {renderFieldVoucherType(row)}
                </TableCell>
                <TableCell align="left">
                  {renderFieldExclusiveType(row)}
                </TableCell>
                <TableCell align="left">{row.excerpt}</TableCell>
                <TableCell align="left">{row.cost}</TableCell>
                <TableCell align="left">{row.quantity}</TableCell>
                <TableCell align="left">{row.redemptionCount}</TableCell>
                <TableCell align="left">{row.usageCount}</TableCell>
                <TableCell align="left">{renderDate(row)}</TableCell>
                <TableCell align="left">{renderStatus(row)}</TableCell>
                <TableCell
                  align="left"
                  padding="checkbox"
                  style={{paddingRight: '10px', minWidth: '100px'}}
                >
                  {renderAction(row.id)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default Voucher;
