/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSearchParams, NavLink} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {ColorType} from '../../types/typeChip';
import {
  Breadcrumbs,
  Chip,
  Table,
  Tooltips,
  ModalZoomImage,
  Button,
} from '../../components';
import useAuth from '../../hooks/useAuth';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import {formatLocalDateFromUTCTime} from '../../utils';
import OcrHistoryService from '../../services/OCRHistory/ocrHistory.service';
import queryString from 'query-string';
import {FilterList as FilterListIcon} from '@mui/icons-material';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
} from './ReceiptValidationLogs.style';
import {
  OCRStatus,
  OCRStatusFilterLabel,
  OCRStatusLabel,
  OCRStatusLabelColor,
  OCRVerificationStageKeys,
} from '../../constants/receiptValidationLogsStatus';
import ModalFilter from './components/ModalFilter/ModalFilter';
import IslanderService from '../../services/Islander/islander.service';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';

function ReceiptValidationLogs() {
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const [searchParams, setSearchParams] = useSearchParams();
  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [zoomImage, setZoomImage] = useState(false);
  const [zoomImageUrl, setZoomImageUrl] = useState<string | undefined>();
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
  const [islanderPartnerNames, setIslanderPartnerNames] = useState<any[]>([]);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [ocrLabelStatusOptions, setOcrLabelStatusOptions] = useState([]);
  const [valueRangePicker, setValueRangerPicker] = useState<any[]>([
    null,
    null,
  ]);
  const [valueFilter, setValueFilter] = useState({
    accountId_eq: searchParams.get('accountId_eq') ?? '',
    id_eq: searchParams.get('id_eq') ?? '',
    receiptId_eq: searchParams.get('receiptId_eq') ?? '',
    createdDateTime_lte: searchParams.get('createdDateTime_lte') ?? '',
    createdDateTime_gte: searchParams.get('createdDateTime_gte') ?? '',
    matchedMerchantID_eq: searchParams.get('matchedMerchantID_eq') ?? '',
    receiptMerchantName_label:
      searchParams.get('receiptMerchantName_label') ?? '',
    ocrLabelStatus_eq: searchParams.get('ocrLabelStatus_eq') ?? '',
    ocrLabelStatus_label: searchParams.get('ocrLabelStatus_label') ?? '',
    receiptConfidence_gte: searchParams.get('receiptConfidence_gte') ?? '',
    receiptConfidence_lte: searchParams.get('receiptConfidence_lte') ?? '',
  });
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    receiptValidationLogStatus_eq: true,
    accountId_eq: searchParamsObject.accountId_eq ?? undefined,
    id_eq: searchParamsObject.id_eq ?? undefined,
    receiptId_eq: searchParamsObject.receiptId_eq ?? undefined,
    createdDateTime_lte: searchParamsObject.createdDateTime_lte ?? undefined,
    createdDateTime_gte: searchParamsObject.createdDateTime_gte ?? undefined,
    matchedMerchantID_eq: searchParamsObject.matchedMerchantID_eq ?? undefined,
    receiptMerchantName_label:
      searchParamsObject.receiptMerchantName_label ?? undefined,
    ocrLabelStatus_eq: searchParamsObject.ocrLabelStatus_eq ?? undefined,
    ocrLabelStatus_label: searchParamsObject.ocrLabelStatus_label ?? undefined,
    receiptConfidence_lte:
      searchParamsObject.receiptConfidence_lte ?? undefined,
    receiptConfidence_gte:
      searchParamsObject.receiptConfidence_gte ?? undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'receiptValidationId',
      numeric: false,
      disablePadding: false,
      label: 'Receipt Validation ID',
    },
    {
      dataIndex: 'transactedDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Transacted Date Time',
    },
    {
      dataIndex: 'accountId',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {
      dataIndex: 'receiptId',
      numeric: false,
      disablePadding: false,
      label: 'Receipt ID',
    },
    {
      dataIndex: 'receiptDate',
      numeric: false,
      disablePadding: false,
      label: 'Receipt Date',
    },
    {
      dataIndex: 'amountSpent',
      numeric: false,
      disablePadding: false,
      label: 'Amount Spent',
    },
    {
      dataIndex: 'islandPartner',
      numeric: false,
      disablePadding: false,
      label: 'Island Partner',
    },
    {
      dataIndex: 'receiptImage',
      numeric: false,
      disablePadding: false,
      label: 'Receipt Image',
    },
    {
      dataIndex: 'systemAssuranceLevel',
      numeric: false,
      disablePadding: false,
      label: 'System Assurance Level',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Transaction Status',
    },
    {
      dataIndex: 'receiptDuplicatedId',
      numeric: false,
      disablePadding: false,
      label: 'Similar to Receipt ID',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const toggleIsOpenModalFilter = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };

  const toggleZoomImage = () => {
    setZoomImage(!zoomImage);
  };

  const renderReceiptImage = values => {
    return (
      <img
        onClick={e => {
          toggleZoomImage();
          setZoomImageUrl(values.receiptImageURL);
        }}
        src={values.receiptImageURL}
        alt="Receipt Image"
        width={60}
        height={100}
      />
    );
  };

  const renderAccountId = values => {
    if (!values?.accountId) {
      const labelNull = '--';
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    return values.accountId;
  };

  const renderReceiptId = values => {
    if (!values?.receiptId) {
      let labelNull = '--';
      if (
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_AUTO_VALIDATION ||
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_FORM_RECOGNIZER_OCR
      ) {
        labelNull = 'Pending';
      }
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    return values.receiptId;
  };

  const renderReceiptTotal = values => {
    if (!values?.receiptTotal && values?.receiptTotal !== 0) {
      let labelNull = '--';
      if (
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_AUTO_VALIDATION ||
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_FORM_RECOGNIZER_OCR
      ) {
        labelNull = 'Pending';
      }
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    return `$${values.receiptTotal}`;
  };

  const renderMerchantName = values => {
    if (!values?.merchantName) {
      let labelNull = '--';
      if (
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_AUTO_VALIDATION ||
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_FORM_RECOGNIZER_OCR
      ) {
        labelNull = 'Pending';
      }
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    return values.merchantName;
  };

  const renderReceiptDateOfPurchase = values => {
    if (values.receiptDateOfPurchase === null) {
      let labelNull = '--';
      if (
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_AUTO_VALIDATION ||
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_FORM_RECOGNIZER_OCR
      ) {
        labelNull = 'Pending';
      }
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    return formatLocalDateFromUTCTime(
      values.receiptDateOfPurchase,
      formatDateTimes
    );
  };

  const renderReceiptDuplicatedId = (values: any) => {
    if (values.receiptDuplicatedId !== null && values.pointId !== null) {
      return (
        <NavLink
          style={{textDecoration: 'none'}}
          to={`/all-transaction/${values.pointId}`}
        >
          {values.receiptDuplicatedId}{' '}
        </NavLink>
      );
    }
    return (
      <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
        --
      </Typography>
    );
  };

  const renderTransactionStatus = (values: any) => {
    const key = values.ocrLabelStatus;
    const color = OCRStatusLabelColor[key];
    const label = OCRStatusLabel[key];
    return <Chip label={label} color={color as ColorType} />;
  };

  const renderSystemAssuranceLevel = (values: any) => {
    const percent =
      values?.receiptConfidence !== null
        ? values.receiptConfidence * 100
        : null;
    let color = '#6E6E6E';
    if (percent === null) {
      let labelNull = '--';
      if (
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_AUTO_VALIDATION ||
        values.ocrLabelStatus ===
          OCRVerificationStageKeys.PENDING_FORM_RECOGNIZER_OCR
      ) {
        labelNull = 'Pending';
      }
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          {labelNull}
        </Typography>
      );
    }

    if (percent < 80) {
      color = '#E66A25';
    } else if (percent >= 80) {
      color = '#2E7D32';
    }
    return (
      <Typography variant="h6" sx={{color: color}}>{`${percent.toFixed(
        2
      )}%`}</Typography>
    );
  };

  const GetReceiptValidationLogs = () => {
    setIsLoading(true);
    new OcrHistoryService()
      .getAll({
        ...params,
        receiptConfidence_gte: params?.receiptConfidence_gte
          ? params?.receiptConfidence_gte / 100
          : undefined,
        receiptConfidence_lte: params?.receiptConfidence_lte
          ? params?.receiptConfidence_lte / 100
          : undefined,
        receiptMerchantName_label: undefined,
        ocrLabelStatus_label: undefined,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res.data.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        // Get api error => show notification or no items listing
        setError(error);
      });
  };

  const getIslanderPartners = () => {
    new IslanderService()
      .get('get-filter-data', {isAvailableMerchantReceipt_eq: 1})
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setIslanderPartnerNames(
            res?.data?.data?.reduce((items, item) => {
              items.push({
                label: item.name || '-',
                value: item.id,
              });
              return items;
            }, [])
          );
        }
      });
  };

  const getVerificationStageOptions = () => {
    const keys = Object.keys(OCRStatusFilterLabel);
    const options: any[] = [];
    keys.forEach((key, index) => {
      options.push({value: Number(key), label: OCRStatusFilterLabel[key]});
    });
    setOcrLabelStatusOptions(options);
  };

  const handleFilter = () => {
    if (valueFilter.accountId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        accountId_eq: valueFilter.accountId_eq,
      }));
    }

    if (valueFilter.id_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        id_eq: valueFilter.id_eq,
      }));
    }

    if (valueFilter.receiptId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        receiptId_eq: valueFilter.receiptId_eq,
      }));
    }

    if (valueFilter.createdDateTime_gte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_gte: valueFilter.createdDateTime_gte,
      }));
    }

    if (valueFilter.createdDateTime_lte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: valueFilter.createdDateTime_lte,
      }));
    }

    if (valueFilter.matchedMerchantID_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        receiptMerchantName_label: valueFilter.matchedMerchantID_eq
          ? islanderPartnerNames.find(
              item => item.value === valueFilter.matchedMerchantID_eq
            )?.label
          : undefined,
        matchedMerchantID_eq: valueFilter.matchedMerchantID_eq,
      }));
    }

    if (valueFilter.receiptConfidence_lte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        receiptConfidence_lte: valueFilter.receiptConfidence_lte,
      }));
    }

    if (valueFilter.receiptConfidence_gte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        receiptConfidence_gte: valueFilter.receiptConfidence_gte,
      }));
    }

    if (valueFilter.ocrLabelStatus_eq || valueFilter.ocrLabelStatus_eq === 0) {
      const ocrLabelStatus_eq =
        valueFilter.ocrLabelStatus_eq === ''
          ? ''
          : Number(valueFilter.ocrLabelStatus_eq);
      setParams(preState => ({
        ...preState,
        _page: 0,
        ocrLabelStatus_eq: ocrLabelStatus_eq,
        ocrLabelStatus_label:
          ocrLabelStatusOptions.find(item => item.value === ocrLabelStatus_eq)
            ?.label ?? undefined,
      }));
    }
  };

  const handleClean = () => {
    setParams({
      _page: 0,
      _size: 10,
      receiptValidationLogStatus_eq: true,
    });
    setValueFilter({
      accountId_eq: '',
      id_eq: '',
      receiptId_eq: '',
      createdDateTime_lte: '',
      createdDateTime_gte: '',
      receiptMerchantName_label: '',
      matchedMerchantID_eq: '',
      ocrLabelStatus_eq: '',
      ocrLabelStatus_label: '',
      receiptConfidence_gte: '',
      receiptConfidence_lte: '',
    });
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'accountId_eq') {
      deleteSearchParams('accountId_eq');
      setValueFilter({
        ...valueFilter,
        accountId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        accountId_eq: undefined,
      }));
    }

    if (type === 'id_eq') {
      deleteSearchParams('id_eq');
      setValueFilter({
        ...valueFilter,
        id_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        id_eq: undefined,
      }));
    }

    if (type === 'receiptId_eq') {
      deleteSearchParams('receiptId_eq');
      setValueFilter({
        ...valueFilter,
        receiptId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptId_eq: undefined,
      }));
    }

    if (type === 'receiptId_eq') {
      deleteSearchParams('receiptId_eq');
      setValueFilter({
        ...valueFilter,
        receiptId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptId_eq: undefined,
      }));
    }

    if (type === 'createdDateTime') {
      setValueRangerPicker([null, null]);
      deleteSearchParams('createdDateTime_gte');
      deleteSearchParams('createdDateTime_lte');
      setValueFilter({
        ...valueFilter,
        createdDateTime_gte: '',
        createdDateTime_lte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        createdDateTime_gte: undefined,
        createdDateTime_lte: undefined,
      }));
    }

    if (type === 'receiptMerchantName_label') {
      deleteSearchParams('receiptMerchantName_label');
      setValueFilter({
        ...valueFilter,
        receiptMerchantName_label: '',
        matchedMerchantID_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptMerchantName_label: undefined,
        matchedMerchantID_eq: undefined,
      }));
    }

    if (type === 'ocrLabelStatus_eq') {
      deleteSearchParams('ocrLabelStatus_eq');
      deleteSearchParams('ocrLabelStatus_label');
      setValueFilter({
        ...valueFilter,
        ocrLabelStatus_label: '',
        ocrLabelStatus_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        ocrLabelStatus_eq: undefined,
        ocrLabelStatus_label: undefined,
      }));
    }

    if (type === 'receiptConfidence_gte') {
      deleteSearchParams('receiptConfidence_gte');
      setValueFilter({
        ...valueFilter,
        receiptConfidence_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptConfidence_gte: undefined,
      }));
    }

    if (type === 'receiptConfidence_lte') {
      deleteSearchParams('receiptConfidence_lte');
      setValueFilter({
        ...valueFilter,
        receiptConfidence_lte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptConfidence_lte: undefined,
      }));
    }

    if (type === 'receiptConfidence') {
      deleteSearchParams('receiptConfidence_lte');
      deleteSearchParams('receiptConfidence_gte');
      setValueFilter({
        ...valueFilter,
        receiptConfidence_lte: '',
        receiptConfidence_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        receiptConfidence_lte: undefined,
        receiptConfidence_gte: undefined,
      }));
    }

    if (type === 'ocrLabelStatus_eq') {
      deleteSearchParams('ocrLabelStatus_eq');
      setValueFilter({
        ...valueFilter,
        ocrLabelStatus_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        receiptValidationLogStatus_eq: true,
        ocrLabelStatus_eq: undefined,
      }));
    }
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportReceiptValidationLog({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    GetReceiptValidationLogs();
  }, [params]);

  useEffect(() => {
    setValueFilter(prevState => ({
      ...prevState,
      matchedMerchantID_eq:
        islanderPartnerNames.find(
          item => item.label === params?.receiptMerchantName_label
        )?.value ?? '',
    }));
  }, [islanderPartnerNames]);

  useEffect(() => {
    getIslanderPartners();
    getVerificationStageOptions();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Receipt Validation Logs" />

      <ModalZoomImage
        visible={zoomImage}
        onClose={toggleZoomImage}
        image={zoomImageUrl}
        alt="Receipt Validation Logs Image Item"
      >
        Hello war world!
      </ModalZoomImage>

      <ModalFilter
        visible={isOpenModalFilter}
        onClose={toggleIsOpenModalFilter}
        handleClean={handleClean}
        handleFilter={handleFilter}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        islanderPartnerNames={islanderPartnerNames}
        ocrLabelStatusOptions={ocrLabelStatusOptions}
        valueRangePicker={valueRangePicker}
        setValueRangerPicker={setValueRangerPicker}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Receipt Validation Logs
          </Typography>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Monitor ongoing receipt validations and track declined errors',
                null,
                true
              ),
            ]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {searchParams.get('accountId_eq') !== null && (
            <Chip
              label={searchParams.get('accountId_eq')}
              color={'default' as ColorType}
              onDelete={() => {
                handleDeleteChip('accountId_eq');
              }}
            />
          )}
          {searchParams.get('id_eq') !== null && (
            <Chip
              label={searchParams.get('id_eq')}
              color={'default' as ColorType}
              onDelete={() => {
                handleDeleteChip('id_eq');
              }}
            />
          )}

          {searchParams.get('receiptId_eq') !== null && (
            <Chip
              label={searchParams.get('receiptId_eq')}
              color={'default' as ColorType}
              onDelete={() => {
                handleDeleteChip('receiptId_eq');
              }}
            />
          )}
          {searchParams.get('createdDateTime_lte') !== null &&
            searchParams.get('createdDateTime_gte') !== null && (
              <Chip
                label={`${formatLocalDateFromUTCTime(
                  searchParams.get('createdDateTime_gte'),
                  'DD/MM/YYYY'
                )} - ${formatLocalDateFromUTCTime(
                  searchParams.get('createdDateTime_lte'),
                  'DD/MM/YYYY'
                )}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('createdDateTime');
                }}
              />
            )}
          {searchParams.get('receiptMerchantName_label') !== null && (
            <Chip
              label={searchParams.get('receiptMerchantName_label')}
              color={'default' as ColorType}
              onDelete={() => {
                handleDeleteChip('receiptMerchantName_label');
              }}
            />
          )}
          {searchParams.get('ocrLabelStatus_eq') !== null && (
            <Chip
              label={searchParams.get('ocrLabelStatus_label')}
              color={'default' as ColorType}
              onDelete={() => {
                handleDeleteChip('ocrLabelStatus_eq');
              }}
            />
          )}

          {searchParams.get('receiptConfidence_gte') !== null &&
            searchParams.get('receiptConfidence_lte') === null && (
              <Chip
                label={`>= ${searchParams.get('receiptConfidence_gte')}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('receiptConfidence_gte');
                }}
              />
            )}
          {searchParams.get('receiptConfidence_lte') !== null &&
            searchParams.get('receiptConfidence_gte') === null && (
              <Chip
                label={`<= ${searchParams.get('receiptConfidence_lte')}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('receiptConfidence_lte');
                }}
              />
            )}
          {searchParams.get('receiptConfidence_lte') !== null &&
            searchParams.get('receiptConfidence_gte') !== null && (
              <Chip
                label={`${searchParams.get(
                  'receiptConfidence_gte'
                )} - ${searchParams.get('receiptConfidence_lte')}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('receiptConfidence');
                }}
              />
            )}
          <div
            style={{
              marginRight: '10px',
              marginLeft: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleIsOpenModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no receipt validation log(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTimes
                    )}
                  </TableCell>
                  <TableCell align="left">{renderAccountId(row)}</TableCell>
                  <TableCell align="left">{renderReceiptId(row)}</TableCell>
                  <TableCell align="left">
                    {renderReceiptDateOfPurchase(row)}
                  </TableCell>
                  <TableCell align="left">{renderReceiptTotal(row)}</TableCell>
                  <TableCell align="left">{renderMerchantName(row)}</TableCell>
                  <TableCell align="left">{renderReceiptImage(row)}</TableCell>
                  <TableCell align="left">
                    {renderSystemAssuranceLevel(row)}
                  </TableCell>
                  <TableCell align="left">
                    {renderTransactionStatus(row)}
                  </TableCell>
                  <TableCell align="left">
                    {renderReceiptDuplicatedId(row)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default ReceiptValidationLogs;
