import React, {useState} from 'react';
import {DialogContent, FormControl, Grid, Stack} from '@mui/material';
import {Modal, TextArea, Button} from '../../../../components';
import MemberShipPointsService from '../../../../services/MemberShipPoints/membership-points';
import {DataDetail} from '../../../../types/typeAllTransaction';
import success from '../../../../constants/success';
import useAuth from '../../../../hooks/useAuth';

// styles
import {
  StyledModalActions,
  StyledTitle,
  Typography,
} from './ModalApproval.style';

interface ModalApproval {
  isModalApproval?: boolean;
  typeModal?: string;
  title?: string;
  dataDetail?: DataDetail;
  onClose?: () => void;
  reloadPage?: () => void;
}

function ModalApproval({
  isModalApproval,
  onClose,
  title,
  typeModal,
  dataDetail,
  reloadPage,
}: ModalApproval) {
  const {showNotification} = useAuth();

  // states
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    setIsLoading(true);
    onClose();
    new MemberShipPointsService()
      .update(`${dataDetail?.membershipId}/points/${dataDetail.id}`, {
        processId: typeModal === 'approve' ? 1 : 3,
        notes: notes,
      })
      .then(res => {
        if (res.success) {
          reloadPage();
          setIsLoading(false);
          showNotification({
            message:
              typeModal === 'approve'
                ? success.APPROVAL_SUCCESS
                : success.DECLINED_SUCCESS,
          });
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  return (
    <Modal visible={isModalApproval} onClose={onClose} maxWidth="sm">
      <DialogContent
        sx={{
          margin: '0px 44px 0px 44px',
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item mt={3} xs={12} md={12} textAlign="center">
            <StyledTitle className={`type type--${typeModal}`}>
              {title}
            </StyledTitle>
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <Typography variant="body2" mb={2} fontWeight="bolder">
            {typeModal === 'approve'
              ? 'Please provide any additional notes to inform the customer about this transaction (Optional)'
              : 'Please provide the reason to inform the customer (Optional)'}
          </Typography>
          <TextArea
            size="small"
            name="quantity"
            type="text"
            maxRows={6}
            value={notes}
            onChange={e => {
              setNotes(e.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <StyledModalActions>
        <Grid container>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button
                variant="outlined"
                onClick={onClose}
                width="125px"
                loading={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={onClick}
                width="125px"
                loading={isLoading}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </StyledModalActions>
    </Modal>
  );
}
export default ModalApproval;
