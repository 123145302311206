/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {path} from '../../routes/path';
import ModalFilter from './components/ModalFilter/ModalFilter';
import {Breadcrumbs, Table, Chip, Tooltips, Button} from '../../components';
import MembersService from '../../services/Members/members.service';
import {ColorType} from '../../types/typeChip';
import {
  formatLocalDateFromUTCTime,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
  formatDate,
} from '../../utils';
import queryString from 'query-string';

// style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './TerminatedMembers.style';
import {NavLink} from 'react-router-dom';

function TerminatedMembers() {
  const navigate = useNavigate();

  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const format = 'DD/MM/YYYY';
  const formatDateTime = 'DD/MM/YYYY HH:mm:ss';

  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);

  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [valueFilter, setValueFilter] = useState({
    email_contains: searchParams.get('email_contains') ?? '',
    membershipId_contains: searchParams.get('membershipId_contains') ?? '',
    loyaltyMembershipId_eq: searchParams.get('loyaltyMembershipId_eq') ?? '',
    objectId_eq: searchParams.get('objectId_eq') ?? '',
    accountId_eq: searchParams.get('accountId_eq') ?? '',
    terminatedDate_gte: searchParams.get('terminatedDate_gte') ?? '',
    terminatedDate_lte: searchParams.get('terminatedDate_lte') ?? '',
    terminatedDate: [null, null],
  });
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'terminatedDate:desc',
    ...{
      email_contains: searchParamsObject.email_contains ?? undefined,
      membershipId_contains:
        searchParamsObject.membershipId_contains ?? undefined,
      loyaltyMembershipId_eq:
        searchParamsObject.loyaltyMembershipId_eq ?? undefined,
      objectId_eq: searchParamsObject.objectId_eq ?? undefined,
      accountId_eq: searchParamsObject.accountId_eq ?? undefined,
      terminatedDate_gte: searchParamsObject.terminatedDate_gte ?? undefined,
      terminatedDate_lte: searchParamsObject.terminatedDate_lte ?? undefined,
    },
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      dataIndex: 'accountId',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {
      dataIndex: 'loyaltyMembershipId',
      numeric: false,
      disablePadding: false,
      label: 'Loyalty Membership ID',
    },
    {
      dataIndex: 'objectId',
      numeric: false,
      disablePadding: false,
      label: 'Object ID',
    },
    {
      dataIndex: 'SnAppId',
      numeric: false,
      disablePadding: false,
      label: "SnApp's Account ID",
    },
    {
      dataIndex: 'terminatedDate',
      numeric: false,
      disablePadding: false,
      label: 'Terminated Date',
    },
    {
      dataIndex: 'accountStatus',
      numeric: false,
      disablePadding: false,
      label: 'Account Status',
    },
    {
      dataIndex: 'allTransactions',
      numeric: false,
      disablePadding: false,
      label: 'All Transactions',
    },
    {
      dataIndex: 'voucherTransactions',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Transactions',
    },
    {
      dataIndex: 'referralTransactions',
      numeric: false,
      disablePadding: false,
      label: 'Referral Transactions',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const renderStatus = (values: any) => {
    return <Chip color={'error' as ColorType} label="Terminated" />;
  };

  const renderViewAllTransaction = (values: any) => {
    return values.allTransactions === true ? (
      <NavLink
        to={`/${path.ALL_TRANSACTION}?_page=0&_size=10&membershipId=${values.loyaltyMembershipId}`}
        style={{textDecoration: 'none'}}
        state={{membershipId: values.loyaltyMembershipId}}
      >
        View
      </NavLink>
    ) : (
      '--'
    );
  };

  const renderViewVoucherTransaction = (values: any) => {
    return values?.voucherTransactions === true ? (
      <NavLink
        to={`/${path.VOUCHER_TRANSACTION_HISTORY}?_page=0&_size=10&_sort=updatedDateTime:desc&membershipId_eq=${values.loyaltyMembershipId}`}
        style={{textDecoration: 'none'}}
      >
        View
      </NavLink>
    ) : (
      '--'
    );
  };

  const renderViewReferralTransaction = (values: any) => {
    return values?.referralTransactions === true ? (
      <NavLink
        to={`/${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}?_page=0&_size=10&_sort=createdDateTime:desc&accountId_eq=${values.membershipId}&status_eq=1`}
        style={{textDecoration: 'none'}}
      >
        View
      </NavLink>
    ) : (
      '--'
    );
  };

  const handleDeleteChip = type => {
    if (type === 'terminatedDate') {
      deleteSearchParams('terminatedDate_lte');
      deleteSearchParams('terminatedDate_gte');
      setValueFilter(prevState => ({
        ...prevState,
        terminatedDate_lte: '',
        terminatedDate_gte: '',
      }));
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        terminatedDate_lte: undefined,
        terminatedDate_gte: undefined,
      }));
      return;
    }

    deleteSearchParams(type);
    setValueFilter(prevState => ({...prevState, [type]: ''}));
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: 10,
      [type]: undefined,
    }));
  };

  const handleClean = () => {
    setValueFilter({
      email_contains: '',
      membershipId_contains: '',
      loyaltyMembershipId_eq: '',
      objectId_eq: '',
      accountId_eq: '',
      terminatedDate_gte: '',
      terminatedDate_lte: '',
      terminatedDate: [null, null],
    });
  };

  const handleFilter = () => {
    if (valueFilter?.membershipId_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        membershipId_contains: valueFilter?.membershipId_contains,
      }));
    }
    if (valueFilter?.email_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_contains: valueFilter?.email_contains,
      }));
    }
    if (valueFilter?.loyaltyMembershipId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        loyaltyMembershipId_eq: valueFilter?.loyaltyMembershipId_eq,
      }));
    }

    if (valueFilter?.objectId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        objectId_eq: valueFilter?.objectId_eq,
      }));
    }

    if (valueFilter?.accountId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        accountId_eq: valueFilter?.accountId_eq,
      }));
    }

    if (valueFilter?.terminatedDate[0] || valueFilter?.terminatedDate[1]) {
      let obj = {};
      if (valueFilter?.terminatedDate[0]) {
        obj = {
          ...obj,
          terminatedDate_gte: getStartOfDate(valueFilter.terminatedDate[0]),
        };
      }
      if (valueFilter?.terminatedDate[1]) {
        obj = {
          ...obj,
          terminatedDate_lte: getEndOfDate(valueFilter?.terminatedDate[1]),
        };
      }
      setParams(preState => ({
        ...preState,
        _page: 0,
        ...obj,
      }));
    } else if (
      valueFilter?.terminatedDate_lte !== '' &&
      valueFilter?.terminatedDate_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        terminatedDate_lte: valueFilter?.terminatedDate_lte,
        terminatedDate_gte: valueFilter?.terminatedDate_gte,
      }));
    }
    toggleModal();
    // handleClean();
  };

  const getMembers = () => {
    setIsLoading(true);
    new MembersService().getTerminatedMembers(params).then(res => {
      if (Array.isArray(res?.data?.data?.items)) {
        setDataSource(res?.data?.data?.items);
        setTotalPage(res?.data?.data?.total);
        setIsLoading(false);
      } else {
        setDataSource([]);
        setTotalPage(0);
        setIsLoading(false);
      }
    });
  };

  const onChangeRangePicker = terminatedDate => {
    setValueFilter({
      ...valueFilter,
      terminatedDate,
    });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    getMembers();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Terminated Islander Members" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        onChangeRangePicker={onChangeRangePicker}
        setValueFilter={setValueFilter}
        valueFilter={valueFilter}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Terminated Islander Members
          </Typography>

          <Breadcrumbs
            routes={[
              typeRoutes(
                'Overview of members who have been terminated.',
                null,
                true
              ),
            ]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('email_contains') !== null && (
              <Chip
                label={searchParams.get('email_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_contains');
                }}
              />
            )}
            {searchParams.get('membershipId_contains') !== null && (
              <Chip
                label={searchParams.get('membershipId_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('membershipId_contains');
                }}
              />
            )}
            {searchParams.get('loyaltyMembershipId_eq') !== null && (
              <Chip
                label={searchParams.get('loyaltyMembershipId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('loyaltyMembershipId_eq');
                }}
              />
            )}
            {searchParams.get('objectId_eq') !== null && (
              <Chip
                label={searchParams.get('objectId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('objectId_eq');
                }}
              />
            )}
            {searchParams.get('accountId_eq') !== null && (
              <Chip
                label={searchParams.get('accountId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('accountId_eq');
                }}
              />
            )}
            {searchParams.get('terminatedDate_lte') !== null &&
              searchParams.get('terminatedDate_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('terminatedDate_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('terminatedDate_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('terminatedDate');
                  }}
                />
              )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        orderBy={orderBy}
        textNodata="There are no islander member(s) matching the filter."
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {dataSource?.map(row => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell component="th" scope="row">
                  {`${row.email}`}
                </TableCell>
                <TableCell align="left">{row.membershipId}</TableCell>
                <TableCell align="left">{row.loyaltyMembershipId}</TableCell>
                <TableCell align="left">{row.objectId}</TableCell>
                <TableCell align="left">{row.accountId}</TableCell>
                <TableCell align="left">
                  {formatLocalDateFromUTCTime(
                    row.terminatedDate,
                    formatDateTime
                  )}
                </TableCell>
                <TableCell align="left">{renderStatus(row)}</TableCell>
                <TableCell align="left">
                  {renderViewAllTransaction(row)}
                </TableCell>
                <TableCell align="left">
                  {renderViewVoucherTransaction(row)}
                </TableCell>
                <TableCell align="left">
                  {renderViewReferralTransaction(row)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default TerminatedMembers;
