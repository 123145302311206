import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {Zoom as MuiZoom, Box} from '@mui/material';

const ZoomContent = styled(Box)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1300;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Zoom = styled(MuiZoom)(spacing);

export {Zoom, ZoomContent};
