/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import {DialogContent, Grid, Stack} from '@mui/material';
import {images} from '../../../constants/image';
import {Modal, Button} from '../../../components';

// style
import {StyledModalActions} from './ModalConfirm.style';

interface ModalConfirmProps {
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  title: string | ReactNode;
  subTitle: string | ReactNode;
  isLoading?: boolean;
  icon?: string | undefined;
  agreeLabel?: string | undefined;
  disAgreeLabel?: string | undefined;
  iconWidth?: number | undefined;
  iconHeight?: number | undefined;
  footer?: ReactNode | undefined;
}

function ModalConfirm({
  visible,
  onClose,
  onConfirm,
  title,
  subTitle,
  isLoading,
  icon,
  agreeLabel = 'OK',
  disAgreeLabel = 'Deny',
  iconWidth,
  iconHeight,
  footer,
}: ModalConfirmProps) {
  return (
    <Modal visible={visible} onClose={onClose} maxWidth="xs">
      <>
        <DialogContent>
          <Grid container justifyContent="space-around">
            <Grid item mt={5} xs={12} md={12} textAlign="center">
              <img
                src={icon ? icon : images.icon_warning}
                width={iconWidth}
                height={iconHeight}
              />
            </Grid>
            <Grid item mt={3} xs={12} md={12} textAlign="center">
              {title}
            </Grid>
            <Grid item mt={5} xs={12} md={12} textAlign="center">
              {subTitle}
            </Grid>
          </Grid>
        </DialogContent>
        <StyledModalActions>
          {footer ? (
            footer
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    width="125px"
                    loading={isLoading}
                  >
                    Deny
                  </Button>
                  <Button
                    variant="contained"
                    onClick={onConfirm}
                    width="125px"
                    loading={isLoading}
                  >
                    OK
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalConfirm;
