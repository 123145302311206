/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {TableCell, TableBody, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Edit} from '@mui/icons-material';

import {
  EnhancedTableToolbar,
  ModalConfirm,
  ModalSuccess,
  Table,
  Tooltips,
} from '../../../../components';
import {ColumnsType} from '../../../../types/typeIslandersPartners';
import IslanderService from '../../../../services/Islander/islander.service';
import ModalAddNewStaff from '../ModalAddNewStaff/ModalAddNewStaff';

// style
import {
  StyledTableRow,
  StyledAction,
  StyledIcon,
  Typography,
} from './TableMerchantReceipt.style';
import ModalAddNewMerchantReceipt from '../ModalAddNewMerchantReceipt/ModalAddNewMerchantReceipt';
import useAuth from '../../../../hooks/useAuth';

const staffMax = 20;

interface TableStaffProps {
  idMerchant: string | number;
  canDelete?: boolean;
  canCreate?: boolean;
}

function TableMerchantReceipt({idMerchant}: TableStaffProps) {
  // states
  const {showNotification} = useAuth();
  const [dense] = useState(false);
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [focusId, setFocusId] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [listMerchantReceipt, setListMerchantReceipt] = useState([]);
  const [idMerchantReceipt, setIdMerchantReceipt] = useState('');
  const [dataDetail, setDataDetail] = useState({});
  const [listMerchantReceiptLength, setListMerchantReceiptLength] = useState(0);

  const [modal, setModal] = useState({
    isModalConfirmDelete: false,
    isModalSuccessDelete: false,
    isModalAddNewStaff: false,
  });
  const [params, setParams] = useState({
    _page: 0,
    _size: 5,
    status_eq: 1,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'receiptName',
      numeric: false,
      disablePadding: false,
      label: 'Receipt Template Name',
    },
    {
      dataIndex: 'receiptAddress',
      numeric: false,
      disablePadding: false,
      label: 'Receipt Template Address',
    },
    {
      dataIndex: 'operatingArea',
      numeric: false,
      disablePadding: false,
      label: 'Operating Area ',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderAction = (value: string, objItem: object) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalAddNewStaff();
                setIdMerchantReceipt(value);
                setDataDetail(objItem);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalConfirmDelete();
                setIdMerchantReceipt(value);
              }}
              size="medium"
            >
              <DeleteIcon style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalAddNewStaff = () => {
    setIdMerchantReceipt('');
    setModal(preState => ({
      ...preState,
      isModalAddNewStaff: !modal.isModalAddNewStaff,
    }));
  };

  const toggleModalSuccessDelete = () => {
    new IslanderService()
      .softDelete(`${idMerchant}/merchant-receipt/${idMerchantReceipt}`)
      .then(res => {
        setIsLoading(true);
        if (res && res?.success) {
          setIsLoading(false);
          getListMerchantReceipt();
          showNotification({
            message: 'Receipt template removed successfully!',
          });
          toggleModalConfirmDelete();
        } else {
          setIsLoading(false);
          showNotification({
            message: res?.errorMessage,
          });
          toggleModalConfirmDelete();
        }
      });
  };

  const getListMerchantReceipt = () => {
    setIsLoading(true);
    new IslanderService()
      .getMerchantReceipts(idMerchant, {
        _page: params._page,
        _size: params._size,
        status_eq: 1,
      })
      .then(res => {
        if (res?.data?.data && Array.isArray(res.data.data.items)) {
          setIsLoading(false);
          setListMerchantReceipt(res.data.data.items);
          setTotalPage(res?.data?.data?.total);
          setListMerchantReceiptLength(res?.data?.data?.total);
        }
      });
  };

  useEffect(() => {
    getListMerchantReceipt();
  }, [params, idMerchant]);

  return (
    <>
      <ModalConfirm
        visible={modal.isModalConfirmDelete}
        title={
          <Typography variant="h4">
            Are you sure you want to delete this Receipt Template?
          </Typography>
        }
        subTitle={
          <Typography color="red">This action cannot be undone.</Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={toggleModalSuccessDelete}
      />

      <ModalSuccess
        visible={modal.isModalSuccessDelete}
        title={<Typography variant="h4">Delete successfully</Typography>}
        subTitle={<Typography>IP staff deleted.</Typography>}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: false,
          }));
        }}
      />

      <ModalAddNewMerchantReceipt
        visible={modal.isModalAddNewStaff}
        onClose={toggleModalAddNewStaff}
        reloadPage={getListMerchantReceipt}
        idMerchant={idMerchant}
        idMerchantReceipt={idMerchantReceipt}
        dataDetail={dataDetail}
      />

      <EnhancedTableToolbar
        isHeader={true}
        isAddNew
        isLoading={isLoading}
        titleHeader={'Receipt Templates (for OCR Validation)'}
        onClickAddNew={toggleModalAddNewStaff}
        canCreate={true}
      />

      <Table
        dataSource={listMerchantReceipt}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        orderBy={orderBy}
        dense={dense}
        isLoading={isLoading}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {listMerchantReceipt?.map((row, index) => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell component="th" scope="row">
                  {row.receiptName || '--'}
                </TableCell>
                <TableCell align="left">{row.receiptAddress || '--'}</TableCell>
                <TableCell align="left">{row.operatingArea || '--'}</TableCell>
                <TableCell
                  align="right"
                  padding="checkbox"
                  style={{paddingRight: '10px'}}
                >
                  {renderAction(row.id, row)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
export default TableMerchantReceipt;
