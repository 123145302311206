import React, {useEffect, useState} from 'react';
import {DialogContent, FormControl, Grid, Stack} from '@mui/material';
import {Modal, TextArea, Button} from '../../../../components';
import MemberShipPointsService from '../../../../services/MemberShipPoints/membership-points';
import {DataDetail} from '../../../../types/typeAllTransaction';
import success from '../../../../constants/success';
import useAuth from '../../../../hooks/useAuth';
import ApproveReceiptForm from './ApproveReceiptForm/ApproveReceiptForm';

// styles
import {
  StyledModalActions,
  StyledTitle,
  Typography,
} from './ModalApprovalReceipt.style';
import DeclineReceiptForm from './DeclineReceiptForm/DeclineReceiptForm';
import {formatDateTime} from '../../../../utils';

interface ModalApprovalReceipt {
  isModalApprovalReceipt?: boolean;
  typeModal?: string;
  title?: string;
  dataDetail?: DataDetail;
  onClose?: () => void;
  reloadPage?: () => void;
}

function ModalApprovalReceipt({
  isModalApprovalReceipt,
  onClose,
  title,
  typeModal,
  dataDetail,
  reloadPage,
}: ModalApprovalReceipt) {
  const {showNotification} = useAuth();

  // states
  const formatDateTimes = 'dd/MM/yyyy hh:mm';
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [yesNoAmend, setYesNoAmend] = useState<any>(false);
  const [errorMessageSelect, setErrorMessageSelect] = useState<string | null>(
    null
  );

  const [initialValues, setInitialValues] = useState({
    oCRHistoryReceiptId: '',
    receiptDateOfPurchase: null,
    receiptTotal: '',
    receiptMerchantName: undefined,
    matchedMerchantID: '',
    receiptAddress: '',
    pointValue: '',
  });
  const [keysSelected, setKeysSelected] = useState<any>({
    oCRHistoryReceiptId: false,
    receiptDateOfPurchase: false,
    receiptTotal: false,
    receiptPartnerAndAddress: false,
    pointValue: false,
  });
  const [rejectedFields, setRejectedFields] = useState<string[]>([]);
  const [receiptAddressValue, setReceiptAddressValue] = useState<number>('');
  const [
    errorMissedFieldWhenApproveWithNoAmend,
    setErrorMissedFieldWhenApproveWithNoAmend,
  ] = useState<string | null>(null);
  const [errorInputFields, setErrorInputFields] = useState<any>({
    oCRHistoryReceiptId: '',
    receiptTotal: '',
    receiptDateOfPurchase: '',
    receiptMerchantName: '',
    matchedMerchantID: '',
    receiptAddress: '',
    pointValue: '',
  });
  const [initialValuesDeclined, setInitialValuesDeclined] = useState({
    rejectReason: '',
  });

  const onClick = () => {
    if (
      validationKeySelected() === true ||
      validationFields() === true ||
      validationWhenApproveWithNoAmend() === true
    ) {
      return;
    }
    setIsLoading(true);
    onClose();
    new MemberShipPointsService()
      .update(`${dataDetail?.membershipId}/points/${dataDetail.id}`, {
        processId: typeModal === 'approve' ? 1 : 3,
        notes: notes,
        ...initialValues,
        receiptDateOfPurchase:
          initialValues.receiptDateOfPurchase === null
            ? undefined
            : formatDateTime(
                initialValues.receiptDateOfPurchase,
                formatDateTimes
              ),
        matchedMerchantID:
          initialValues.matchedMerchantID === ''
            ? undefined
            : initialValues.matchedMerchantID,
        receiptAddress:
          initialValues.receiptAddress === ''
            ? undefined
            : initialValues.receiptAddress,
        rejectReason:
          initialValuesDeclined.rejectReason === ''
            ? undefined
            : initialValuesDeclined.rejectReason,
        rejectedFields: rejectedFields.length > 0 ? rejectedFields : undefined,
      })
      .then(res => {
        if (res.success) {
          reloadPage();
          setIsLoading(false);
          showNotification({
            message:
              typeModal === 'approve'
                ? success.APPROVAL_SUCCESS
                : success.DECLINED_SUCCESS,
          });
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const validationWhenApproveWithNoAmend = () => {
    if (
      typeModal !== 'declined' &&
      ((dataDetail?.merchantId === null &&
        initialValues?.matchedMerchantID === '') ||
        (dataDetail?.spentAmount === null &&
          initialValues?.receiptTotal === '') ||
        (dataDetail?.pointValue === null && initialValues?.pointValue === ''))
    ) {
      setErrorMissedFieldWhenApproveWithNoAmend(
        'This transaction has missing information. Please amend before approving.'
      );
      return true;
    }
    setErrorMissedFieldWhenApproveWithNoAmend(null);
    return false;
  };

  const validationKeySelected = () => {
    if (yesNoAmend === 'true') {
      if (!Object.values(keysSelected).find(f => f === true)) {
        setErrorMessageSelect(
          'Select at least one field you would like to amend, or select “No” to cancel the amendments.'
        );
        return true;
      }
    }
    if (typeModal === 'declined') {
      if (initialValuesDeclined.rejectReason === '') {
        setErrorMessageSelect('Please select a reason for rejection.');
        return true;
      }
    }
    setErrorMessageSelect(null);
    return false;
  };

  const validationFields = () => {
    let errorsObject = {
      oCRHistoryReceiptId: '',
      receiptTotal: '',
      receiptDateOfPurchase: '',
      receiptMerchantName: '',
      matchedMerchantID: '',
      receiptAddress: '',
      pointValue: '',
    };
    let isError = false;
    if (
      keysSelected.oCRHistoryReceiptId === true &&
      initialValues.oCRHistoryReceiptId === ''
    ) {
      errorsObject = {...errorsObject, oCRHistoryReceiptId: 'Required'};
      isError = true;
    }
    if (
      keysSelected.receiptTotal === true &&
      initialValues.receiptTotal === ''
    ) {
      errorsObject = {...errorsObject, receiptTotal: 'Required'};
      isError = true;
    }
    if (
      keysSelected.receiptDateOfPurchase === true &&
      initialValues.receiptDateOfPurchase === null
    ) {
      errorsObject = {...errorsObject, receiptDateOfPurchase: 'Required'};
      isError = true;
    }
    if (
      keysSelected.receiptMerchantName === true &&
      initialValues.receiptMerchantName === undefined
    ) {
      errorsObject = {...errorsObject, receiptMerchantName: 'Required'};
      isError = true;
    }
    if (
      keysSelected.receiptPartnerAndAddress === true &&
      initialValues.matchedMerchantID === ''
    ) {
      errorsObject = {...errorsObject, matchedMerchantID: 'Required'};
      isError = true;
    }
    if (
      keysSelected.receiptPartnerAndAddress === true &&
      initialValues.receiptAddress === ''
    ) {
      errorsObject = {...errorsObject, receiptAddress: 'Required'};
      isError = true;
    }
    if (keysSelected.pointValue === true && initialValues.pointValue === '') {
      errorsObject = {...errorsObject, pointValue: 'Required'};
      isError = true;
    }
    setErrorInputFields(errorsObject);
    return isError;
  };

  const resetForm = () => {
    setInitialValuesDeclined({rejectReason: ''});
    setInitialValues({
      oCRHistoryReceiptId: '',
      receiptDateOfPurchase: null,
      receiptTotal: '',
      receiptMerchantName: undefined,
      matchedMerchantID: '',
      receiptAddress: '',
      pointValue: '',
    });
    setKeysSelected({
      oCRHistoryReceiptId: false,
      receiptDateOfPurchase: false,
      receiptTotal: false,
      receiptPartnerAndAddress: false,
      pointValue: false,
    });
    setRejectedFields([]);
    setErrorMessageSelect(null);
    setReceiptAddressValue('');
    setErrorInputFields({
      oCRHistoryReceiptId: '',
      receiptTotal: '',
      receiptDateOfPurchase: '',
      receiptMerchantName: '',
      matchedMerchantID: '',
      receiptAddress: '',
      pointValue: '',
    });
    setErrorMissedFieldWhenApproveWithNoAmend(null);
  };

  useEffect(() => {
    if (Object.values(keysSelected).find(f => f === true)) {
      setErrorMessageSelect(null);
    }
  }, [keysSelected]);

  useEffect(() => {
    resetForm();
  }, [yesNoAmend]);

  useEffect(() => {
    setErrorMissedFieldWhenApproveWithNoAmend(null);
  }, [initialValues]);

  useEffect(() => {
    setErrorMessageSelect(null);
  }, [initialValuesDeclined]);

  return (
    <Modal
      visible={isModalApprovalReceipt}
      onClose={() => {
        onClose();
        resetForm();
      }}
      maxWidth="sm"
    >
      <DialogContent
        sx={{
          padding: '0px 64px',
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item mt={3} xs={12} md={12} textAlign="center">
            <StyledTitle className={`type type--${typeModal}`}>
              {title}
            </StyledTitle>
          </Grid>
        </Grid>
        {typeModal === 'approve' && (
          <ApproveReceiptForm
            transactionDetail={dataDetail}
            setInitialValues={setInitialValues}
            initialValues={initialValues}
            setYesNoAmend={setYesNoAmend}
            yesNoAmend={yesNoAmend}
            setKeysSelected={setKeysSelected}
            keysSelected={keysSelected}
            setReceiptAddressValue={setReceiptAddressValue}
            receiptAddressValue={receiptAddressValue}
            errorInputFields={errorInputFields}
            setErrorInputFields={setErrorInputFields}
            errorMissedFieldWhenApproveWithNoAmend={
              errorMissedFieldWhenApproveWithNoAmend
            }
          />
        )}
        {typeModal === 'declined' && (
          <DeclineReceiptForm
            transactionDetail={dataDetail}
            setInitialValues={setInitialValuesDeclined}
            initialValues={initialValuesDeclined}
            rejectedFields={rejectedFields}
            setRejectedFields={setRejectedFields}
          />
        )}
        {errorMessageSelect !== null && (
          <span style={{display: 'block'}} className="mandatory-label">
            {errorMessageSelect}
          </span>
        )}
      </DialogContent>
      <StyledModalActions>
        <Grid container>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button
                variant="outlined"
                onClick={() => {
                  onClose();
                  resetForm();
                  setYesNoAmend(false);
                }}
                width="125px"
                loading={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={onClick}
                width="125px"
                loading={isLoading}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </StyledModalActions>
    </Modal>
  );
}
export default ModalApprovalReceipt;
