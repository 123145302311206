/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {InputLabel, FormHelperText} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
// style
import {StyledSelect, StyledPlaceholder} from './SimpleSelect.style';

type SelectOptionsProps = {
  label: string;
  value: string;
};

interface SelectProps {
  label?: string;
  name?: string;
  id?: string;
  labelId: string;
  value: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onChangeSelect: (event: any) => void;
  selectOptions: Array<SelectOptionsProps>;
  displayEmpty?: boolean;
  size?: 'small' | 'medium';
  placeholder?: string;
  onBlur?: (any) => void;
  error?: any;
  helperText?: any;
  disabled?: boolean;
}

function Select({
  label,
  name,
  labelId,
  id = labelId,
  value,
  variant = 'standard',
  onChangeSelect,
  selectOptions,
  displayEmpty,
  size,
  placeholder,
  onBlur,
  error,
  helperText,
  disabled = false,
  ...other
}: SelectProps) {
  return (
    <>
      <InputLabel id={labelId} htmlFor={labelId}>
        {label}
      </InputLabel>
      <StyledSelect
        disabled={disabled}
        labelId={labelId}
        name={name}
        id={id}
        variant={variant}
        value={value}
        label={label}
        onChange={onChangeSelect}
        displayEmpty={displayEmpty}
        onBlur={onBlur}
        size={size}
        error={error}
        renderValue={
          value !== ''
            ? () => selectOptions.find(item => item.value === value)?.label
            : () => <StyledPlaceholder>{placeholder}</StyledPlaceholder>
        }
        {...other}
      >
        {selectOptions.map((item, index) => (
          <MenuItem value={item.value} key={index}>
            {item?.image ? (
              <img
                src={item?.image}
                width={item?.width}
                height={item?.height}
                style={{paddingRight: '16px', objectFit: 'cover'}}
              />
            ) : (
              ''
            )}
            {item.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && (
        <FormHelperText
          style={{
            color: '#D45957',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}

export default Select;
