import React, {useState, useEffect} from 'react';
import {Checkbox, FormControl, FormControlLabel, Grid} from '@mui/material';
import {
  DateTimePicker,
  Input,
  RadioGroups,
  Select,
} from '../../../../../components';
import {Typography} from './ApproveReceiptForm.style';
import IslanderPartnerService from '../../../../../services/Islander/islander.service';
import {DataDetail} from '../../../../../types/typeAllTransaction';
import OcrHistoryService from '../../../../../services/OCRHistory/ocrHistory.service';
import {
  envRole,
  formatDate,
  formatDateTime,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
  formatLocalDateFromUTCTime,
} from '../../../../../utils';

interface InitialValues {
  oCRHistoryReceiptId?: string | undefined;
  receiptDateOfPurchase?: string | undefined;
  receiptTotal?: number | undefined;
  receiptMerchantName?: string | undefined;
  matchedMerchantID?: number | undefined;
  receiptAddress?: string | undefined;
  pointValue?: number | undefined;
}

interface ApproveReceiptFormProps {
  transactionDetail?: DataDetail;
  setInitialValues: (params: any) => void;
  initialValues: InitialValues;
  setYesNoAmend: (any) => void;
  yesNoAmend: any;
  setKeysSelected: (any) => void;
  keysSelected: any;
  receiptAddressValue: any;
  setReceiptAddressValue: () => void;
  errorInputFields?: any;
  setErrorInputFields?: (params: any) => void;
  errorMissedFieldWhenApproveWithNoAmend?: string;
}

const ApproveReceiptForm = ({
  transactionDetail,
  initialValues,
  setInitialValues,
  yesNoAmend,
  setYesNoAmend,
  keysSelected,
  setKeysSelected,
  receiptAddressValue,
  setReceiptAddressValue,
  errorInputFields,
  setErrorInputFields,
  errorMissedFieldWhenApproveWithNoAmend,
}: ApproveReceiptFormProps) => {
  const localFormat = 'DD MMM YYYY hh:mmA';
  const [islanderPartnerNames, setIslanderPartnerNames] = useState<any>([]);
  const [islanderPartnerAddress, setIslanderPartnerAddress] = useState<any>([]);
  const [receiptDetail, setReceiptDetail] = useState<any>(null);
  const yesNoOptions = [
    {key: 'Yes', value: true},
    {
      key: 'No',
      value: false,
    },
  ];

  const handleChangeKeysSelected = (value, key) => {
    setKeysSelected({
      ...keysSelected,
      [key]: value,
    });
  };

  const handleChangeValue = (value, key) => {
    if (key === 'matchedMerchantID') {
      setInitialValues({
        ...initialValues,
        [key]: value,
        receiptMerchantName:
          islanderPartnerNames.find(f => f.value === value)?.label ?? undefined,
        receiptAddress: '',
      });
      setIslanderPartnerAddress(GetMerchantAddressOptions(value));
      setErrorInputFields({...errorInputFields, [key]: ''});
      setReceiptAddressValue('');
    } else if (key === 'receiptAddress') {
      setInitialValues({
        ...initialValues,
        [key]:
          islanderPartnerAddress.find(f => f.value === value)?.label ??
          undefined,
      });
      setReceiptAddressValue(value);
      setErrorInputFields({...errorInputFields, [key]: ''});
    } else {
      setInitialValues({...initialValues, [key]: value});
      setErrorInputFields({...errorInputFields, [key]: ''});
    }
  };

  const GetMerchantAddressOptions = value => {
    const listAddress = islanderPartnerNames.find(
      f => f.value === value
    )?.listReceiptAddress;
    if (listAddress !== null && Array.isArray(listAddress)) {
      const addressOptions = listAddress.map((m, index) => ({
        label: m,
        value: index + 1,
      }));
      return addressOptions;
    }

    return [];
  };

  const resetForm = () => {
    setKeysSelected({
      oCRHistoryReceiptId: false,
      receiptDateOfPurchase: false,
      receiptTotal: false,
      receiptPartnerAndAddress: false,
      pointValue: false,
    });
    setIslanderPartnerAddress([]);
    setInitialValues({
      oCRHistoryReceiptId: undefined,
      receiptDateOfPurchase: '',
      receiptTotal: undefined,
      receiptMerchantName: undefined,
      matchedMerchantID: '',
      receiptAddress: '',
      pointValue: undefined,
    });
  };

  const getListMerchant = () => {
    new IslanderPartnerService()
      .get('get-filter-data', {
        isAvailableMerchantReceipt_eq: 1,
      })
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setIslanderPartnerNames(
            res?.data?.data?.reduce((items, item) => {
              items.push({
                label: item.name || '-',
                value: item.id,
                listReceiptAddress: item.listReceiptAddress,
              });
              return items;
            }, [])
          );
        }
      });
  };

  const renderValue = (value, defaultValue: any = '--') => {
    const isNullOrEmpty = value === null || value === '' || value === undefined;
    return !isNullOrEmpty ? (
      <span style={{fontWeight: 'bolder', display: 'block'}}>{value}</span>
    ) : (
      <span style={{fontWeight: 'bolder', display: 'block'}}>
        {defaultValue}
      </span>
    );
  };

  const getReceiptDetail = (id: number | undefined) => {
    new OcrHistoryService().getAll({id_eq: id}).then(res => {
      if (res?.success === true) {
        if (Array.isArray(res?.data?.items) && res?.data?.items.length > 0) {
          setReceiptDetail(res?.data?.items[0]);
        }
      }
    });
  };

  useEffect(() => {
    if (yesNoAmend === 'false') {
      resetForm();
    }
  }, [yesNoAmend]);

  useEffect(() => {
    getListMerchant();
  }, []);

  useEffect(() => {
    if (transactionDetail?.receiptId !== null) {
      getReceiptDetail(transactionDetail?.receiptId);
    }
  }, [transactionDetail]);
  return (
    <Grid container>
      <Grid item key="yesNo-box" sm={12}>
        <FormControl fullWidth margin="normal">
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Is there anything you need to amend?
          </Typography>
          <RadioGroups
            name="yesNoAmend"
            options={yesNoOptions}
            values={yesNoAmend}
            onChange={e => {
              setYesNoAmend(e.target.value);
            }}
          />
        </FormControl>
      </Grid>
      {yesNoAmend === 'true' && (
        <Grid item key="select-field-box" sm={12}>
          <FormControl fullWidth margin="normal">
            <Typography variant="body2" mb={2} fontWeight="bolder">
              Select field(s) you would like to amend{' '}
              <span className="mandatory-label">*</span>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  value={keysSelected['oCRHistoryReceiptId']}
                  checked={keysSelected['oCRHistoryReceiptId']}
                  onChange={(e, value) =>
                    handleChangeKeysSelected(value, 'oCRHistoryReceiptId')
                  }
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Receipt/Order number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={keysSelected['receiptDateOfPurchase']}
                  checked={keysSelected['receiptDateOfPurchase']}
                  onChange={(e, value) =>
                    handleChangeKeysSelected(value, 'receiptDateOfPurchase')
                  }
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Receipt date and time"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={keysSelected['receiptTotal']}
                  checked={keysSelected['receiptTotal']}
                  onChange={(e, value) =>
                    handleChangeKeysSelected(value, 'receiptTotal')
                  }
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Total amount"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={keysSelected['pointValue']}
                  checked={keysSelected['pointValue']}
                  onChange={(e, value) =>
                    handleChangeKeysSelected(value, 'pointValue')
                  }
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Points earned"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={keysSelected['receiptPartnerAndAddress']}
                  checked={keysSelected['receiptPartnerAndAddress']}
                  onChange={(e, value) =>
                    handleChangeKeysSelected(value, 'receiptPartnerAndAddress')
                  }
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Islander Partner Name/Address"
            />
          </FormControl>
        </Grid>
      )}
      <Grid item key="infos-box" sm={12}>
        {keysSelected['oCRHistoryReceiptId'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Receipt/Order number detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(receiptDetail?.receiptId)}
            </span>
          </Typography>
        )}
        {keysSelected['receiptDateOfPurchase'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Receipt date and time detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(
                formatDate(receiptDetail?.receiptDateOfPurchase, localFormat)
              )}
            </span>
          </Typography>
        )}
        {keysSelected['receiptTotal'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Total amount detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(
                receiptDetail?.receiptTotal !== null
                  ? '$' + receiptDetail?.receiptTotal
                  : null
              )}
            </span>
          </Typography>
        )}
        {keysSelected['pointValue'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Points earned detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(transactionDetail?.pointValue)}
            </span>
          </Typography>
        )}
        {keysSelected['receiptPartnerAndAddress'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Islander Partner Name detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(receiptDetail?.merchantName)}
            </span>
          </Typography>
        )}
        {keysSelected['receiptPartnerAndAddress'] && (
          <Typography variant="body2" mb={2} fontWeight="bolder">
            Islander Partner Address detected
            <span style={{display: 'block'}} className="mandatory-label">
              {renderValue(receiptDetail?.receiptAddress)}
            </span>
          </Typography>
        )}
      </Grid>
      <Grid item key="form-box" sm={12}>
        {keysSelected['oCRHistoryReceiptId'] && (
          <FormControl fullWidth>
            <Typography variant="subtitle2">Receipt/Order number</Typography>
            <Input
              type="text"
              name="oCRHistoryReceiptId"
              placeholder="Enter Receipt/Order number"
              helperText={errorInputFields.oCRHistoryReceiptId}
              error={errorInputFields.oCRHistoryReceiptId !== ''}
              size="small"
              variant="outlined"
              value={initialValues.oCRHistoryReceiptId}
              onChange={e =>
                handleChangeValue(e.target.value, 'oCRHistoryReceiptId')
              }
            />
          </FormControl>
        )}
        {keysSelected['receiptDateOfPurchase'] && (
          <FormControl fullWidth sx={{marginBottom: '18px'}}>
            <Typography variant="subtitle2">Receipt date and time</Typography>
            <DateTimePicker
              value={initialValues.receiptDateOfPurchase}
              helperText={errorInputFields.receiptDateOfPurchase}
              isErrorMessage={errorInputFields.receiptDateOfPurchase !== ''}
              onChange={e => {
                handleChangeValue(e, 'receiptDateOfPurchase');
              }}
            />
          </FormControl>
        )}
        {keysSelected['receiptTotal'] && (
          <FormControl fullWidth>
            <Typography variant="subtitle2">Total amount</Typography>
            <Input
              type="number"
              name="receiptTotal"
              placeholder="Enter Total amount"
              helperText={errorInputFields.receiptTotal}
              error={errorInputFields.receiptTotal !== ''}
              size="small"
              variant="outlined"
              value={initialValues.receiptTotal}
              onChange={e => handleChangeValue(e.target.value, 'receiptTotal')}
            />
          </FormControl>
        )}
        {keysSelected['pointValue'] && (
          <FormControl fullWidth>
            <Typography variant="subtitle2">Points earned</Typography>
            <Input
              type="number"
              name="pointValue"
              placeholder="Enter Points earned"
              helperText={errorInputFields.pointValue}
              error={errorInputFields.pointValue !== ''}
              size="small"
              variant="outlined"
              value={initialValues.pointValue}
              onChange={e => handleChangeValue(e.target.value, 'pointValue')}
            />
          </FormControl>
        )}
        {keysSelected['receiptPartnerAndAddress'] && (
          <FormControl fullWidth sx={{marginBottom: '18px'}}>
            <Typography variant="subtitle2">Islander Partner Name</Typography>
            <Select
              labelId="matchedMerchantID"
              displayEmpty
              variant="outlined"
              onChangeSelect={e =>
                handleChangeValue(e.target.value, 'matchedMerchantID')
              }
              value={initialValues.matchedMerchantID}
              placeholder="Select Island Partner's Name"
              size="small"
              selectOptions={islanderPartnerNames}
              error={errorInputFields.matchedMerchantID !== ''}
              helperText={errorInputFields.matchedMerchantID}
            />
          </FormControl>
        )}
        {keysSelected['receiptPartnerAndAddress'] && (
          <FormControl fullWidth sx={{marginBottom: '18px'}}>
            <Typography variant="subtitle2">
              Islander Partner Address
            </Typography>
            <Select
              disabled={islanderPartnerAddress.length === 0}
              labelId="receiptAddress"
              displayEmpty
              variant="outlined"
              onChangeSelect={e =>
                handleChangeValue(e.target.value, 'receiptAddress')
              }
              value={receiptAddressValue}
              placeholder="Select Islander Partner Address"
              size="small"
              selectOptions={islanderPartnerAddress}
              error={errorInputFields.receiptAddress !== ''}
              helperText={errorInputFields.receiptAddress}
            />
          </FormControl>
        )}
      </Grid>
      {errorMissedFieldWhenApproveWithNoAmend !== null && (
        <span className="mandatory-label">
          {errorMissedFieldWhenApproveWithNoAmend}
        </span>
      )}
    </Grid>
  );
};

export default ApproveReceiptForm;
