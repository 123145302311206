/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import get from 'lodash/get';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import {path} from '../routes/path';
import {useEffect, useState} from 'react';
import {USER_TYPE} from '../constants/UserType';

require('crypto');

// const PIN_KEY = '5256091c4f24451299aa514e1dfe9fac';

export function replaceEmail(email: string) {
  if (email) {
    const split = email.split('@');
    return (
      email.substr(0, 3) +
      new Array(split[0].length - 1).fill('•').join('') +
      '@' +
      split[1]
    );
  }
  return null;
}

export function limitString(value: string, limitLength = 35) {
  if (value) {
    const split = value.split('.');
    const fileName = split[0];
    const extension = split[1];
    if (fileName.length > limitLength) {
      return fileName.substring(0, limitLength - 3) + '....' + extension;
    }
    return value;
  }
  return null;
}

export function formatDate(d: any, f: string) {
  if (d) {
    try {
      return moment(d).format(f);
    } catch (error) {
      return '';
    }
  }
  return '';
}

/**
 * BASE_PERMISSION: list permissions that everyone can access
 */
export const BASE_PERMISSION: any = [`/${path.AUTH}`];

/**
 * PERMISSION: permission based on user type
 */
export const PERMISSION: any = {
  administrator: 'all-access',
  merchant: {
    [`/${path.ISLAND_PARTNERS}`]: ['read'],
    [`/${path.ALL_TRANSACTION}`]: ['read'],
    [`/${path.USER}`]: ['read'],
  },
};

export const hasPermission = (
  pathName: string,
  permissionName: string | null = null
) => {
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';

  /* Check for base permission, that everyone can access */
  if (BASE_PERMISSION?.includes(pathName)) return true;
  /* If current pathName is not in base permission, check specified permission */
  if (!Object.keys(PERMISSION)?.includes(role)) return false;
  if (PERMISSION[role] !== 'all-access') {
    if (!Object.keys(PERMISSION[role])?.includes(pathName)) return false;
    if (!PERMISSION[role][pathName]?.includes(permissionName)) return false;
  }
  return true;
};

export const getHmacHashCode = (payload: any, flag = false) => {
  const hmacMessage = flag ? payload : JSON.stringify(payload);
  const hmacHash = hmacSHA256(
    hmacMessage,
    process.env.REACT_APP_PIN_KEY?.toString() || ''
  );
  const hcode = Base64.stringify(hmacHash);
  return hcode;
};

export const setStorageList = (list: object | null) => {
  if (Array.isArray(list) && list.length > 0) {
    list.map(item => {
      const {key, value} = item;
      setStorage(key, value);
    });
  }
};

export const setStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify({data: value}) : value
  );
};

export const getStorage = (key: string) => {
  const value: string | null = window.sessionStorage.getItem(key);
  try {
    if (value !== null) {
      const parse = JSON.parse(value);
      return get(parse, 'data') || parse;
    }
    return value;
  } catch (error) {
    return value;
  }
};

export const removeStorageList = (list: object | null) => {
  if (Array.isArray(list) && list.length > 0) {
    list.map(key => removeStorage(key));
  }
};

export const removeStorage = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export function formatPhoneNumber(phoneNumberString: any) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{4})(\d{4})$/);
  if (match) {
    return match[1] + ' ' + match[2];
  }
  return null;
}

/**
 * Common method to format date time
 * @param d Input date/date time
 * @param format Format for the input date/date time
 * @returns
 */
export function formatDateTime(d: any, format: any = undefined) {
  return moment(d, format).toISOString();
}

/**
 * Get start of date
 * @param d Input date/date time
 * @param format Format for the input date/date time
 * @returns
 */
export function getStartOfDate(d: any, format: any = undefined) {
  const date = moment(d, format).startOf('day');
  return formatDateTime(date);
}

/**
 * Get end of date
 * @param d Input date/date time
 * @param format Format for the input date/date time
 * @returns
 */
export function getEndOfDate(d: any, format: any = undefined) {
  const date = moment(d, format).endOf('day');
  return formatDateTime(date);
}

/**
 * Format UTC time to user local time
 * Date input need to be a UTC time (timezone +0)
 * @param d UTC date time
 * @param format Output date/date time format
 * @returns
 */
export function formatLocalDateFromUTCTime(d: any, format: any = 'DD/MM/YYYY') {
  if (!d) return null;
  return moment(d).format(format);
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const envRole = process.env.REACT_APP_ROLE || '1';
