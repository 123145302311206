/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../constants/image';
import {Modal, Select, Button, Input} from '../../../components';
import {MultipleSelectCheckbox} from '../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  setOptionVoucherCategory?: () => void;
  optionVoucherCategory?: any;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  icons?: any[];
  shopCategories?: any[];
  valueDisplayOn?: any[];
  setValueDisplayOn?: () => void;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean = () => {},
  valueFilter,
  setValueFilter = () => {},
  icons,
  shopCategories,
  valueDisplayOn,
  setValueDisplayOn = () => {},
}: ModalFilterProps) {
  const [optionIsDisplayOn, setOptionIsDisplayOn] = useState<any[]>([
    {title: 'App Dashboard’s Discovery section (Guest mode)', value: 1},
    {title: 'App Dashboard’s Discovery section (Logged In mode)', value: 2},
    {title: 'App Voucher’s Listing filter', value: 3},
  ]);

  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCleanModalForm = () => {
    handleClean();
    setValueDisplayOn([]);
  };

  const onSetIsDisplayOn = (values: any[]) => {
    setValueDisplayOn(values);
    setValueFilter((preState: any) => ({
      ...preState,
      showOnMobileDashboardGuestMode_eq:
        !!values.find(f => f.value === 1) ?? '',
      showOnMobileDashboardLoggedIn_eq: !!values.find(f => f.value === 2) ?? '',
      showOnMobileVoucherListing_eq: !!values.find(f => f.value === 3) ?? '',
    }));
  };

  useEffect(() => {
    let newValue: any[] = [];
    if (valueFilter.showOnMobileDashboardGuestMode_eq === 'true') {
      const guestModeValue = optionIsDisplayOn.filter(f => f.value === 1);
      newValue = [...newValue, ...guestModeValue];
    }

    if (valueFilter.showOnMobileDashboardLoggedIn_eq === 'true') {
      const loggedModeValue = optionIsDisplayOn.filter(f => f.value === 2);
      newValue = [...newValue, ...loggedModeValue];
    }

    if (valueFilter.showOnMobileVoucherListing_eq === 'true') {
      const voucherListingValue = optionIsDisplayOn.filter(f => f.value === 3);
      newValue = [...newValue, ...voucherListingValue];
    }

    setValueDisplayOn(newValue);
  }, []);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="sm">
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-18px'}}>
            <Typography variant="subtitle2" mb={2}>
              By Category Title
            </Typography>
            <Input
              type="text"
              name="title_contains"
              placeholder="Enter Title"
              size="small"
              variant="outlined"
              value={valueFilter.title_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-18px'}}>
            <Typography variant="subtitle2" mb={2}>
              By Category Description
            </Typography>
            <Input
              type="text"
              name="description_contains"
              placeholder="Enter Description"
              size="small"
              variant="outlined"
              value={valueFilter.description_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Icon asset – Inactive
            </Typography>
            <Select
              labelId="iconInactive_contains"
              name="iconInactive_contains"
              size="small"
              variant="outlined"
              displayEmpty
              placeholder="Select Inactive Icon"
              onChangeSelect={onChange}
              value={valueFilter?.iconInactive_contains}
              selectOptions={icons}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Icon asset – Active
            </Typography>
            <Select
              labelId="iconActive_contains"
              name="iconActive_contains"
              size="small"
              variant="outlined"
              displayEmpty
              placeholder="Select Active Icon"
              onChangeSelect={onChange}
              value={valueFilter.iconActive_contains}
              selectOptions={icons}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Link to Sitecore’s Shop category
            </Typography>
            <Select
              labelId="associatedSitecoreShopCategory_contains"
              name="associatedSitecoreShopCategory_contains"
              size="small"
              variant="outlined"
              displayEmpty
              placeholder="Select Category"
              onChangeSelect={onChange}
              value={valueFilter.associatedSitecoreShopCategory_contains}
              selectOptions={shopCategories}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Display On
            </Typography>
            <MultipleSelectCheckbox
              placeholder="Select Display On?"
              value={valueDisplayOn}
              onChange={(e, values) => {
                onSetIsDisplayOn(values);
              }}
              options={optionIsDisplayOn}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleCleanModalForm}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={
                !valueFilter.title_contains &&
                !valueFilter.description_contains &&
                !valueFilter.iconActive_contains &&
                !valueFilter.iconInactive_contains &&
                !valueFilter.associatedSitecoreShopCategory_contains &&
                !valueFilter.showOnMobileVoucherListing_eq &&
                !valueFilter.showOnMobileDashboardGuestMode_eq &&
                !valueFilter.showOnMobileDashboardLoggedIn_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
