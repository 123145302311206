/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

// style
import {
  StyledDateTimePicker,
  StyledInputDateTimePicker,
  StyledTextField,
} from './DateTimePicker.style';

interface DateTimePickerProps {
  label?: string;
  value?: any | Date | string | number;
  minDate?: Date;
  toolbarPlaceholder?: string;
  onChange?: (newValue: Date | null) => void;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  format?: string;
  views?: any;
  helperText?: string;
  required?: boolean;
  isEmptyValue?: boolean;
  isErrorMessage?: boolean | undefined;
}

function DateTimePicker({
  label,
  value,
  minDate,
  toolbarPlaceholder,
  onChange,
  disabled,
  loading,
  readOnly,
  format = 'MM/dd/yyyy hh:mm aa',
  helperText = 'Date is required',
  required = false,
  isEmptyValue = false,
  isErrorMessage = false,
  ...other
}: DateTimePickerProps) {
  const [isError, setIsError] = useState(false);

  const handleFocus = () => {
    if (required) {
      setIsError(value === null);
    }
  };

  useEffect(() => {
    if (required) {
      setIsError(isEmptyValue);
    }
  }, [isEmptyValue]);

  useEffect(() => {
    if (isErrorMessage) {
      return setIsError(true);
    }
    return setIsError(false);
  }, [isErrorMessage]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDateTimePicker
        label={label}
        value={value}
        minDate={minDate}
        toolbarPlaceholder={toolbarPlaceholder}
        onChange={onChange}
        disabled={disabled}
        loading={loading}
        readOnly={readOnly}
        inputFormat={format}
        renderInput={params => (
          <StyledInputDateTimePicker error={isError}>
            <StyledTextField
              {...params}
              size="small"
              error={isError}
              onFocus={handleFocus}
              helperText={isError ? helperText : null}
              sx={{
                marginTop: isError ? '20px' : null,
              }}
            />
          </StyledInputDateTimePicker>
        )}
        {...other}
      />
    </LocalizationProvider>
  );
}
export default DateTimePicker;
