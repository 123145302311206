import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate, useParams, NavLink} from 'react-router-dom';
import {CardContent, Grid, Stack} from '@mui/material';

import {Breadcrumbs, Button, Chip, ModalZoomImage} from '../../../components';
import {DataDetail, typeRoutes} from '../../../types/typeAllTransaction';
import {path} from '../../../routes/path';
import {statusColor} from '../../../styles/status-color';
import ModalApproval from '../components/ModalApproval/ModalApproval';
import AllTransactionService from '../../../services/AllTransaction/all-transaction.service';
import {
  envRole,
  formatDate,
  formatLocalDateFromUTCTime,
  hasPermission,
} from '../../../utils';
import {ColorType} from '../../../types/typeChip';
import {
  ELEMENT_STATUS,
  POINT_SUCCESS,
  POINT_SUCCESS_COLOR,
  POINT_SUCCESS_LABEL,
} from '../../../constants/status';
import queryString from 'query-string';

// styles
import {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledChip,
  StyledCardContent,
  ReceiptImage,
} from './AllTransactionDetail.style';
import {USER_TYPE} from '../../../constants/UserType';
import ModalApprovalReceipt from '../components/ModalApprovalReceipt/ModalApprovalReceipt';
import {
  DisplayFieldsLabel,
  OCRRejectReasonsLabel,
} from '../../../constants/receiptValidationLogsStatus';
import {IsNullOrEmptyOrUndefined, stringFormat} from '../../../utils/helpers';
import {TRANSACTION_TYPES} from '../../../constants/transaction';

function AllTransactionDetail() {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const locationUrl = useLocation();
  const idDetail = paramsUrl?.id;
  const membershipId = locationUrl?.state?.membershipId;
  const merchantId = locationUrl?.state?.merchantId;

  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const formatDateTime = 'DD/MM/YYYY HH:mm';
  // states
  const [isModalApproval, setIsModalApproval] = useState(false);
  const [isModalApprovalReceipt, setIsModalApprovalReceipt] = useState(false);
  const [dataDetail, setDataDetail] = useState<DataDetail>({});
  const [typeModal, setTypeModal] = useState('');
  const [vouchersUsedTitle, setVouchersUsedTitle] = useState<
    string | undefined
  >(undefined);
  const [isModalZoomImage, setIsModalZoomImage] = useState(false);
  const [imageZoom, setImageZoom] = useState('');

  const renderCaption = (value: string) => {
    if (value) {
      return (
        <Typography color="rgba(0, 0, 0, 0.5)" mb="31px" variant="h6">
          {value}:
        </Typography>
      );
    }
    return null;
  };

  const renderSubtitle = value => {
    return (
      <Typography mb="31px" variant="h6">
        {value ? value : '--'}
      </Typography>
    );
  };

  const renderRejectReason = (rejectReason: number | undefined) => {
    let title = '--';
    let color = 'black';
    if (rejectReason !== null) {
      title = OCRRejectReasonsLabel[rejectReason - 1];
      color = 'red';
    }
    return (
      <Typography mb="31px" variant="h6" color={color}>
        {title}
      </Typography>
    );
  };

  const renderRejectedFields = (rejectedFields: string[]) => {
    if (rejectedFields === null) {
      return (
        <Typography mb="31px" variant="h6">
          --
        </Typography>
      );
    }

    return (
      <div>
        {rejectedFields.split(',').map((item, index) => (
          <li key={item + '-' + index}>
            <Typography
              mb="31px"
              variant="body2"
              display="inline"
              fontWeight="bolder"
            >
              {DisplayFieldsLabel[item]}
            </Typography>
          </li>
        ))}
      </div>
    );
  };

  const renderTextStatus = (operation: number, point: number) => {
    const fontSize = '50px';
    if (point > 0) {
      return (
        <Typography
          color={operation === 1 ? statusColor.approved : statusColor.failed}
          variant="h1"
          fontSize={fontSize}
          mt={5}
          textAlign="center"
        >
          {operation === 1 ? `+ ${point}` : `- ${point}`}
        </Typography>
      );
    }
    return (
      <Typography
        color="#000000"
        variant="h1"
        fontSize={fontSize}
        mt={5}
        textAlign="center"
      >
        {point}
      </Typography>
    );
  };

  const toggleModalApproval = () => {
    if (dataDetail?.receiptId !== null) {
      setIsModalApprovalReceipt(!isModalApprovalReceipt);
    } else {
      setIsModalApproval(!isModalApproval);
    }
  };

  const getVoucherUsed = data => {
    if (data.ticketTitle && data.secondTicketTitle) {
      return setVouchersUsedTitle(
        `${data.ticketTitle}, ${data.secondTicketTitle}`
      );
    }

    if (data.ticketTitle) {
      return setVouchersUsedTitle(data.ticketTitle);
    }

    return setVouchersUsedTitle(undefined);
  };

  const getDataDetail = () => {
    new AllTransactionService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setDataDetail(res.data.data);
        getVoucherUsed(res.data.data);
      }
    });
  };

  const renderSimilarTransactionIds = dataDetail => {
    if (
      dataDetail?.duplicatedTransactionIds !== null &&
      Array.isArray(dataDetail.duplicatedTransactionIds) &&
      dataDetail.duplicatedTransactionIds.length > 0
    ) {
      return dataDetail.duplicatedTransactionIds.map((item, index) => (
        <>
          {index > 0 && ', '}
          <NavLink
            style={{textDecoration: 'none'}}
            to={`/all-transaction/${item}`}
          >
            {item}
          </NavLink>
        </>
      ));
    }

    return '--';
  };

  const renderSimilarReceiptIds = dataDetail => {
    if (
      dataDetail?.duplicatedReceiptIds !== null &&
      Array.isArray(dataDetail.duplicatedReceiptIds) &&
      dataDetail.duplicatedReceiptIds.length > 0
    ) {
      return dataDetail.duplicatedReceiptIds.map((item, index) => (
        <>
          {index > 0 && ', '}
          <NavLink
            style={{textDecoration: 'none'}}
            to={`/all-transaction/receipt-validation-logs?id_eq=${item}`}
          >
            {item}
          </NavLink>
        </>
      ));
    }

    return '--';
  };

  const renderLink = () => {
    if (membershipId) {
      return `/${
        path.ALL_TRANSACTION
      }?membershipId=${membershipId}${queryString.stringify(
        locationUrl?.state?.filterParams
      )}`;
    }
    if (merchantId) {
      return `/${
        path.ALL_TRANSACTION
      }?merchantId=${merchantId}${queryString.stringify(
        locationUrl?.state?.filterParams
      )}`;
    }
    return `/${path.ALL_TRANSACTION}?${queryString.stringify(
      locationUrl?.state?.filterParams
    )}`;
  };

  const renderStatus = (value: number) => {
    if (!IsNullOrEmptyOrUndefined(value)) {
      const renderColor = POINT_SUCCESS_COLOR[value];
      const renderLabel = POINT_SUCCESS_LABEL[value];
      return <Chip label={renderLabel} color={renderColor as ColorType} />;
    }
  };

  const renderTransactionType = dataDetail => {
    const transactionType = dataDetail?.transactionType ?? 0;
    let transactionTypeString = '--';
    switch (transactionType) {
      case 1:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.islandPartnerName
        );
        break;
      case 2:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.ticketNumberId,
          dataDetail?.secondTicketNumberId ?? ''
        );
        break;
      case 3:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.ticketNumberId,
          dataDetail?.secondTicketNumberId ?? ''
        );
        break;
      case 4:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.workflowId
        );
        break;
      case 5:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.ticketNumberId
        );
        break;
      case 6:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.islandPartnerName
        );
        break;
      case 7:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.workflowId
        );
        break;
      default:
        transactionTypeString = '--';
        break;
    }

    return transactionTypeString.replace(/, $/, '');
  };

  const renderSpentAmount = () => {
    if (dataDetail?.operation === 2 && dataDetail?.islandPartnerName === null) {
      return '$ 0';
    }
    return dataDetail?.spentAmount
      ? `$ ${parseFloat(dataDetail?.spentAmount).toFixed(2)}`
      : '--';
  };

  const ValidateUsedOrRemovedVoucher = (value: any) => {
    return (
      value?.expiryDateTime === null &&
      value?.spentAmount === null &&
      value?.pointValue === null &&
      value?.merchantId === null &&
      value?.employeeId === null &&
      value?.ticketId !== null
    );
  };

  const toggleIsModalZoomImage = () => {
    setIsModalZoomImage(!isModalZoomImage);
  };

  const renderReceiptImage = image => {
    if (image === null) {
      return '--';
    }
    return (
      <ReceiptImage
        src={image}
        onClick={() => {
          toggleIsModalZoomImage();
          setImageZoom(image);
        }}
      />
    );
  };

  const renderSystemAssuranceLevel = (values: any) => {
    const percent =
      values?.receiptConfidence !== null
        ? values.receiptConfidence * 100
        : null;
    let color = '#6E6E6E';
    if (percent === null) {
      return (
        <Typography
          mb="31px"
          variant="h6"
          color={'GrayText'}
          fontWeight="bolder"
        >
          --
        </Typography>
      );
    }

    if (percent < 80) {
      color = '#E66A25';
    } else if (percent >= 80) {
      color = '#2E7D32';
    }
    return (
      <Typography
        mb="31px"
        variant="h6"
        sx={{color: color}}
      >{`${percent}%`}</Typography>
    );
  };

  const renderRowColumnsA = (title: string, value: string) => {
    return (
      <tr>
        <td width="34%" style={{verticalAlign: 'top'}}>
          {title}
        </td>
        <td width="64%" style={{verticalAlign: 'top'}}>
          {value}
        </td>
      </tr>
    );
  };

  const renderRowColumnsB = (title: string, value: string) => {
    return (
      <tr>
        <td width="50%" style={{verticalAlign: 'top'}}>
          {title}
        </td>
        <td width="50%" style={{verticalAlign: 'top'}}>
          {value}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    getDataDetail();
  }, [idDetail]);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <ModalApproval
        title={
          typeModal === 'approve'
            ? 'Before you approve'
            : 'You’re about to decline this transaction'
        }
        typeModal={typeModal}
        isModalApproval={isModalApproval}
        onClose={toggleModalApproval}
        dataDetail={dataDetail}
        reloadPage={getDataDetail}
        // setDataDetail={setDataDetail}
      />

      <ModalApprovalReceipt
        title={
          typeModal === 'approve'
            ? 'Before you approve'
            : 'You’re about to decline this transaction'
        }
        typeModal={typeModal}
        isModalApprovalReceipt={isModalApprovalReceipt}
        onClose={toggleModalApproval}
        dataDetail={dataDetail}
        reloadPage={getDataDetail}
        // setDataDetail={setDataDetail}
      />

      <ModalZoomImage
        visible={isModalZoomImage}
        image={imageZoom}
        alt="All Transaction Detail Image Zoom"
        onClose={toggleIsModalZoomImage}
      />

      <Typography variant="h3" gutterBottom>
        Transaction Detail
      </Typography>
      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            // typeRoutes('Dashboard', `/${path.DASHBOARD}`),
            typeRoutes('All Transaction', renderLink(), false, {
              membershipId: membershipId,
              merchantId: merchantId,
            }),
            typeRoutes('Detail', null, true),
          ]}
        />
      </StyledDivBreadcrumbs>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Typography variant="h6" fontSize="14px">
            {formatLocalDateFromUTCTime(
              dataDetail?.createdDateTime,
              'H:mm A, MMM DD, YYYY'
            ) || '--'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={9}>
        <Grid item xs={12} md={12}>
          <StyledChip>{renderStatus(dataDetail.processId)}</StyledChip>
          <Card
            pl={12}
            pr={12}
            sx={{
              position: 'relative',
              bottom: '16px',
            }}
          >
            <StyledCardContent>
              <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                  {renderTextStatus(
                    dataDetail?.operation,
                    dataDetail?.pointValue
                  )}
                  <Typography
                    variant="h6"
                    mt="10px"
                    textAlign="center"
                    fontStyle="oblique"
                  >
                    {dataDetail?.notes || '--'}
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    color="rgba(0, 0, 0, 0.5)"
                  >
                    {`# ${dataDetail?.id}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider my={6} />
              <Grid wrap="nowrap" container justifyContent="space-evenly">
                <table width="50%" style={{height: 'fit-content'}}>
                  <tbody>
                    {renderRowColumnsA(
                      +envRole === USER_TYPE.ADMINISTRATOR &&
                        renderCaption('Email Members'),
                      +envRole === USER_TYPE.ADMINISTRATOR &&
                        renderSubtitle(dataDetail?.email)
                    )}
                    {renderRowColumnsA(
                      renderCaption('Account ID'),
                      renderSubtitle(dataDetail?.customerId)
                    )}
                    {renderRowColumnsA(
                      renderCaption('Transactional Type'),
                      renderSubtitle(renderTransactionType(dataDetail))
                    )}
                    {renderRowColumnsA(
                      renderCaption('Spent Amount'),
                      renderSubtitle(renderSpentAmount())
                    )}
                    {renderRowColumnsA(
                      renderCaption('Receipt Image'),
                      renderSubtitle(
                        renderReceiptImage(dataDetail?.receiptImageUrl ?? null)
                      )
                    )}
                    {dataDetail.processId === 3 &&
                      renderRowColumnsA(
                        renderCaption('Reason for rejection'),
                        renderRejectReason(dataDetail?.rejectReason)
                      )}
                  </tbody>
                </table>
                <Grid item xs={1} md={1}></Grid>
                <table width="35%" style={{height: 'fit-content'}}>
                  <tbody>
                    {renderRowColumnsB(
                      renderCaption('Expiry date'),
                      renderSubtitle(
                        formatDate(dataDetail?.expiryDateTime, formatDateTime)
                      )
                    )}
                    {renderRowColumnsB(
                      renderCaption('Island Partner'),
                      renderSubtitle(dataDetail?.islandPartnerName)
                    )}
                    {renderRowColumnsB(
                      renderCaption('Island Partner ID'),
                      renderSubtitle(dataDetail?.islandPartnerId)
                    )}
                    {renderRowColumnsB(
                      renderCaption('Authorised by'),
                      renderSubtitle(dataDetail?.employeeName)
                    )}
                    {renderRowColumnsB(
                      renderCaption('Voucher(s) Used'),
                      renderSubtitle(vouchersUsedTitle)
                    )}
                    {renderRowColumnsB(
                      renderCaption('Points multiplier event ID'),
                      renderSubtitle(dataDetail?.pointMultiplierEventCreationId)
                    )}
                    {renderRowColumnsB(
                      renderCaption('Associated Transaction No'),
                      renderSubtitle(dataDetail?.workflowId)
                    )}
                    {dataDetail.processId === 3 &&
                      renderRowColumnsB(
                        renderCaption('Details for rejection'),
                        renderRejectedFields(dataDetail?.displayFields)
                      )}
                  </tbody>
                </table>
              </Grid>
            </StyledCardContent>
          </Card>
        </Grid>
        <Divider my={6} />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card px={6} pt={3}>
            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Stack direction="row" spacing={4}>
                  {dataDetail?.processId === 2 && !!canUpdate ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        type="button"
                        onClick={() => {
                          toggleModalApproval();
                          setTypeModal('approve');
                        }}
                        width="125px"
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        type="button"
                        onClick={() => {
                          toggleModalApproval();
                          setTypeModal('declined');
                        }}
                        width="125px"
                      >
                        Decline
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => {
                          navigate(-1);
                        }}
                        width="125px"
                      >
                        Done
                      </Button>
                      {(+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.operation === 2 &&
                        dataDetail?.islandPartnerName === null) ||
                      (+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.ticketId !== null &&
                        dataDetail?.islandPartnerName !== null) ||
                      (+envRole === USER_TYPE.ADMINISTRATOR &&
                        ValidateUsedOrRemovedVoucher(dataDetail)) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.VOUCHER}/${dataDetail?.ticketId}`
                            );
                          }}
                          width="185px"
                        >
                          {`Go to Voucher ID ${dataDetail?.ticketNumberId}`}
                        </Button>
                      ) : null}
                      {(+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.operation === 2 &&
                        dataDetail?.islandPartnerName === null &&
                        dataDetail?.secondTicketId !== null) ||
                      (+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.secondTicketId !== null &&
                        dataDetail?.islandPartnerName !== null) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.VOUCHER}/${dataDetail?.secondTicketId}`
                            );
                          }}
                          width="185px"
                        >
                          {`Go to Voucher ID ${dataDetail?.secondTicketNumberId}`}
                        </Button>
                      ) : null}

                      {dataDetail?.pointMultiplierEventCreationId ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.LIMITED_EVENT}?_page=0&_size=10&status_eq=1&limitedEventId_eq=${dataDetail?.pointMultiplierEventCreationId}`
                            );
                          }}
                          width="165px"
                        >
                          Go to Limited Event
                        </Button>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AllTransactionDetail;
