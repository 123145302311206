/* eslint-disable node/no-extraneous-import */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment-timezone';

export const IsNullOrEmptyOrUndefined = (value: any): boolean => {
  // Check for null or undefined
  if (value === null || value === undefined) {
    return true;
  }

  // Check for empty strings
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  // Check for empty arrays
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // Check for empty objects
  if (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  ) {
    return true;
  }

  // Check for NaN
  if (typeof value === 'number' && isNaN(value)) {
    return true;
  }

  // If none of the conditions match, the value is not null, empty, or undefined
  return false;
};

export const GetCurrentSingaporeTimeZone = (): Date => {
  return new Date(moment.tz('Asia/Singapore').format('YYYY-MM-DD HH:mm:ss'));
};

export const stringFormat = (template: string, ...values) => {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof values[number] !== 'undefined' ? values[number] : match;
  });
};
