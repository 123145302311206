import React, {useState, useEffect} from 'react';
import {Checkbox, FormControl, FormControlLabel, Grid} from '@mui/material';
import {
  DateTimePicker,
  Input,
  RadioGroups,
  Select,
} from '../../../../../components';
import {Typography} from './DeclineReceiptForm.style';
import IslanderPartnerService from '../../../../../services/Islander/islander.service';
import {DataDetail} from '../../../../../types/typeAllTransaction';
import OcrHistoryService from '../../../../../services/OCRHistory/ocrHistory.service';
import {
  envRole,
  formatDate,
  formatDateTime,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
  formatLocalDateFromUTCTime,
} from '../../../../../utils';
import {
  OCRRejectReasons,
  OCRRejectReasonsLabel,
} from '../../../../../constants/receiptValidationLogsStatus';

interface InitialValues {
  rejectReason?: string | undefined;
}

interface DeclineReceiptFormProps {
  transactionDetail?: DataDetail;
  setInitialValues: (params: any) => void;
  initialValues: InitialValues;
  setRejectedFields: (params: any) => void;
  rejectedFields: string[];
}

const DeclineReceiptForm = ({
  transactionDetail,
  initialValues,
  setInitialValues,
  rejectedFields,
  setRejectedFields,
}: DeclineReceiptFormProps) => {
  const [rejectReceiptOptions, setRejectReceiptOptions] = useState<any>([]);
  const [rejectFieldSelected, setRejectFieldSelected] = useState({
    receiptId: false,
    receiptDateOfPurchase: false,
    receiptTotal: false,
    matchedMerchantID: false,
  });
  const handleChangeValue = (value, key) => {
    setInitialValues({...initialValues, [key]: value});
  };

  const getRejectReasonOptions = () => {
    const rejectReceiptOptions = Object.keys(OCRRejectReasons).reduce(
      (items, value, index) => {
        items.push({value: index + 1, label: OCRRejectReasonsLabel[index]});
        return items;
      },
      []
    );

    setRejectReceiptOptions(rejectReceiptOptions);
  };

  const onSelectRejectedFields = (key: string, value: boolean) => {
    if (value === true) {
      setRejectedFields([...rejectedFields, key]);
    } else {
      setRejectedFields(rejectedFields.filter(f => f !== key));
    }
  };

  const onSetRejectedFieldSelected = (key: string, value: boolean) => {
    setRejectFieldSelected({...rejectFieldSelected, [key]: value});
  };

  useEffect(() => {
    getRejectReasonOptions();
  }, []);

  return (
    <Grid container>
      <Grid item sm={12}>
        <FormControl fullWidth sx={{marginBottom: '16px'}}>
          <Typography variant="subtitle2" fontWeight={'bolder'}>
            Please select the reason for rejection{' '}
          </Typography>
          <Select
            labelId="rejectReason"
            displayEmpty
            variant="outlined"
            onChangeSelect={e =>
              handleChangeValue(e.target.value, 'rejectReason')
            }
            value={initialValues.rejectReason}
            placeholder="Select reason"
            size="small"
            selectOptions={rejectReceiptOptions}
          />
        </FormControl>
        <FormControl fullWidth sx={{marginBottom: '16px'}}>
          <Typography variant="subtitle2" fontWeight={'bolder'}>
            Select the field(s) that you believe contribute to determining the
            reason for rejection (Optional)
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={rejectFieldSelected.receiptId}
                checked={rejectFieldSelected.receiptId}
                onChange={(e, value) => {
                  onSetRejectedFieldSelected('receiptId', value);
                  onSelectRejectedFields('ReceiptId', value);
                }}
                inputProps={{'aria-label': 'controlled'}}
              />
            }
            label="Receipt/Order number"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rejectFieldSelected.receiptDateOfPurchase}
                checked={rejectFieldSelected.receiptDateOfPurchase}
                onChange={(e, value) => {
                  onSetRejectedFieldSelected('receiptDateOfPurchase', value);
                  onSelectRejectedFields('ReceiptDateOfPurchase', value);
                }}
                inputProps={{'aria-label': 'controlled'}}
              />
            }
            label="Receipt date/time"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rejectFieldSelected.receiptTotal}
                checked={rejectFieldSelected.receiptTotal}
                onChange={(e, value) => {
                  onSetRejectedFieldSelected('receiptTotal', value);
                  onSelectRejectedFields('ReceiptTotal', value);
                }}
                inputProps={{'aria-label': 'controlled'}}
              />
            }
            label="Total amount"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rejectFieldSelected.matchedMerchantID}
                checked={rejectFieldSelected.matchedMerchantID}
                onChange={(e, value) => {
                  onSelectRejectedFields('MatchedMerchantID', value);
                  onSetRejectedFieldSelected('matchedMerchantID', value);
                }}
                inputProps={{'aria-label': 'controlled'}}
              />
            }
            label="Islander Partner Name/Address/Operating Area"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DeclineReceiptForm;
