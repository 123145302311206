/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {Box, CardContent, FormControl, Grid} from '@mui/material';
import useAuth from '../../../hooks/useAuth';

import {
  Breadcrumbs,
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
  AspectSelectImage,
  DateTimePicker,
} from '../../../components';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './MemberDashboardCreateNew.style';
import {typeRoutes} from '../../../types/typeAllTransaction';
import MemberDashBoardService from '../../../services/MemberDashboard/member-dashboard.service';
import MobileAssetsService from '../../../services/MobileAssets/mobile-assets.service';
import SimpleSelectFilter from '../../../components/Selects/SimpleSelectFilter/SimpleSelectFilter';
import CacheService from '../../../services/Cache/cache.service';
import {
  cTALinkTypeOptions,
  radioOptions,
} from '../../../constants/memberDashboardContanst';
import {formatDateTime} from '../../../utils';

interface DataDetail {
  title?: string;
  description?: string;
  gradientColor?: number;
  mobileAssetId?: string;
  appCTALink?: string;
  webCTALink?: string;
  displayOrder?: number | undefined;
  cTALinkType?: number | undefined;
  cTALink?: string;
  image?: string;
  startDateTime?: string;
  endDateTime?: string;
}

function MemberDashboardCreateNew() {
  const formatDateTimes = 'dd/MM/yyyy hh:mm';
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);
  const sizeLimit = 1000;
  const [memberDashboardAssetImages, setMemberDashboardAssetImages] = useState(
    []
  );
  const [radioOptionsAppCTALink, setRadioOptionsAppCTALink] = useState<any[]>(
    []
  );
  const [valueRangerPicker, setValueRangerPicker] = useState<any>({
    startDateTime: null,
    endDateTime: null,
  });

  const [helperTextImage, setHelperTextImage] =
    useState<string>('Upload an image');
  const [showErrorSizeLimite, setShowErrorSizeLimit] = useState(false);
  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    gradientColor: 1,
    mobileAssetId: '',
    appCTALink: '',
    webCTALink: '',
    cTALinkType: 0,
    cTALink: '',
    image: '',
    startDateTime: '',
    endDateTime: '',
  });

  const parseCTALink = (values: any) => {
    if (values?.appCTALink !== '') {
      return radioOptionsAppCTALink.find(
        item => item.value === values?.appCTALink
      )?.url;
    }
    if (values?.webCTALink !== '') {
      return values?.webCTALink;
    }
  };

  const validateForm = () => {
    let errors = 0;

    if (memberDashboardAssetImages.length === 0) {
      errors = errors + 1;
    }

    return errors === 0;
  };

  const onSubmit = values => {
    setShowErrorSizeLimit(false);
    if (!validateForm()) {
      return false;
    }
    setLoading(true);
    new MemberDashBoardService()
      .CreateNewMemberDashboardAsset({
        ...values,
        gradientColor: Number(initialValues?.gradientColor),
        cTALink: parseCTALink(values),
        appCTALink: undefined,
        webCTALink: undefined,
        image: undefined,
        cTALinkType:
          values?.cTALinkType !== 0 ? values?.cTALinkType : undefined,
      })
      .then(res => {
        if (res !== null && res.success) {
          setInitialValues({
            title: '',
            description: '',
            gradientColor: 1,
            mobileAssetId: '',
            appCTALink: '',
            webCTALink: '',
            cTALinkType: 0,
            image: '',
            startDateTime: '',
            endDateTime: '',
          });
          new MemberDashBoardService()
            .uploadImage(
              res.data.id,
              new File(
                memberDashboardAssetImages,
                'mastheadDashboardAssetImages.jpeg'
              )
            )
            .then(resImg => {
              if (resImg) {
                showNotification({
                  message: 'Masthead created successfully!',
                });
                setMemberDashboardAssetImages([]);
                setValueRangerPicker({startDateTime: null, endDateTime: null});
              }
              setLoading(false);
            });
        } else {
          setLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const renderDescCTALink = (value: number) => {
    switch (value) {
      case cTALinkTypeOptions[1].value:
        return 'Select Redirection to 1 of the App page from Masthead';
      case cTALinkTypeOptions[2].value:
        return 'Insert link for Redirection to any Web page from Masthead';
      default:
        return '';
    }
  };

  const GetAppCTALinks = () => {
    new CacheService().get('GetItemFromCache?key=AppCTALinks', {}).then(res => {
      if (res?.data?.data !== null && res?.data?.data !== '') {
        const appCTALinkOptions = JSON.parse(res?.data?.data);
        appCTALinkOptions.forEach(item => {
          item.label = item.label.replace(/_/g, ' ');
        });
        setRadioOptionsAppCTALink(appCTALinkOptions);
      }
    });
  };

  const onUploadImages = images => {
    setMemberDashboardAssetImages(images);
  };

  useEffect(() => {
    if (showErrorSizeLimite === true) {
      setHelperTextImage(
        `File is too big, please upload an image of a maximum file size 1MB`
      );
    }

    if (
      memberDashboardAssetImages.length === 0 &&
      showErrorSizeLimite !== true
    ) {
      setHelperTextImage(`Upload an image`);
    }
  }, [showErrorSizeLimite, memberDashboardAssetImages]);

  useEffect(() => {
    GetAppCTALinks();
  }, []);
  return (
    <>
      <Helmet title="Member Dashboard" />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Member Dashboard
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Create Masthead Content for Logged In Users',
                undefined,
                true
              ),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Grid container mb="30px">
          <Grid item xs={12} lg={12}>
            <Shadow>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={values => {
                  return Yup.object().shape({
                    title: Yup.string()
                      .required('Fill in the title')
                      .max(42, 'Please input only maximum of 42 characters'),
                    description: Yup.string()
                      .required('Fill in the description')
                      .max(150, 'Please input only maximum of 150 characters'),
                    gradientColor: Yup.number().integer(),
                    appCTALink:
                      initialValues?.cTALinkType === cTALinkTypeOptions[1].value
                        ? Yup.number().required('Select 1 App page')
                        : Yup.number(),
                    webCTALink:
                      initialValues?.cTALinkType === cTALinkTypeOptions[2].value
                        ? Yup.string().required('Enter link')
                        : Yup.string(),
                    startDateTime: Yup.date()
                      .typeError('Please input a valid start date and time')
                      .required('Select the start date and time'),
                    endDateTime: Yup.date()
                      .typeError('Please input a valid end date and time')
                      .required('Select the end date and time'),
                  });
                }}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                  resetForm,
                  isSubmitting,
                  touched,
                  values,
                }) => {
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <Card py={12} px={12}>
                        <CardContent sx={{padding: 0}}>
                          <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={10}
                            mb="50px"
                          >
                            <Grid item xs={12}>
                              <FormControl fullWidth margin="normal">
                                <Typography variant="subtitle2" mb={2}>
                                  Title{' '}
                                  <span className="mandatory-label">(*)</span>
                                  <Typography
                                    variant="body2"
                                    fontStyle="italic"
                                  >
                                    Maximum 42 characters including spaces
                                  </Typography>
                                </Typography>
                                <Input
                                  placeholder="Enter title"
                                  size="small"
                                  name="title"
                                  value={values.title}
                                  variant="outlined"
                                  helperText={touched.title && errors.title}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.title && errors.title)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth margin="normal">
                                <Typography variant="subtitle2" mb={2}>
                                  Description{' '}
                                  <span className="mandatory-label">(*)</span>
                                  <Typography
                                    variant="body2"
                                    fontStyle="italic"
                                  >
                                    Maximum 150 characters including spaces
                                  </Typography>
                                </Typography>
                                <TextArea
                                  label=""
                                  placeholder="Enter description"
                                  maxRows={2}
                                  name="description"
                                  value={values.description}
                                  helperText={
                                    touched.description && errors.description
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.description && errors.description
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={12}>
                              <FormControl variant="standard" fullWidth>
                                <Typography variant="subtitle2" mb={2} mt={3}>
                                  Background Gradient Colour
                                </Typography>
                                <RadioGroups
                                  name="gradientColor"
                                  options={radioOptions}
                                  values={initialValues.gradientColor}
                                  onChange={e => {
                                    handleChange(e);
                                    const newInitialValues = initialValues;
                                    newInitialValues.gradientColor =
                                      e.target.value;
                                    setInitialValues(newInitialValues);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl sx={{marginBottom: '20px'}}>
                                <Typography variant="subtitle2" mb={2}>
                                  Masthead Asset{' '}
                                  <span className="mandatory-label">(*)</span>{' '}
                                  <Typography
                                    variant="body2"
                                    fontStyle="italic"
                                  >
                                    Image must be a minimum of 375 x 600 pixels
                                    in standard resolution or a maximum of 750 x
                                    1200 pixels in high resolution, in portrait
                                    orientation, with a maximum file size of 1MB
                                  </Typography>
                                </Typography>
                                <AspectSelectImage
                                  minWidth={375}
                                  minHeight={600}
                                  maxWidth={750}
                                  maxHeight={1200}
                                  sizeLimit={sizeLimit}
                                  setShowErrorSizeLimit={setShowErrorSizeLimit}
                                  styleErrorMessage={{
                                    width: '100%',
                                    margin: '4px 14px 0px 14px',
                                    paddingLeft: '12px',
                                    display: 'block',
                                  }}
                                  isUploadImage={true}
                                  helperText={helperTextImage}
                                  showError={
                                    ((values?.image === null ||
                                      values?.image === '') &&
                                      touched.image) ||
                                    showErrorSizeLimite
                                  }
                                  defaultImages={memberDashboardAssetImages}
                                  setDefaultImages={images => {
                                    onUploadImages(images);
                                    setFieldValue('image', images[0]);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl
                                fullWidth
                                sx={{marginBottom: '20px'}}
                              >
                                <Typography variant="subtitle2" mb={2}>
                                  CTA Link Type
                                </Typography>
                                <RadioGroups
                                  name="cTALinkType"
                                  options={cTALinkTypeOptions}
                                  values={initialValues.cTALinkType}
                                  onChange={e => {
                                    setFieldValue(
                                      'cTALinkType',
                                      Number(e.target.value)
                                    );
                                    setFieldValue('webCTALink', '');
                                    setFieldValue('appCTALink', '');
                                    const newInitialValues = initialValues;
                                    newInitialValues.cTALinkType = Number(
                                      e.target.value
                                    );
                                    newInitialValues.appCTALink = '';
                                    newInitialValues.webCTALink = '';
                                    setInitialValues(newInitialValues);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            {values?.cTALinkType ===
                              cTALinkTypeOptions[1].value && (
                              <Grid item xs={12}>
                                <FormControl
                                  fullWidth
                                  sx={{marginBottom: '20px'}}
                                >
                                  <Typography variant="subtitle2" mb={2}>
                                    App CTA Link{' '}
                                    <Typography
                                      variant="body2"
                                      sx={{display: 'inline-block'}}
                                    >
                                      [
                                      {renderDescCTALink(
                                        Number(values?.cTALinkType)
                                      )}
                                      ]
                                    </Typography>{' '}
                                    <span className="mandatory-label">(*)</span>
                                  </Typography>
                                  <Select
                                    labelId="appCTALink"
                                    name="appCTALink"
                                    size="small"
                                    variant="outlined"
                                    displayEmpty
                                    placeholder="Select link"
                                    onChangeSelect={e => {
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    helperText={
                                      touched.appCTALink && errors.appCTALink
                                    }
                                    error={Boolean(
                                      touched.appCTALink && errors.appCTALink
                                    )}
                                    value={values.appCTALink}
                                    selectOptions={radioOptionsAppCTALink}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                            {values?.cTALinkType ===
                              cTALinkTypeOptions[2].value && (
                              <Grid item xs={12}>
                                <FormControl
                                  fullWidth
                                  sx={{marginBottom: '20px'}}
                                >
                                  <Typography variant="subtitle2" mb={2}>
                                    Web CTA Link{' '}
                                    <Typography
                                      variant="body2"
                                      sx={{display: 'inline-block'}}
                                    >
                                      [
                                      {renderDescCTALink(
                                        Number(values?.cTALinkType)
                                      )}
                                      ]
                                    </Typography>{' '}
                                    <span className="mandatory-label">(*)</span>
                                  </Typography>
                                  <Input
                                    placeholder="Input Link"
                                    size="small"
                                    name="webCTALink"
                                    value={values.webCTALink}
                                    variant="outlined"
                                    helperText={
                                      touched.webCTALink && errors.webCTALink
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={Boolean(
                                      touched.webCTALink && errors.webCTALink
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item xs={8}>
                              <Typography variant="subtitle2" mb={2}>
                                Schedule Masthead Display{' '}
                                <span className="mandatory-label">(*)</span>{' '}
                              </Typography>
                              <Grid
                                container
                                justifyContent={'space-between'}
                                wrap="nowrap"
                                gap={16}
                              >
                                <Grid item xs={6}>
                                  <FormControl fullWidth margin="dense">
                                    <Typography variant="body2" mb={2}>
                                      Start Date Time
                                    </Typography>
                                    <DateTimePicker
                                      isEmptyValue={
                                        (values.startDateTime === null ||
                                          values.startDateTime === '') &&
                                        touched.endDateTime
                                      }
                                      isErrorMessage={Boolean(
                                        touched.startDateTime &&
                                          errors.startDateTime
                                      )}
                                      value={valueRangerPicker.startDateTime}
                                      helperText={errors?.startDateTime}
                                      onChange={e => {
                                        if (
                                          e !== null &&
                                          e >= new Date(values?.endDateTime)
                                        ) {
                                          setValueRangerPicker({
                                            ...valueRangerPicker,
                                            startDateTime: e,
                                            endDateTime: null,
                                          });

                                          setFieldValue(
                                            'startDateTime',
                                            formatDateTime(e, formatDateTimes)
                                          );
                                          setFieldValue('endDateTime', '');
                                        } else {
                                          setValueRangerPicker({
                                            ...valueRangerPicker,
                                            startDateTime: e,
                                          });

                                          setFieldValue(
                                            'startDateTime',
                                            formatDateTime(e, formatDateTimes)
                                          );
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth margin="dense">
                                    <Typography variant="body2" mb={2}>
                                      End Date Time
                                    </Typography>
                                    <DateTimePicker
                                      isEmptyValue={
                                        (values.endDateTime === null ||
                                          values.endDateTime === '') &&
                                        touched.endDateTime
                                      }
                                      value={valueRangerPicker.endDateTime}
                                      isErrorMessage={Boolean(
                                        touched.endDateTime &&
                                          errors.endDateTime
                                      )}
                                      disabled={
                                        values.startDateTime === null ||
                                        values.startDateTime === ''
                                      }
                                      helperText={errors?.endDateTime}
                                      minDate={
                                        values?.startDateTime ||
                                        formatDateTime(
                                          new Date(),
                                          formatDateTimes
                                        )
                                      }
                                      onChange={e => {
                                        setValueRangerPicker({
                                          ...valueRangerPicker,
                                          endDateTime: e,
                                        });
                                        setFieldValue(
                                          'endDateTime',
                                          formatDateTime(e, formatDateTimes)
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Grid
                          sx={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                          <Button type="submit" loading={loading}>
                            Create Masthead
                          </Button>
                        </Grid>
                      </Card>
                    </form>
                  );
                }}
              </Formik>
            </Shadow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MemberDashboardCreateNew;
