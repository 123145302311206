/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {DateRange} from '@mui/lab/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  TRANSACTION_FILTER_TYPE_LIST,
  TRANSACTION_TYPES,
} from '../../constants/transaction';
import {ADD, BONUS} from '../../constants/operation';
import {ColorType} from '../../types/typeChip';
import {
  Breadcrumbs,
  Button,
  Chip,
  Table,
  Tooltips,
  ModalZoomImage,
} from '../../components';
import {path} from '../../routes/path';
import {typeRoutes} from '../../types/typeAllTransaction';
import {ColumnsType} from '../../types/typeTable';
import ModalFilter from './components/ModalFilter/ModalFilter';
import AllTransactionService from '../../services/AllTransaction/all-transaction.service';
import IslandPartnerService from '../../services/Islander/islander.service';
import {
  envRole,
  formatDate,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
  formatLocalDateFromUTCTime,
} from '../../utils';
import {
  POINT_SUCCESS_COLOR,
  POINT_SUCCESS_LABEL,
  POINT_SUCCESS,
} from '../../constants/status';
import useAuth from '../../hooks/useAuth';
import {ROLE_SDC_ADMIN, USER_TYPE} from '../../constants/UserType';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import queryString from 'query-string';
import success from '../../constants/success';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledStack,
  StyledAction,
  StyledIcon,
  StyledColorPoints,
  ReceiptImage,
} from './AllTransaction.style';
import {NavLink} from 'react-router-dom';

import {
  GetCurrentSingaporeTimeZone,
  IsNullOrEmptyOrUndefined,
  stringFormat,
} from '../../utils/helpers';
import {ModalInformations as ModalInformationsProps} from '../../types/typeModals';
import ModalInformations from '../../components/Modal/ModalInformations/ModalInformations';

function AllTransaction() {
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const membershipId = locationUrl?.state?.membershipId;
  const merchantID = locationUrl?.state?.merchantId;
  const [isModalZoomImage, setIsModalZoomImage] = useState(false);
  const [imageZoom, setImageZoom] = useState('');
  const pointsStatusModalInfomations: ModalInformationsProps = {
    title: 'Points status',
    description: 'Based on date of transaction',
  };
  const format = 'DD/MM/YYYY';
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const {getUser, showNotification} = useAuth();
  const user = getUser() || {};
  const {merchantId, email} = user;
  const userType = +envRole;
  const isAdministrator = +envRole === USER_TYPE.ADMINISTRATOR;

  const role = isAdministrator ? 'administrator' : 'merchant';
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [optionByStatus, setOptionByStatus] = useState([]);
  const [nameContainsList, setNameContainsList] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    description: '',
  });

  const searchParamsObject = queryString.parse(searchParams.toString());

  const [valueFilter, setValueFilter] = useState({
    merchantId_eq: (merchantID || searchParams.get('merchantId_eq')) ?? '',
    processId_eq: searchParams.get('processId_eq') ?? '',
    createdDateTime_lte: searchParams.get('createdDateTime_lte') ?? '',
    createdDateTime_gte: searchParams.get('createdDateTime_gte') ?? '',
    pointMultiplierEventCreationId_eq:
      searchParams.get('pointMultiplierEventCreationId_eq') ?? '',
    accountId_eq: searchParams.get('accountId_eq') ?? '',
    id_eq: searchParams.get('id_eq') ?? '',
    pointMultiplierEventTitle_contains:
      searchParams.get('pointMultiplierEventTitle_contains') ?? '',
    transactionFilterType_by:
      searchParamsObject?.transactionFilterType_by !== undefined
        ? searchParamsObject?.transactionFilterType_by
            .split(',')
            .reduce((transactions, key) => {
              transactions.push({
                title: TRANSACTION_FILTER_TYPE_LIST[key],
                value: key,
              });
              return transactions;
            }, [])
        : undefined,
    pointStatusEnum_eq: searchParams.get('pointStatusEnum_eq') ?? '',
    operation_ne: searchParams.get('operation_ne') ?? '',
  });
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [params, setParams] = useState({
    ...{
      _page: 0,
      _size: 10,
      merchantId_eq: merchantID || undefined,
      operation_ne: searchParamsObject?.operation_ne || undefined,
      membershipId_eq: membershipId || undefined,
      pointMultiplierEventCreationId_eq:
        searchParamsObject?.pointMultiplierEventCreationId_eq || undefined,
      pointMultiplierEventTitle_contains:
        searchParamsObject?.pointMultiplierEventTitle_contains || undefined,
      transactionFilterType_by:
        searchParamsObject?.transactionFilterType_by !== undefined
          ? searchParamsObject?.transactionFilterType_by
          : undefined,
      pointStatusEnum_eq: searchParamsObject?.pointStatusEnum_eq || undefined,
    },
    ...{
      merchantId_eq: searchParamsObject.merchantId_eq ?? undefined,
      processId_eq: searchParamsObject.processId_eq ?? undefined,
      createdDateTime_lte: searchParamsObject.createdDateTime_lte ?? undefined,
      createdDateTime_gte: searchParamsObject.createdDateTime_gte ?? undefined,
      accountId_eq: searchParamsObject.accountId_eq ?? undefined,
      id_eq: searchParamsObject.id_eq ?? undefined,
    },
    ...(role === 'merchant' && merchantId
      ? {
          merchantId_eq: merchantId,
        }
      : {}),
    ...(role !== 'merchant' && merchantID
      ? {
          merchantId_eq: merchantID,
        }
      : {}),
  });

  const columns: Array<ColumnsType> = isAdministrator
    ? [
        {
          dataIndex: 'transactionNo',
          numeric: false,
          disablePadding: false,
          label: 'Transaction no.',
        },
        {
          dataIndex: 'createdDate',
          numeric: false,
          disablePadding: false,
          label: 'Transaction date and time',
        },
        {
          dataIndex: 'membershipId',
          numeric: false,
          disablePadding: false,
          label: 'Account ID',
        },
        {
          dataIndex: 'transactional_type',
          numeric: false,
          disablePadding: false,
          label: 'Transaction type',
        },
        {
          dataIndex: 'amount',
          numeric: false,
          disablePadding: false,
          label: 'Amount spent',
        },
        {
          dataIndex: 'receiptImageUrl',
          numeric: false,
          disablePadding: false,
          label: 'Receipt image',
        },
        {
          dataIndex: 'receiptConfidence',
          numeric: false,
          disablePadding: false,
          label: 'System assurance level',
        },
        {
          dataIndex: 'limitedEventId',
          numeric: false,
          disablePadding: false,
          label: 'Points multiplier event ID',
        },
        {
          dataIndex: 'limitedEventTitle',
          numeric: false,
          disablePadding: false,
          label: 'Points multiplier event name',
        },
        {
          dataIndex: 'points',
          numeric: false,
          disablePadding: false,
          label: 'Points',
        },
        {
          dataIndex: 'expiryDate',
          numeric: false,
          disablePadding: false,
          label: 'Points expiry date',
        },
        {
          dataIndex: 'status',
          numeric: false,
          disablePadding: false,
          label: 'Transaction status',
        },
        {
          dataIndex: 'duplicatedTransactionIds',
          numeric: false,
          disablePadding: false,
          label: 'Similar to ≥$20 transaction ID(s)',
        },
        {
          dataIndex: 'duplicatedReceiptIds',
          numeric: false,
          disablePadding: false,
          label: 'Similar to <$20 transaction ID(s)',
        },
        {
          dataIndex: 'pointsStatus',
          numeric: false,
          disablePadding: false,
          label: 'Points status',
          modalInfo: pointsStatusModalInfomations,
        },
        {
          dataIndex: null,
          numeric: false,
          disablePadding: false,
          label: '',
        },
      ]
    : [
        {
          dataIndex: 'transactionNo',
          numeric: false,
          disablePadding: false,
          label: 'Transaction no.',
        },
        {
          dataIndex: 'createdDate',
          numeric: false,
          disablePadding: false,
          label: 'Transaction date and time',
        },
        {
          dataIndex: 'membershipId',
          numeric: false,
          disablePadding: false,
          label: 'Account ID',
        },
        {
          dataIndex: 'transactional_type',
          numeric: false,
          disablePadding: false,
          label: 'Transaction type',
        },
        {
          dataIndex: 'amount',
          numeric: false,
          disablePadding: false,
          label: 'Amount spent',
        },
        {
          dataIndex: 'receiptImageUrl',
          numeric: false,
          disablePadding: false,
          label: 'Receipt image',
        },
        {
          dataIndex: 'limitedEventId',
          numeric: false,
          disablePadding: false,
          label: 'Points multiplier event ID',
        },
        {
          dataIndex: 'limitedEventTitle',
          numeric: false,
          disablePadding: false,
          label: 'Points multiplier event name',
        },
        {
          dataIndex: 'points',
          numeric: false,
          disablePadding: false,
          label: 'Points',
        },
        {
          dataIndex: 'expiryDate',
          numeric: false,
          disablePadding: false,
          label: 'Points expiry date',
        },
        {
          dataIndex: 'status',
          numeric: false,
          disablePadding: false,
          label: 'Transaction status',
        },
        {
          dataIndex: null,
          numeric: false,
          disablePadding: false,
          label: '',
        },
      ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onClickIconButton = idTransaction => {
    if (membershipId) {
      return navigate(
        `/${path.ALL_TRANSACTION}/${idTransaction}?membershipId=${membershipId}`,
        {
          state: {
            membershipId: membershipId,
            filterParams: {
              ...queryString.parse(searchParams.toString()),
            },
          },
        }
      );
    }
    if (merchantID) {
      return navigate(
        `/${path.ALL_TRANSACTION}/${idTransaction}?merchantId=${merchantID}`,
        {
          state: {
            merchantId: merchantID,
            filterParams: {
              ...queryString.parse(searchParams.toString()),
            },
          },
        }
      );
    }
    return navigate(`/${path.ALL_TRANSACTION}/${idTransaction}`, {
      state: {
        membershipId: null,
        merchantId: null,
        filterParams: {
          ...queryString.parse(searchParams.toString()),
        },
      },
    });
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  onClickIconButton(value);
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                onClickIconButton(value);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (!IsNullOrEmptyOrUndefined(value)) {
      const renderColor = POINT_SUCCESS_COLOR[value.processId];
      const renderLabel = POINT_SUCCESS_LABEL[value.processId];
      return <Chip label={renderLabel} color={renderColor as ColorType} />;
    }
  };

  const renderReceiptImage = image => {
    if (image === null) {
      return '--';
    }
    return (
      <ReceiptImage
        src={image}
        onClick={() => {
          toggleIsModalZoomImage();
          setImageZoom(image);
        }}
      />
    );
  };

  const renderSystemAssuranceLevel = (values: any) => {
    const percent =
      values?.receiptConfidence !== null
        ? values.receiptConfidence * 100
        : null;
    let color = '#6E6E6E';
    if (percent === null) {
      return (
        <Typography color={'GrayText'} variant="caption" fontWeight="bolder">
          --
        </Typography>
      );
    }

    if (percent < 80) {
      color = '#E66A25';
    } else if (percent >= 80) {
      color = '#2E7D32';
    }
    return (
      <Typography variant="h6" sx={{color: color}}>{`${percent}%`}</Typography>
    );
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'date') {
      deleteSearchParams('createdDateTime_lte');
      deleteSearchParams('createdDateTime_gte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        createdDateTime_lte: '',
        createdDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDateTime_lte: undefined,
        createdDateTime_gte: undefined,
      }));
    }

    if (type === 'merchantId_eq') {
      deleteSearchParams('merchantId_eq');
      deleteSearchParams('merchantId_label');
      setValueFilter({
        ...valueFilter,
        merchantId_eq: '',
        operation_ne: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        operation_ne: undefined,
        merchantId_eq: undefined,
        ...(role === 'merchant' && merchantId
          ? {
              merchantId_eq: merchantId,
              operation_ne: '2',
            }
          : {}),
      }));
    }

    if (type === 'pointMultiplierEventTitle_contains') {
      deleteSearchParams('pointMultiplierEventTitle_contains');
      setValueFilter({
        ...valueFilter,
        pointMultiplierEventTitle_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointMultiplierEventTitle_contains: undefined,
      }));
    }

    if (type === 'pointMultiplierEventCreationId_eq') {
      deleteSearchParams('pointMultiplierEventCreationId_eq');
      deleteSearchParams('pointMultiplierEventCreationId_label');
      setValueFilter({
        ...valueFilter,
        pointMultiplierEventCreationId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointMultiplierEventCreationId_eq: undefined,
      }));
    }
    if (type === 'processId_eq') {
      deleteSearchParams('processId_eq');
      deleteSearchParams('processId_label');
      setValueFilter({
        ...valueFilter,
        processId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        processId_eq: undefined,
      }));
    }

    if (type === 'accountId_eq') {
      deleteSearchParams('accountId_eq');
      setValueFilter({
        ...valueFilter,
        accountId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        accountId_eq: undefined,
      }));
    }

    if (type === 'id_eq') {
      deleteSearchParams('id_eq');
      setValueFilter({
        ...valueFilter,
        id_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        id_eq: undefined,
      }));
    }

    if (type === 'pointStatusEnum_eq') {
      deleteSearchParams('pointStatusEnum_eq');
      setValueFilter({
        ...valueFilter,
        pointStatusEnum_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointStatusEnum_eq: undefined,
      }));
    }
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const toggleIsModalZoomImage = () => {
    setIsModalZoomImage(!isModalZoomImage);
  };

  const renderProcessLabelFilter = () => {
    const object = optionByStatus.find(
      item => item.value === valueFilter?.processId_eq
    );
    if (object === undefined || object === null) {
      return searchParams.get('processId_label') ?? undefined;
    }
    return object?.label;
  };

  const renderMerchantLabel = () => {
    if (
      nameContainsList.length === 0 &&
      searchParams.get('merchantId_label') !== null
    ) {
      return searchParams.get('merchantId_label');
    } else {
      return valueFilter?.merchantId_eq !== ''
        ? nameContainsList?.find(
            item => item.value === valueFilter?.merchantId_eq
          )?.label
        : undefined;
    }
  };

  const renderFieldPoints = value => {
    if (value) {
      if (value.pointValue > 0) {
        return (
          <StyledColorPoints className={`points points--${value.operation}`}>
            {value.operation === ADD || value.operation === BONUS
              ? `+ ${value.pointValue}`
              : `- ${value.pointValue}`}
          </StyledColorPoints>
        );
      }
      return <StyledColorPoints>{value.pointValue}</StyledColorPoints>;
    }
  };

  const handleClean = () => {
    setValueRangePicker([null, null]);
    setValueFilter({
      accountId_eq: '',
      id_eq: '',
      processId_eq: '',
      transactionFilterType_by: undefined,
      merchantId_eq: '',
      createdDateTime_lte: '',
      createdDateTime_gte: '',
      pointMultiplierEventCreationId_eq: '',
      pointMultiplierEventTitle_contains: '',
      pointStatusEnum_eq: '',
      operation_ne: '',
    });
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: 10,
      createdDateTime_lte: undefined,
      createdDateTime_gte: undefined,
      merchantId_eq: undefined,
      pointMultiplierEventCreationId_eq: undefined,
      pointMultiplierEventTitle_contains: undefined,
      accountId_eq: undefined,
      id_eq: undefined,
      processId_eq: undefined,
      pointStatusEnum_eq: undefined,
      operation_ne: undefined,
    }));
    deleteSearchParams('accountId_eq');
    deleteSearchParams('id_eq');
    deleteSearchParams('processId_eq');
    deleteSearchParams('createdDateTime_lte');
    deleteSearchParams('createdDateTime_gte');
    deleteSearchParams('pointMultiplierEventCreationId_eq');
    deleteSearchParams('pointMultiplierEventTitle_contains');
    deleteSearchParams('pointStatusEnum_eq');
  };

  const handleFilter = () => {
    if (valueFilter?.processId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        processId_eq: valueFilter?.processId_eq,
      }));
    }

    if (valueRangePicker[0] && valueRangePicker[1]) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: getEndOfDate(valueRangePicker[1], format),
        createdDateTime_gte: getStartOfDate(valueRangePicker[0], format),
      }));
    } else if (
      valueFilter?.createdDateTime_lte !== '' &&
      valueFilter?.createdDateTime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: valueFilter?.createdDateTime_lte,
        createdDateTime_gte: valueFilter?.createdDateTime_gte,
      }));
    }

    if (valueFilter?.transactionFilterType_by) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        transactionFilterType_by: valueFilter?.transactionFilterType_by
          .map(item => item.value)
          .toString(),
      }));
    }

    if (valueFilter?.pointMultiplierEventTitle_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointMultiplierEventTitle_contains:
          valueFilter?.pointMultiplierEventTitle_contains,
      }));
    }

    if (valueFilter?.merchantId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantId_eq: valueFilter?.merchantId_eq,
        operation_ne: '2',
      }));
    }

    if (valueFilter?.pointMultiplierEventCreationId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointMultiplierEventCreationId_eq:
          valueFilter?.pointMultiplierEventCreationId_eq,
      }));
    }
    if (valueFilter?.accountId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        accountId_eq: valueFilter?.accountId_eq,
      }));
    }
    if (valueFilter?.id_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        id_eq: valueFilter?.id_eq,
      }));
    }
    if (valueFilter?.pointStatusEnum_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointStatusEnum_eq: valueFilter?.pointStatusEnum_eq,
      }));
    }

    toggleModal();
  };

  const toggleOpenModalInfomations = async (
    isOpen: boolean,
    title: string,
    desc: string
  ) => {
    setModalInfo({
      ...modalInfo,
      isOpen: isOpen,
      title: title,
      description: desc,
    });
  };

  const renderSpentAmount = dataDetail => {
    if (dataDetail?.spentAmount !== null) {
      return parseFloat(dataDetail.spentAmount).toFixed(2);
    }

    return '--';
  };

  const renderPointsStatus = (data: any) => {
    if (IsNullOrEmptyOrUndefined(data.expiryDateTime)) {
      return '--';
    }
    if (data.processId !== POINT_SUCCESS.APPROVED) {
      return '--';
    }
    let renderLabel = '';
    let renderColor = '';
    switch (data?.status) {
      case 1:
        renderLabel = 'Active';
        renderColor = 'success';
        break;
      default:
        renderLabel = 'Expired';
        renderColor = 'error';
        break;
    }
    return <Chip label={renderLabel} color={renderColor as ColorType} />;
  };

  const renderSimilarTransactionIds = dataDetail => {
    if (
      dataDetail?.duplicatedTransactionIds !== null &&
      Array.isArray(dataDetail.duplicatedTransactionIds) &&
      dataDetail.duplicatedTransactionIds.length > 0
    ) {
      return dataDetail.duplicatedTransactionIds.map((item, index) => (
        <div key={index + 1}>
          {index > 0 && <br />}
          <NavLink
            style={{textDecoration: 'none'}}
            to={`/all-transaction/${item}`}
          >
            {item}
          </NavLink>
        </div>
      ));
    }

    return '--';
  };

  const renderSimilarReceiptIds = dataDetail => {
    if (
      dataDetail?.duplicatedReceiptIds !== null &&
      Array.isArray(dataDetail.duplicatedReceiptIds) &&
      dataDetail.duplicatedReceiptIds.length > 0
    ) {
      return dataDetail.duplicatedReceiptIds.map((item, index) => (
        <div key={index + 1}>
          {index > 0 && <br />}
          <NavLink
            style={{textDecoration: 'none'}}
            to={`/all-transaction/receipt-validation-logs?id_eq=${item}`}
          >
            {item}
          </NavLink>
        </div>
      ));
    }

    return '--';
  };

  const renderTransactionType = dataDetail => {
    const transactionType = dataDetail?.transactionType ?? 0;
    let transactionTypeString = '--';
    switch (transactionType) {
      case 1:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.islandPartnerName
        );
        break;
      case 2:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.ticketNumberId,
          dataDetail?.secondTicketNumberId ?? ''
        );
        break;
      case 3:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.ticketNumberId,
          dataDetail?.secondTicketNumberId ?? ''
        );
        break;
      case 4:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.workflowId
        );
        break;
      case 5:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.ticketNumberId
        );
        break;
      case 6:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail.islandPartnerName
        );
        break;
      case 7:
        transactionTypeString = stringFormat(
          TRANSACTION_TYPES[transactionType],
          dataDetail?.workflowId
        );
        break;
      default:
        transactionTypeString = '--';
        break;
    }

    return transactionTypeString.replace(/, $/, '');
  };

  const getListAllTransaction = () => {
    setIsLoading(true);
    if (role) {
      new AllTransactionService()
        .getAll({
          ...params,
          operation_ne: !isAdministrator
            ? 2
            : params.operation_ne !== null
            ? params.operation_ne
            : undefined,
          merchantId_eq:
            !isAdministrator && merchantId !== null && merchantId !== 0
              ? merchantId
              : params.merchantId_eq !== null
              ? params.merchantId_eq
              : undefined,
        })
        .then(res => {
          if (res?.data && Array.isArray(res.data.items)) {
            setDataSource(res.data.items);
            setTotalPage(res?.data?.total);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          // Get api error => show notification or no items listing
          setError(error);
        });
    }
  };

  useEffect(() => {
    if (role === ROLE_SDC_ADMIN[USER_TYPE.ADMINISTRATOR]) {
      if (merchantID || membershipId) {
        // populate nameContainsList
        new IslandPartnerService()
          .getAll({
            id_eq: role === 'merchant' ? merchantId : undefined,
            status_eq: 1,
            _size: 2147483647,
          })
          .then(res => {
            if (res?.data?.items) {
              setNameContainsList(
                res?.data?.items?.reduce((items, item) => {
                  items.push({
                    label: item.name || '-',
                    value: item.id,
                  });
                  return items;
                }, [])
              );
            }
          });
      }
    }
  }, []);

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelAllTransaction({
        ...params,
        email: email,
        exportCSV_userType: userType,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        operation_ne: !isAdministrator
          ? 2
          : params.operation_ne !== null
          ? params.operation_ne
          : undefined,
        processId_label:
          valueFilter?.processId_eq !== ''
            ? renderProcessLabelFilter()
            : undefined,
        merchantId_eq:
          !isAdministrator && merchantId !== null && merchantId !== 0
            ? merchantId
            : params.merchantId_eq !== null
            ? params.merchantId_eq
            : undefined,
        merchantId_label: renderMerchantLabel(),
      })
    );
    getListAllTransaction();
  }, [params, role, merchantId]);

  return (
    <React.Fragment>
      <Helmet title="All Transaction" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        valueRangePicker={valueRangePicker}
        setValueFilter={setValueFilter}
        setValueRangePicker={setValueRangePicker}
        setOptionByStatus={setOptionByStatus}
        optionByStatus={optionByStatus}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
      />

      <ModalZoomImage
        visible={isModalZoomImage}
        image={imageZoom}
        alt="All Transaction Image Zoom"
        onClose={toggleIsModalZoomImage}
      />

      <ModalInformations
        onClose={() => toggleOpenModalInfomations(!modalInfo.isOpen, '', '')}
        visible={modalInfo.isOpen}
        title={modalInfo.title}
        description={modalInfo.description}
        iconWidth={80}
        iconHeight={80}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            All Transaction
          </Typography>
          <Breadcrumbs routes={[typeRoutes('All Transaction', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('createdDateTime_lte') !== null &&
              searchParams.get('createdDateTime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('createdDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('createdDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}
            {searchParams.get('pointMultiplierEventCreationId_eq') !== null && (
              <Chip
                label={`${searchParams.get(
                  'pointMultiplierEventCreationId_eq'
                )}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointMultiplierEventCreationId_eq');
                }}
              />
            )}

            {searchParams.get('pointMultiplierEventTitle_contains') !==
              null && (
              <Chip
                label={`${searchParams.get(
                  'pointMultiplierEventTitle_contains'
                )}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointMultiplierEventTitle_contains');
                }}
              />
            )}

            {role === ROLE_SDC_ADMIN[USER_TYPE.ADMINISTRATOR] &&
              searchParams.get('merchantId_label') !== null && (
                <Chip
                  label={searchParams.get('merchantId_label')}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('merchantId_eq');
                  }}
                />
              )}

            {searchParams.get('processId_label') !== null && (
              <Chip
                label={searchParams.get('processId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('processId_eq');
                }}
              />
            )}
            {searchParams.get('accountId_eq') !== null && (
              <Chip
                label={searchParams.get('accountId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('accountId_eq');
                }}
              />
            )}
            {searchParams.get('id_eq') !== null && (
              <Chip
                label={searchParams.get('id_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('id_eq');
                }}
              />
            )}

            {!IsNullOrEmptyOrUndefined(
              searchParams.get('pointStatusEnum_eq')
            ) && (
              <Chip
                label={
                  searchParams.get('pointStatusEnum_eq') === '1'
                    ? 'Active'
                    : searchParams.get('pointStatusEnum_eq') === '2'
                    ? 'Expired'
                    : '--'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointStatusEnum_eq');
                }}
              />
            )}

            {searchParams.get('transactionFilterType_by') &&
              valueFilter?.transactionFilterType_by?.map((item, index) => (
                <Chip
                  label={item.title}
                  color={'default' as ColorType}
                  onDelete={() => {
                    valueFilter?.transactionFilterType_by?.splice(index, 1);
                    setValueFilter({
                      ...valueFilter,
                      transactionFilterType_by:
                        valueFilter?.transactionFilterType_by,
                    });
                    setParams(preState => ({
                      ...preState,
                      _page: 0,
                      _size: 10,
                      transactionFilterType_by:
                        valueFilter?.transactionFilterType_by.length > 0
                          ? valueFilter?.transactionFilterType_by
                              .map(item => item.value)
                              .toString()
                          : undefined,
                    }));
                  }}
                />
              ))}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onOpenModalInfo={toggleOpenModalInfomations}
        rowsPerPage={params._size}
        textNodata="There are no all transaction(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTimes
                    )}
                  </TableCell>
                  <TableCell align="left">{row.customerId}</TableCell>

                  <TableCell align="left">
                    {renderTransactionType(row)}
                  </TableCell>
                  <TableCell align="left">{renderSpentAmount(row)}</TableCell>
                  <TableCell align="left">
                    {renderReceiptImage(row?.receiptImageUrl ?? null)}
                  </TableCell>
                  {isAdministrator && (
                    <TableCell align="left">
                      {renderSystemAssuranceLevel(row)}
                    </TableCell>
                  )}
                  <TableCell align="left">
                    {row.pointMultiplierEventCreationId ?? '--'}
                  </TableCell>
                  <TableCell align="left">
                    {row?.pointMultiplierEventTitle ?? '--'}
                  </TableCell>
                  <TableCell align="left">{renderFieldPoints(row)}</TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(row.expiryDateTime, format)}
                  </TableCell>
                  <TableCell align="left">{renderStatus(row)}</TableCell>
                  {isAdministrator && (
                    <TableCell align="left">
                      {renderSimilarTransactionIds(row)}
                    </TableCell>
                  )}
                  {isAdministrator && (
                    <TableCell align="left">
                      {renderSimilarReceiptIds(row)}
                    </TableCell>
                  )}
                  {isAdministrator && (
                    <TableCell align="left">
                      {renderPointsStatus(row)}
                    </TableCell>
                  )}
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default AllTransaction;
