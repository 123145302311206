import {SidebarItemsType} from '../types/typeSidebar';
import {path} from './path';
import {images} from '../constants/image';
import {envRole} from '../utils';
import {USER_TYPE} from '../constants/UserType';
const isAdministrator = +envRole === USER_TYPE.ADMINISTRATOR;

const DashboardNavRoutes = [
  {
    title: '',
    pages: [
      {
        href: `/${path.ISLAND_MEMBERS}`,
        icon: images.icon_members,
        title: 'Islander Members',
        subPage: true,
        children: [
          {
            href: `${path.ISLAND_MEMBERS}/${path.TERMINATED_MEMBERS}`,
            icon: images.icon_terminated_members,
            title: 'Terminated Members',
          },
          {
            href: `${path.ISLAND_MEMBERS}/${path.WATCHLIST_TAG}`,
            icon: images.icon_transaction,
            title: 'Watchlist Tags Values',
          },
        ],
      },
      {
        href: `/${path.ISLAND_PARTNERS}`,
        icon: images.icon_partners,
        title: `Island Partner${isAdministrator ? 's' : ''}`,
      },
      {
        href: `/${path.SDC_ADMINISTRATORS}`,
        icon: images.icon_sdc_admin,
        title: 'SDC Administrators',
      },
      {
        href: `/${path.ALL_TRANSACTION}`,
        icon: images.icon_transaction,
        title: 'All Transaction',
        isDropdown: isAdministrator,
        subPage: true,
        children: [
          {
            href: `/${path.ALL_TRANSACTION}/${path.RECEIPT_VALIDATION_LOGS}`,
            icon: images.icon_transaction,
            title: 'Receipt Validation Logs',
          },
        ],
      },
      {
        href: `/${path.LIMITED_EVENT}`,
        icon: images.icon_transaction,
        title: 'Limited Events',
      },
      {
        href: `/${path.REFERRAL_PROGRAMME}`,
        icon: images.icon_transaction,
        title: 'Referral Programme',
        children: [
          {
            href: `/${path.REFERRAL_PROGRAMME}/${path.PARTNERSHIP}`,
            icon: images.icon_partners,
            title: 'Partnership',
          },
          {
            href: `/${path.REFERRAL_PROGRAMME}/${path.ISLANDER_MEMBER}`,
            icon: images.icon_members,
            title: 'Islander Member',
          },
          {
            href: `/${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}`,
            icon: images.icon_transaction,
            title: 'Referral Transactions',
          },
        ],
      },
      {
        href: `/${path.VOUCHER_TRANSACTION_HISTORY}`,
        icon: images.icon_voucher_transaction,
        title: 'Voucher Transaction',
      },
      {
        href: `/${path.VOUCHER}`,
        icon: images.icon_voucher,
        title: 'Voucher',
        subPage: true,
        children: [
          {
            href: `/${path.VOUCHER}/${path.FEATURED_VOUCHER}`,
            icon: images.icon_featured_voucher,
            title: 'Featured Voucher',
          },
          {
            href: `/${path.VOUCHER}/${path.VOUCHER_CATEGORIES}`,
            icon: images.icon_voucher_categories,
            title: 'Voucher Categories',
            tierChildren: 1,
            children: [
              {
                href: `/${path.VOUCHER}/${path.VOUCHER_CATEGORIES}/${path.VOUCHER_CATEGORIES_ADD_NEW}`,
                icon: images.icon_add_new_categories,
                title: 'Add new',
              },
              {
                href: `/${path.VOUCHER}/${path.VOUCHER_CATEGORIES}/${path.VOUCHER_CATEGORIES_MANAGE}`,
                icon: images.icon_manage_categories,
                title: 'Manage categories',
              },
            ],
          },
        ],
      },
      {
        href: `/${path.MOBILE_APP_CMS}`,
        icon: images.icon_mobile_app_cms,
        title: 'Mobile App CMS',
        children: [
          {
            href: `/${path.MOBILE_APP_CMS}/${path.EVENTS}`,
            icon: images.icon_partners,
            title: 'Event',
          },
          {
            href: `/${path.MOBILE_APP_CMS}/${path.ARTICLE}`,
            icon: images.icon_members,
            title: 'Article',
          },
          {
            href: `/${path.MOBILE_APP_CMS}/${path.MEMBER_DASHBOARD}`,
            icon: images.icon_member_dashboard,
            title: 'Member Dashboard',
            tierChildren: 1,
            children: [
              {
                href: `/${path.MOBILE_APP_CMS}/${path.MEMBER_DASHBOARD}/${path.MEMBER_DASHBOARD_CREATE_NEW}`,
                icon: images.icon_member_dashboard_create_new,
                title: 'Create New',
              },
              {
                href: `/${path.MOBILE_APP_CMS}/${path.MEMBER_DASHBOARD}/${path.MEMBER_DASHBOARD_MANAGE_LIVE}`,
                icon: images.icon_manage_live,
                title: 'Manage Live',
              },
            ],
          },
          {
            href: `/${path.MOBILE_APP_CMS}/${path.MEDIA_LIBRARY}`,
            icon: images.icon_media_library,
            title: 'Media Library',
            tierChildren: 1,
            children: [
              {
                href: `/${path.MOBILE_APP_CMS}/${path.MEDIA_LIBRARY}/${path.UPLOAD_NEW}`,
                icon: images.icon_upload_new,
                title: 'Upload New',
              },
              {
                href: `/${path.MOBILE_APP_CMS}/${path.MEDIA_LIBRARY}/${path.MOBILE_ASSETS}`,
                icon: images.icon_manage_assets,
                title: 'Manage Assets',
              },
            ],
          },
        ],
      },
      {
        href: `/${path.AUDIT_LOGS}`,
        icon: images.icon_audit_logs,
        title: 'Audit Logs',
      },
      {
        href: `/${path.AUDIT_LOGS_MEMBERS}`,
        icon: images.icon_audit_logs,
        title: 'Audit Logs Members',
      },
    ] as SidebarItemsType[],
  },
];

export default DashboardNavRoutes;
