/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import Snackbar, {SnackbarOrigin} from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
// styled
// import {} from './Notification.style';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface State extends SnackbarOrigin {
  open: boolean;
}

function Notification(props: any) {
  const [state, setState] = useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  });
  const {vertical, horizontal, open} = state;
  const {message, variation = 'success', linkElement = null, icon} = props;
  useEffect(() => {
    if (!props && !message) return;
    setState({...state, open: true});
  }, [props]);

  return (
    (message && (
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        open={open}
        onClose={() =>
          setState({
            ...state,
            open: false,
          })
        }
        message=""
        key={vertical + horizontal}
        autoHideDuration={4000}
      >
        <Alert
          severity={variation}
          icon={icon}
          variant="filled"
          onClose={() => {
            setState({
              ...state,
              open: false,
            });
          }}
        >
          <span dangerouslySetInnerHTML={{__html: message}}></span>
          {linkElement}
        </Alert>
      </Snackbar>
    )) ||
    null
  );
}
export default Notification;
