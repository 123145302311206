/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  Delete,
  Edit,
  Done as Active,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {ColumnsType, typeRoutes} from '../../types/typeAllTransaction';
import {useSearchParams} from 'react-router-dom';
import queryString from 'query-string';
import {
  Breadcrumbs,
  Button,
  Chip,
  ModalConfirm,
  Table,
  Tooltips,
} from '../../components';
import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';
import {images} from '../../constants/image';
import useAuth from '../../hooks/useAuth';
import VoucherCategoryService from '../../services/Voucher/voucher-category.service';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
  StyledStack,
} from './VoucherCategories.style';
import ModalUpdate from './ModalUpdate/ModalUdate';
import MobileAssetsService from '../../services/MobileAssets/mobile-assets.service';
import {
  VOUCHER_TRANSACTION_STATUS_COLOR,
  VOUCHER_TRANSACTION_STATUS_LABEL,
} from '../../constants/status';
import {ColorType} from '../../types/typeChip';
import ModalFilter from './Modalfilter/ModalFilter';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';

const VoucherCategories = (props: any) => {
  // states
  const {showNotification, getUser} = useAuth();
  const {email} = getUser() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [shopCategories, setShopCategories] = useState<any[]>([]);
  const [icons, setIcons] = useState<any[]>([]);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [valueDisplayOn, setValueDisplayOn] = useState<any[]>([]);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'CreatedDateTime:desc',
    title_contains: searchParamsObject.title_contains ?? undefined,
    description_contains: searchParamsObject.description_contains ?? undefined,
    iconActive_contains: searchParamsObject.iconActive_contains ?? undefined,
    iconInactive_contains:
      searchParamsObject.iconInactive_contains ?? undefined,
    associatedSitecoreShopCategory_contains:
      searchParamsObject.associatedSitecoreShopCategory_contains ?? undefined,
    showOnMobileVoucherListing_eq:
      searchParamsObject.showOnMobileVoucherListing_eq ?? undefined,
    showOnMobileDashboardGuestMode_eq:
      searchParamsObject.showOnMobileDashboardGuestMode_eq ?? undefined,
    showOnMobileDashboardLoggedIn_eq:
      searchParamsObject.showOnMobileDashboardLoggedIn_eq ?? undefined,
  });
  const [valueFilter, setValueFilter] = useState({
    title_contains: searchParams.get('title_contains') ?? '',
    description_contains: searchParams.get('description_contains') ?? '',
    iconActive_contains: searchParams.get('iconActive_contains') ?? '',
    iconInactive_contains: searchParams.get('iconInactive_contains') ?? '',
    associatedSitecoreShopCategory_contains:
      searchParams.get('associatedSitecoreShopCategory_contains') ?? '',
    showOnMobileVoucherListing_eq:
      searchParams.get('showOnMobileVoucherListing_eq') ?? '',
    showOnMobileDashboardGuestMode_eq:
      searchParams.get('showOnMobileDashboardGuestMode_eq') ?? '',
    showOnMobileDashboardLoggedIn_eq:
      searchParams.get('showOnMobileDashboardLoggedIn_eq') ?? '',
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'id',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'ID',
    },
    {
      dataIndex: 'tilte',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      dataIndex: 'iconActive',
      numeric: false,
      disablePadding: false,
      label: 'Icon Asset - Active',
    },
    {
      dataIndex: 'iconInactive',
      numeric: false,
      disablePadding: false,
      label: 'Icon Asset - Inactive',
    },
    {
      dataIndex: 'category',
      numeric: false,
      disablePadding: false,
      label: 'Sitecore’s Shop category',
    },
    {
      dataIndex: 'showOnMobileDashboardGuestMode',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'Display on <br/> App Dashboard’s Discovery section (Guest mode)',
    },
    {
      dataIndex: 'showOnMobileDashboardLoggedIn',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label:
        'Display on <br/> App Dashboard’s Discovery section (Logged In mode)',
    },
    {
      dataIndex: 'showOnMobileVoucherListing',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'Display on <br/> App Dashboard’s Listing filter',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getVoucherCategories = () => {
    setIsLoading(true);
    new VoucherCategoryService().getAll({...params}).then(res => {
      if (res?.success) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const renderImageSVG = (url: string) => {
    return url !== null ? (
      <img
        src={url}
        alt="image svg"
        width={40}
        height={60}
        style={{objectFit: 'cover'}}
      />
    ) : (
      '--'
    );
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const toggleModalUpdate = () => {
    setIsModalUpdate(!isModalUpdate);
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const onDelete = () => {
    setIsLoading(true);
    new VoucherCategoryService().softDelete(selectedId).then(res => {
      if (res && res?.success) {
        showNotification({
          message: 'Category deactivated successfully!',
        });
        toggleModalConfirmDelete();
        getVoucherCategories();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onActive = (index: number) => {
    const id = dataSource[index]?.id;
    setIsLoading(true);
    new VoucherCategoryService()
      .update(id, {...dataSource[index], status: 1})
      .then(res => {
        if (res && res?.success) {
          showNotification({
            message: 'Category activated successfully!',
          });
          getVoucherCategories();
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderYesNo = value => {
    switch (value) {
      case false:
        return 'No';
      case true:
        return 'Yes';
      default:
        return 'No';
    }
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalUpdate();
                setSelectedId(dataSource[value]?.id);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          {dataSource[value]?.status === 1 ? (
            <Tooltips title="Deactivate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  setSelectedId(dataSource[value]?.id);
                  toggleModalConfirmDelete();
                }}
                size="medium"
              >
                <Delete style={StyledIcon} color="error" />
              </IconButton>
            </Tooltips>
          ) : (
            <Tooltips title="Activate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  onActive(value);
                }}
                size="medium"
              >
                <Active style={StyledIcon} color="success" />
              </IconButton>
            </Tooltips>
          )}
        </StyledAction>
      );
    }
  };

  const renderStatus = (value: any) => {
    return (
      <Chip
        label={VOUCHER_TRANSACTION_STATUS_LABEL[value.status]}
        color={VOUCHER_TRANSACTION_STATUS_COLOR[value.status] as ColorType}
      />
    );
  };

  const getCategories = () => {
    new VoucherCategoryService().getSirecoreCategory().then(res => {
      if (res.status === 200) {
        const newCategories: any[] = res?.data?.data?.map((item, index) => ({
          value: index + 1,
          label: item?.label,
          id: item?.id,
        }));
        const listCategories = [
          {
            value: 0,
            label: '---',
            id: null,
          },
          ...newCategories,
        ];
        getCorrectLinkValueFromUrl(listCategories);
        setShopCategories(listCategories);
      }
    });
  };

  const getIconAssets = () => {
    new MobileAssetsService()
      .GetAllMobileAsset({_page: 0, _size: 99999})
      .then(res => {
        let listIcon: any[] = [
          {
            value: 99999,
            label: '--',
            image: null,
            width: 48,
            height: 32,
          },
        ];
        res?.data?.items?.map((item, index) => {
          const isSvgFile = item.fileName.split('.')[1] === 'svg';
          if (isSvgFile) {
            listIcon = [
              ...listIcon,
              {
                value: index + 1,
                label: item?.fileName,
                image: item?.uRL,
                width: 48,
                height: 32,
              },
            ];
          }
        });

        getCorrectIconValueFromUrl(listIcon);
        setIcons(listIcon);
      });
  };

  const getCorrectIconValueFromUrl = listIcon => {
    if (
      valueFilter.iconActive_contains &&
      !Number(valueFilter.iconActive_contains)
    ) {
      setValueFilter(preState => ({
        ...preState,
        iconActive_contains:
          listIcon.find(f => f.image === valueFilter.iconActive_contains)
            ?.value ?? '',
      }));
    }

    if (
      valueFilter.iconInactive_contains &&
      !Number(valueFilter.iconInactive_contains)
    ) {
      setValueFilter(preState => ({
        ...preState,
        iconInactive_contains:
          listIcon.find(f => f.image === valueFilter.iconInactive_contains)
            ?.value ?? '',
      }));
    }
  };

  const getCorrectLinkValueFromUrl = list => {
    if (
      valueFilter.associatedSitecoreShopCategory_contains &&
      !Number(valueFilter.associatedSitecoreShopCategory_contains)
    ) {
      setValueFilter(preState => ({
        ...preState,
        associatedSitecoreShopCategory_contains:
          list.find(
            f => f.id === valueFilter.associatedSitecoreShopCategory_contains
          )?.value ?? '',
      }));
    }
  };

  const handleFilter = () => {
    if (valueFilter?.title_contains) {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        title_contains: valueFilter.title_contains,
      }));
    }

    if (valueFilter?.description_contains) {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        description_contains: valueFilter.description_contains,
      }));
    }

    if (valueFilter?.associatedSitecoreShopCategory_contains) {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        associatedSitecoreShopCategory_contains: shopCategories.find(
          item =>
            item?.value === valueFilter.associatedSitecoreShopCategory_contains
        )?.id,
      }));
    }

    if (valueFilter?.iconActive_contains) {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        iconActive_contains: icons.find(
          f => f.value === valueFilter.iconActive_contains
        ).image,
      }));
    }

    if (valueFilter?.iconInactive_contains) {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        iconInactive_contains: icons.find(
          f => f.value === valueFilter.iconInactive_contains
        ).image,
      }));
    }

    if (valueFilter?.showOnMobileDashboardGuestMode_eq !== '') {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        showOnMobileDashboardGuestMode_eq:
          valueFilter.showOnMobileDashboardGuestMode_eq
            ? valueFilter.showOnMobileDashboardGuestMode_eq
            : undefined,
      }));
    }

    if (valueFilter?.showOnMobileDashboardLoggedIn_eq !== '') {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        showOnMobileDashboardLoggedIn_eq:
          valueFilter.showOnMobileDashboardLoggedIn_eq
            ? valueFilter.showOnMobileDashboardLoggedIn_eq
            : undefined,
      }));
    }

    if (valueFilter?.showOnMobileVoucherListing_eq !== '') {
      setParams(prevState => ({
        ...prevState,
        _page: 0,
        showOnMobileVoucherListing_eq: valueFilter.showOnMobileVoucherListing_eq
          ? valueFilter.showOnMobileVoucherListing_eq
          : undefined,
      }));
    }

    toggleModalFilter(!isModalFilter);
  };

  const handleClean = () => {
    setValueFilter({
      title_contains: '',
      description_contains: '',
      associatedSitecoreShopCategory_contains: '',
      iconActive_contains: '',
      iconInactive_contains: '',
      showOnMobileDashboardGuestMode_eq: '',
      showOnMobileDashboardLoggedIn_eq: '',
      showOnMobileVoucherListing_eq: '',
    });
    setParams({
      _page: 0,
      _size: 10,
      _sort: 'CreatedDateTime:desc',
    });
  };

  const handleDeleteChip = (key: string) => {
    if (key === 'title_contains') {
      deleteSearchParams('title_contains');
      setValueFilter({
        ...valueFilter,
        title_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        title_contains: undefined,
      }));
    }

    if (key === 'description_contains') {
      deleteSearchParams('description_contains');
      setValueFilter({
        ...valueFilter,
        description_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        description_contains: undefined,
      }));
    }

    if (key === 'iconActive_contains') {
      deleteSearchParams('iconActive_contains');
      setValueFilter({
        ...valueFilter,
        iconActive_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        iconActive_contains: undefined,
      }));
    }

    if (key === 'iconInactive_contains') {
      deleteSearchParams('iconInactive_contains');
      setValueFilter({
        ...valueFilter,
        iconInactive_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        iconInactive_contains: undefined,
      }));
    }

    if (key === 'associatedSitecoreShopCategory_contains') {
      deleteSearchParams('associatedSitecoreShopCategory_contains');
      setValueFilter({
        ...valueFilter,
        associatedSitecoreShopCategory_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        associatedSitecoreShopCategory_contains: undefined,
      }));
    }

    if (key === 'showOnMobileDashboardGuestMode_eq') {
      deleteSearchParams('showOnMobileDashboardGuestMode_eq');
      setValueFilter({
        ...valueFilter,
        showOnMobileDashboardGuestMode_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        showOnMobileDashboardGuestMode_eq: undefined,
      }));
      setValueDisplayOn(valueDisplayOn.filter(f => f.value !== 1));
    }

    if (key === 'showOnMobileDashboardLoggedIn_eq') {
      deleteSearchParams('showOnMobileDashboardLoggedIn_eq');
      setValueFilter({
        ...valueFilter,
        showOnMobileDashboardLoggedIn_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        showOnMobileDashboardLoggedIn_eq: undefined,
      }));
      setValueDisplayOn(valueDisplayOn.filter(f => f.value !== 2));
    }

    if (key === 'showOnMobileVoucherListing_eq') {
      deleteSearchParams('showOnMobileVoucherListing_eq');
      setValueFilter({
        ...valueFilter,
        showOnMobileVoucherListing_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        showOnMobileVoucherListing_eq: undefined,
      }));
      setValueDisplayOn(valueDisplayOn.filter(f => f.value !== 3));
    }
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelVoucherCategories({
        ...params,
        _page: undefined,
        _size: undefined,
        _sort: undefined,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    getCategories();
    getIconAssets();
  }, []);

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );

    getVoucherCategories();
  }, [params, icons]);

  return (
    <React.Fragment>
      <Helmet title="Voucher Categories" />
      <ModalUpdate
        visible={isModalUpdate}
        onClose={toggleModalUpdate}
        reloadPage={getVoucherCategories}
        icons={icons}
        shopCategories={shopCategories}
        idDetail={selectedId}
      />
      <ModalConfirm
        visible={isModalConfirmDelete}
        icon={images.image_deactivate}
        iconWidth={100}
        agreeLabel="Deactivate"
        disAgreeLabel="Cancel"
        title={
          <Typography variant="h4">
            Are you sure you want to deactivate this category?
          </Typography>
        }
        subTitle={
          <Typography>
            Please note that this change will take effect instantly on App. You
            can always reactivate it.
          </Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        icons={icons}
        shopCategories={shopCategories}
        setValueDisplayOn={setValueDisplayOn}
        valueDisplayOn={valueDisplayOn}
      />

      <Grid
        justifyContent="space-between"
        container
        spacing={2}
        columns={16}
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom>
            Voucher Categories
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Manage categories', null, true)]} />
        </Grid>
        <Grid item display="flex" alignItems="center">
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('title_contains') !== null && (
              <Chip
                label={searchParams.get('title_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('title_contains');
                }}
              />
            )}
            {searchParams.get('description_contains') !== null && (
              <Chip
                label={searchParams.get('description_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('description_contains');
                }}
              />
            )}
            {searchParams.get('associatedSitecoreShopCategory_contains') !==
              null && (
              <Chip
                label={
                  shopCategories.find(
                    f =>
                      f.id ===
                      searchParams.get(
                        'associatedSitecoreShopCategory_contains'
                      )
                  )?.label
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('associatedSitecoreShopCategory_contains');
                }}
              />
            )}
            {searchParams.get('iconActive_contains') !== null && (
              <Chip
                label={
                  icons.find(
                    f => f.image === searchParams.get('iconActive_contains')
                  )?.label
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('iconActive_contains');
                }}
              />
            )}

            {searchParams.get('iconInactive_contains') !== null && (
              <Chip
                label={
                  icons.find(
                    f => f.image === searchParams.get('iconInactive_contains')
                  )?.label
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('iconInactive_contains');
                }}
              />
            )}
            {searchParams.get('showOnMobileDashboardGuestMode_eq') !== null && (
              <Chip
                label="Show On Mobile Dashboard Guest Mode"
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('showOnMobileDashboardGuestMode_eq');
                }}
              />
            )}
            {searchParams.get('showOnMobileDashboardLoggedIn_eq') !== null && (
              <Chip
                label="Show On Mobile Dashboard Logged"
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('showOnMobileDashboardLoggedIn_eq');
                }}
              />
            )}
            {searchParams.get('showOnMobileVoucherListing_eq') !== null && (
              <Chip
                label="Show On Mobile Voucher Listing"
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('showOnMobileVoucherListing_eq');
                }}
              />
            )}
          </StyledStack>
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Tooltips title="Filter list">
              <IconButton
                aria-label="Filter list"
                size="large"
                onClick={toggleModalFilter}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltips>
          </Grid>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        setParams={setParams}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="No categories found in this status."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(index)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell align="left">{row?.title}</TableCell>
                  <TableCell align="left">
                    {renderImageSVG(row?.iconActive)}
                  </TableCell>
                  <TableCell align="left">
                    {renderImageSVG(row?.iconInactive)}
                  </TableCell>
                  <TableCell align="left">
                    {row?.associatedSitecoreShopCategory || '--'}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileDashboardGuestMode)}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileDashboardLoggedIn)}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileVoucherListing)}
                  </TableCell>
                  <TableCell align="center">{renderStatus(row)}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(index)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
};

export default VoucherCategories;
