/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {sortBy} from 'lodash';

import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {Breadcrumbs, Table, Tooltips, Button} from '../../components';
import VoucherService from '../../services/Voucher/voucher.service';
import useAuth from '../../hooks/useAuth';
import success from '../../constants/success';
import failure from '../../constants/failure';
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

//style
import {
  Typography,
  Divider,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './FeaturedVoucher.style';

interface onSortObject {
  oldIndex?: number | undefined;
  newIndex?: number | undefined;
}

const DragHandle = SortableHandle(({style, children, ...other}: any) => (
  <TableCell {...other}> {children} </TableCell>
));

const TableBodySortable = SortableContainer(({children}: any) => (
  <TableBody>{children}</TableBody>
));

const Row = SortableElement(({row, ...other}: any) => {
  return (
    <StyledTableRow {...other}>
      <DragHandle align="left">{row.numberId}</DragHandle>
      <DragHandle align="left">{row.title}</DragHandle>
      <DragHandle align="left">{row.orderFeaturedTicket}</DragHandle>
      <TableCell
        align="right"
        padding="checkbox"
        style={{paddingRight: '10px'}}
      >
        {other.onClick()}
      </TableCell>
    </StyledTableRow>
  );
});

function FeaturedVoucher() {
  const {showNotification} = useAuth();

  // states
  const getCurrentDate = new Date().toISOString();
  const [order] = React.useState<'desc' | 'asc'>('desc');
  const [orderBy] = React.useState('orderFeaturedTicket');
  const [dense] = React.useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
    _sort: 'orderFeaturedTicket:asc',
    expireStartDateTime_lte: getCurrentDate,
    expireEndDateTime_gte: getCurrentDate,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'voucherCreationID',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Creation ID',
    },
    {
      dataIndex: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Title',
    },
    {
      dataIndex: 'orderFeaturedTicket',
      numeric: false,
      disablePadding: false,
      label: 'Order',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onDeleteFeaturedVoucher = id => {
    const data = dataSource.filter(item => item.id !== id);
    setDataSource(
      data.map((item, index) => ({...item, orderFeaturedTicket: index + 1}))
    );
  };

  const getListFeaturedVoucher = () => {
    setIsLoading(true);
    new VoucherService()
      .getAll({
        ...params,
        orderFeaturedTicket_gt: 0,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res?.data?.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      });
  };

  const validateLengthFeaturedVouchers = () => {
    return dataSource.length <= 6;
  };

  const onUpdate = () => {
    if (!validateLengthFeaturedVouchers()) {
      showNotification({
        message: failure.SAVE_FAILURE,
        variation: 'error',
      });
      return;
    }
    new VoucherService()
      .arrangerFeaturedVoucher({
        featuredTickets: dataSource.map(item => ({
          id: item.id,
          orderFeaturedTicket: item.orderFeaturedTicket,
        })),
      })
      .then(res => {
        if (res.success) {
          showNotification({
            message: success.UPDATE_SUCCESS,
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    getListFeaturedVoucher();
  }, []);

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                onDeleteFeaturedVoucher(value);
              }}
            >
              <Delete color="error" style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const onSortEnd = ({oldIndex, newIndex}: onSortObject) => {
    dataSource[oldIndex].orderFeaturedTicket = newIndex + 1;
    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        dataSource[i].orderFeaturedTicket = i + 2;
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        dataSource[i].orderFeaturedTicket = i;
      }
    }

    setDataSource(arrayMoveImmutable(dataSource, oldIndex, newIndex));
  };
  return (
    <React.Fragment>
      <Helmet title="Voucher" />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Featured Voucher
          </Typography>

          <Breadcrumbs routes={[typeRoutes('Featured Voucher', null, true)]} />
        </Grid>
        <Button onClick={onUpdate} loading={isLoading}>
          Save changes
        </Button>
      </Grid>

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        textNodata="There are no voucher(s) matching the filter."
        orderBy={orderBy}
        isLoading={isLoading}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis="y">
          {dataSource?.map((row, index) => {
            return (
              <Row
                row={row}
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                index={index}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
                onClick={() => renderAction(row.id)}
              />
            );
          })}
        </TableBodySortable>
      </Table>
    </React.Fragment>
  );
}

export default FeaturedVoucher;
