import styled from 'styled-components/macro';
import {spacing, display} from '@mui/system';
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const StyledQR = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 30px;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const StyledDivBreadcrumbs = styled.div`s
  margin-top: 10px;
`;

const TableStaffWrapper = styled.div`
  .MuiTableContainer-root {
    min-height: 140px;
  }
`;

export {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
  StyledQR,
  TableStaffWrapper,
};
