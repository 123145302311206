/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import {
  Checkbox,
  TableHead,
  TableContainer,
  TableCell,
  TablePagination,
  TableRow,
  // TableSortLabel,
  CircularProgress,
} from '@mui/material';
import {images} from '../../constants/image';
import Tooltips from '../Tooltips/Tooltips';

// styles
import {
  StyledNoData,
  Paper,
  StyledTable,
  StyledMuiTableRowHover,
  TableHeader,
  StyledPaperLoading,
  Typography,
} from './Table.style';
import {IsNullOrEmptyOrUndefined} from '../../utils/helpers';
import {TableHeadProps, PropsTable} from '../../types/typeTable';
import {ModalInformations as ModalInformationsType} from '../../types/typeModals';

const CustomTableHead = (props: TableHeadProps) => {
  const {
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    // onRequestSort,
    onOpenModalInfo = () => {},
    isMultiCheckbox = true,
  } = props;

  // const createSortHandler = (property: string) => (event: any) => {
  //   onRequestSort(event, property);
  // };

  const renderIconInfo = (modalInfo?: ModalInformationsType) => {
    if (IsNullOrEmptyOrUndefined(modalInfo)) {
      return '';
    }
    return (
      <img
        src={images.icon_info}
        alt="Infomations"
        style={{
          cursor: 'pointer',
          display: 'inline-block',
          marginBottom: '-5px',
        }}
        onClick={() => {
          onOpenModalInfo(
            true,
            modalInfo?.title ?? '',
            modalInfo?.description ?? ''
          );
        }}
      />
    );
  };

  return (
    <TableHead>
      <TableRow>
        {isMultiCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
        )}
        {columns.map(headCell =>
          !headCell.disabled ? (
            <TableCell
              key={headCell.dataIndex}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.dataIndex ? order : false}
            >
              {
                /* Content Label */
                headCell.subText ? (
                  <Tooltips title={headCell.subText} placement="bottom-start">
                    <TableHeader>
                      <p
                        className={`text__align--${headCell.textAlign}`}
                        dangerouslySetInnerHTML={{__html: headCell.label}}
                      />
                      {renderIconInfo(headCell.modalInfo) /* Icon Info */}
                      {headCell?.helperText ? (
                        <Typography component="em" display="block">
                          {headCell.helperText}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </TableHeader>
                  </Tooltips>
                ) : (
                  /* Content Label */
                  <TableHeader>
                    <div
                      className={`head__title--wrap text__align--${headCell.textAlign}`}
                    >
                      <p dangerouslySetInnerHTML={{__html: headCell.label}} />
                      {renderIconInfo(headCell.modalInfo) /* Icon Info */}
                    </div>
                    {headCell?.helperText ? (
                      <Typography component="em" display="block">
                        {headCell.helperText}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </TableHeader>
                )
              }
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

function Table({
  dataSource,
  columns,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  onSelectAllClick,
  onOpenModalInfo,
  // onRequestSort,
  selected,
  order,
  orderBy,
  isLoading,
  dense,
  isMultiCheckbox = true,
  children,
  isPagination = true,
  count,
  textNodata = 'No data',
  ...other
}: PropsTable) {
  return (
    <React.Fragment>
      {isLoading ? (
        <Paper sx={StyledPaperLoading}>
          <CircularProgress />
        </Paper>
      ) : (
        <Paper>
          <TableContainer>
            <StyledTable
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
              sx={StyledMuiTableRowHover}
              {...other}
            >
              <CustomTableHead
                columns={columns}
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onOpenModalInfo={onOpenModalInfo}
                isMultiCheckbox={isMultiCheckbox}
                onSelectAllClick={onSelectAllClick}
                rowCount={dataSource?.length}
              />
              {children}
            </StyledTable>
          </TableContainer>

          {dataSource.length === 0 && (
            <StyledNoData>
              <img src={images.icon_no_data} alt="icon_no_data" />
              <Typography>{textNodata}</Typography>
            </StyledNoData>
          )}

          {isPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          )}
        </Paper>
      )}
    </React.Fragment>
  );
}

export default Table;
