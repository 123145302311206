/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {Modal, Button, Input} from '../../../../components';
import {Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {AspectSelectImage, DateTimePicker} from '../../../../components';
// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';
import EventService from '../../../../services/Event/event.service';
import {formatDateTime} from '../../../../utils';

interface ModalAddNewProps {
  idEvent?: any;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({
  idEvent,
  visible,
  onClose,
  reloadPage,
}: ModalAddNewProps) {
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const {showNotification} = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  // states
  const [initialValues, setInitialValues] = useState({
    title: '',
    shortDescription: '',
    uRL: '',
    qrExpiredDate: qrExpiredDate.toISOString(),
    refreshedDate: refreshedDate.toISOString(),
    eventStartDateTime: '',
    eventEndDateTime: '',
    eventImages: [],
    image: '',
  });

  const getDataDetail = () => {
    if (idEvent) {
      setIsLoading(true);
      new EventService().getOne(idEvent).then(res => {
        if (res && res.data && res.data.data) {
          const data = res?.data?.data;
          setIsLoading(false);
          setInitialValues({
            ...initialValues,
            ...data,
            eventImages: data?.image ? [data?.image] : [],
            eventStartDateTime: formatDateTime(data.eventStartDateTime),
            eventEndDateTime: formatDateTime(data.eventEndDateTime),
          });
        }
      });
    }
  };

  const validateForm = values => {
    let errors = 0;

    if (values.eventImages.length === 0) {
      errors = errors + 1;
    }

    return errors === 0;
  };

  const onUpdate = (values, eventId) => {
    if (!validateForm(values)) {
      return false;
    }
    setIsLoading(true);
    new EventService()
      .update(eventId, {
        ...values,
        image: undefined,
      })
      .then(res => {
        if (res && res?.success) {
          if (
            values.eventImages.length > 0 &&
            typeof values.eventImages[0] !== 'string'
          ) {
            new EventService()
              .uploadImage(
                res.data.id,
                new File(values.eventImages, 'eventImages.jpeg', {
                  type: values.eventImages[0].type,
                })
              )
              .then(resImg => {
                if (resImg) {
                  getDataDetail();
                  reloadPage();
                  onClose();
                  setIsLoading(false);
                  showNotification({
                    message: success.UPDATE_SUCCESS,
                  });
                }
              });
          } else {
            getDataDetail();
            setIsLoading(false);
            reloadPage();
            onClose();
            showNotification({
              message: success.UPDATE_SUCCESS,
            });
          }
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onAdd = values => {
    if (!validateForm(values)) {
      return false;
    }
    setIsLoading(true);
    new EventService().create({...values, eventImages: undefined}).then(res => {
      if (res && res?.success) {
        if (values.eventImages.length > 0) {
          new EventService()
            .uploadImage(
              res.data.id,
              new File(values.eventImages, 'eventImages.jpeg', {
                type: values.eventImages[0].type,
              })
            )
            .then(resImg => {
              if (resImg) {
                setIsLoading(false);
                onClose();
                reloadPage();
                showNotification({
                  message: success.CREATE_SUCCESS,
                });
              }
            });
        } else {
          setIsLoading(false);
          onClose();
          reloadPage();
          showNotification({
            message: success.CREATE_SUCCESS,
          });
        }
      } else {
        setIsLoading(false);
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onSubmit = values => {
    if (idEvent) {
      // call api update event
      onUpdate(values, idEvent);
    } else {
      // call api create event
      onAdd(values);
    }
    return;
  };

  const resetForm = () => {
    setInitialValues({
      title: '',
      shortDescription: '',
      uRL: '',
      qrExpiredDate: qrExpiredDate.toISOString(),
      refreshedDate: refreshedDate.toISOString(),
      eventStartDateTime: '',
      eventEndDateTime: '',
      image: '',
      eventImages: [],
    });
  };

  useEffect(() => {
    if (idEvent) {
      getDataDetail();
    } else {
      resetForm();
    }
  }, [idEvent]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
        resetForm();
      }}
    >
      <>
        <StyledModalTitle>
          {idEvent ? 'Update Event' : 'Add New Event'}
        </StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .required('Fill in the Event title')
                .max(32, 'Please input only maximum of 32 characters'),
              shortDescription: Yup.string()
                .required('Fill in the Event description')
                .max(70, 'Please input only maximum of 70 characters'),
              uRL: Yup.string()
                .matches(/((https):\/\/)+[a-zA-Z0-9-_]/, 'Url is invalid url')
                .required('Url is required'),
              eventStartDateTime: Yup.string().required(
                'Start Date Time is required'
              ),
              eventEndDateTime: Yup.string().required(
                'End Date Time is required'
              ),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              // isSubmitting,
              touched,
              values,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl fullWidth>
                    <Typography variant="body2">
                      Event Title <span className="mandatory-label">(*)</span>
                    </Typography>
                    <Typography variant="body2" sx={{fontStyle: 'italic'}}>
                      Maximum 32 characters including spaces
                    </Typography>
                    <Input
                      type="text"
                      name="title"
                      placeholder="Enter Event Title"
                      size="small"
                      variant="outlined"
                      value={values.title}
                      onChange={e => {
                        setFieldValue('title', e.target.value);
                      }}
                      helperText={touched.title && errors.title}
                      onBlur={() => setFieldTouched('title', true)}
                      error={Boolean(touched.title && errors.title)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography variant="body2">
                      Event Short Description{' '}
                      <span className="mandatory-label">(*)</span>
                      <Typography variant="body2" sx={{fontStyle: 'italic'}}>
                        Maximum 70 characters including spaces
                      </Typography>
                    </Typography>
                    <Input
                      type="text"
                      name="shortDescription"
                      placeholder="Enter Event Short Description"
                      size="small"
                      variant="outlined"
                      value={values.shortDescription}
                      onChange={e => {
                        setFieldValue('shortDescription', e.target.value);
                      }}
                      helperText={
                        touched.shortDescription && errors.shortDescription
                      }
                      onBlur={() => setFieldTouched('shortDescription', true)}
                      error={Boolean(
                        touched.shortDescription && errors.shortDescription
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography variant="body2" mb={2}>
                      Event URL <span className="mandatory-label">(*)</span>
                    </Typography>
                    <Input
                      type="text"
                      name="uRL"
                      placeholder="Enter Url"
                      size="small"
                      variant="outlined"
                      value={values.uRL}
                      onChange={e => {
                        setFieldValue('uRL', e.target.value);
                      }}
                      onBlur={() => setFieldTouched('uRL', true)}
                      helperText={touched.uRL && errors.uRL}
                      error={Boolean(touched.uRL && errors.uRL)}
                    />
                  </FormControl>
                  {/* <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    Event Date Range{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <RangePicker
                    required
                    isEmptyValue={isEmptyDate}
                    value={valueRangePicker}
                    onChange={onChangeRangePicker}
                  />
                </FormControl> */}
                  <FormControl fullWidth margin="dense">
                    <Typography variant="body2" mb={2}>
                      Start Date Time{' '}
                      <span className="mandatory-label">(*)</span>
                    </Typography>
                    <DateTimePicker
                      isErrorMessage={
                        touched.eventStartDateTime && errors.eventStartDateTime
                      }
                      value={
                        values.eventStartDateTime === ''
                          ? null
                          : values.eventStartDateTime
                      }
                      helperText={
                        touched.eventStartDateTime && errors.eventStartDateTime
                      }
                      onChange={e => {
                        if (e >= values.eventStartDateTime) {
                          setFieldValue(
                            'eventStartDateTime',
                            e ? formatDateTime(e, formatDateTimes) : ''
                          );
                          setFieldValue('eventEndDateTime', '');
                        } else {
                          setFieldValue(
                            'eventStartDateTime',
                            e ? formatDateTime(e, formatDateTimes) : ''
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="dense">
                    <Typography variant="body2" mb={2}>
                      End Date Time <span className="mandatory-label">(*)</span>
                    </Typography>
                    <DateTimePicker
                      isErrorMessage={
                        touched.eventEndDateTime && errors.eventEndDateTime
                      }
                      disabled={
                        values.eventStartDateTime === null ||
                        values.eventStartDateTime === ''
                      }
                      value={
                        values.eventEndDateTime === ''
                          ? null
                          : values.eventEndDateTime
                      }
                      helperText={
                        touched.eventEndDateTime && errors.eventEndDateTime
                      }
                      minDate={values?.eventStartDateTime || new Date()}
                      onChange={e => {
                        setFieldValue(
                          'eventEndDateTime',
                          e ? formatDateTime(e, formatDateTimes) : ''
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography variant="body2" mb={2} mt={3}>
                      Event Image <span className="mandatory-label">(*)</span>
                    </Typography>
                    <AspectSelectImage
                      required
                      showError={
                        touched.image &&
                        (values.image === null ||
                          values.image === '' ||
                          values.image === undefined)
                      }
                      defaultImages={values?.eventImages || []}
                      setDefaultImages={images => {
                        setFieldValue('eventImages', images);
                        setFieldValue('image', images[0]?.url ?? '');
                        setFieldTouched('image', true);
                      }}
                    />
                  </FormControl>
                  <Divider variant="middle" pt={8} />
                  <StyledModalActions>
                    <div className="btn-action-1">
                      <Button variant="text" type="button" onClick={onClose}>
                        Cancel
                      </Button>
                    </div>
                    <div className="btn-action-2">
                      <Button type="submit" loading={isLoading}>
                        {idEvent ? 'Update' : 'Add Event'}
                      </Button>
                    </div>
                  </StyledModalActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
