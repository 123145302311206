/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  Delete,
  Edit,
  CheckSharp as Active,
  WarningAmberRounded as WarningIcon,
} from '@mui/icons-material';
import {
  Grid,
  TableCell,
  TableBody,
  IconButton,
  Stack,
  Alert,
} from '@mui/material';
import {ColumnsType, typeRoutes} from '../../types/typeAllTransaction';
import {
  Breadcrumbs,
  ModalConfirm,
  Table,
  Tooltips,
  Chip,
  Button,
} from '../../components';

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import queryString from 'query-string';

import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';
import {images} from '../../constants/image';
import useAuth from '../../hooks/useAuth';
import MemberDashboardAsset from '../../services/MemberDashboard/member-dashboard.service';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './MemberDashboard.style';
import {ColorType} from '../../types/typeChip';
import ModalUpdate from './ModalUpdate/ModalUpdate';
import {useSearchParams} from 'react-router-dom';
import CacheService from '../../services/Cache/cache.service';
import {formatDateTime, formatLocalDateFromUTCTime} from '../../utils';

const DragHandle = SortableHandle(({style, children, width, ...other}: any) => (
  <TableCell {...other} width={width}>
    {' '}
    {children}{' '}
  </TableCell>
));

const TableBodySortable = SortableContainer(({children}: any) => (
  <TableBody>{children}</TableBody>
));

const Row = SortableElement(
  ({row, renderstatus, renderimage, renderdisplayorder, ...other}: any) => {
    return (
      <StyledTableRow {...other}>
        <DragHandle align="left">{renderdisplayorder(row)}</DragHandle>
        <DragHandle component="th" scope="row" width="40%">
          {renderimage(row)}
        </DragHandle>
        <DragHandle align="left">{renderstatus(row?.status)}</DragHandle>
        <DragHandle align="left">
          {formatLocalDateFromUTCTime(row.startDateTime, 'DD/MM/YYYY HH:mm')}
        </DragHandle>
        <DragHandle align="left">
          {formatLocalDateFromUTCTime(row.endDateTime, 'DD/MM/YYYY HH:mm')}
        </DragHandle>
        <TableCell
          align="right"
          padding="checkbox"
          style={{paddingRight: '10px'}}
          width={250}
        >
          {other.onClick()}
        </TableCell>
      </StyledTableRow>
    );
  }
);

const MemberDashboard = (props: any) => {
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const [searchParams, setSearchParams] = useSearchParams();
  const today = new Date().getTime();
  const getCurrentDate = new Date().toISOString();
  const limitedDisplayOrder = 6;
  // states
  const {showNotification} = useAuth();
  const [isChanged, setIsChanged] = useState(false);
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadButtonSave, setLoadButtonSave] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isPagination, setIsPagination] = useState(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [radioOptionsAppCTALink, setRadioOptionsAppCTALink] = useState<any[]>(
    []
  );
  const [params, setParams] = useState({
    _page: 0,
    _size: 99999,
    _sort: 'displayOrder:asc',
    endDateTime_gte: getCurrentDate,
    startDateTime_lte: getCurrentDate,
    status_eq: undefined,
    isPublished_eq: true,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'displayOrder',
      numeric: false,
      disablePadding: false,
      label: 'Order',
    },
    {
      dataIndex: 'mastheadImage',
      numeric: false,
      disablePadding: false,
      label: 'Masthead Item',
    },
    {
      dataIndex: 'isPublished',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      dataIndex: 'startDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Start Date Time',
    },
    {
      dataIndex: 'endDateTime',
      numeric: false,
      disablePadding: false,
      label: 'End Date Time',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getMemberDashboard = async () => {
    setIsLoading(true);
    await new MemberDashboardAsset()
      .GetAllMemberDashboardAsset(params)
      .then(res => {
        if (res.success) {
          setDataSource(res.data.items);
          setTotalPage(res.data.total);
          setIsLoading(false);
          const isActiveTab =
            params.startDateTime_lte !== null &&
            params.endDateTime_gte !== null &&
            params.isPublished_eq === true &&
            params._size === 99999;
          setIsAlert(isActiveTab && res.data.items.length > 6);
        } else {
          setIsLoading(false);
        }
      });
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const onDelete = () => {
    setIsLoading(true);
    new MemberDashboardAsset()
      .update(selectedId, {
        cTALinkType: dataSource.find(f => f.id === selectedId).cTALinkType,
        cTALink: dataSource.find(f => f.id === selectedId).cTALink,
        isPublished: false,
      })
      .then(res => {
        if (res && res?.success) {
          showNotification({
            message: 'Masthead deactivated successfully!',
          });
          toggleModalConfirmDelete();
          getMemberDashboard();
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onActive = id => {
    setIsLoading(true);
    new MemberDashboardAsset().update(id, {isPublished: true}).then(res => {
      if (res?.success) {
        showNotification({
          message: 'Masthead activated successfully!',
        });
        getMemberDashboard();
        setIsLoading(false);
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
        setIsLoading(false);
      }
    });
  };

  const onSave = () => {
    setIsLoading(true);
    setLoadButtonSave(true);
    const dataSourceLive = dataSource.filter(item => item.isPublished === true);
    new MemberDashboardAsset()
      .ArrangeMemberDashboardAssets({
        memberDashboardAssets: dataSourceLive.map((item, index) => ({
          id: item.id,
          displayOrder: index + 1,
        })),
      })
      .then(res => {
        if (res?.success) {
          showNotification({
            message: 'Masthead updated!',
          });
          getMemberDashboard();
          setIsLoading(false);
          setIsChanged(false);
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
          setIsLoading(false);
        }
        setLoadButtonSave(false);
      });
  };

  const renderImage = data => {
    return (
      <Grid sx={{display: 'flex', alignItems: 'center'}}>
        <img src={data?.image} width={60} height={80} />{' '}
        <Typography px={4}>{data?.title}</Typography>
      </Grid>
    );
  };

  const renderStatus = row => {
    if (
      today <= new Date(row?.startDateTime).getTime() &&
      row?.isPublished === true
    ) {
      return <Chip label="Scheduled" color={'warning' as ColorType} />;
    }

    if (
      today > new Date(row?.endDateTime).getTime() &&
      row?.isPublished === true
    ) {
      return <Chip label="Expired" color={'error' as ColorType} />;
    }

    if (
      row?.isPublished === true &&
      today >= new Date(row?.startDateTime).getTime() &&
      today <= new Date(row?.endDateTime).getTime()
    ) {
      return <Chip label="Published" color={'success' as ColorType} />;
    }

    return <Chip label="Unpublished" color={'default' as ColorType} />;
  };

  const renderDisplayOrder = (row, index) => {
    if (
      row?.isPublished === true &&
      today >= new Date(row?.startDateTime).getTime() &&
      today <= new Date(row?.endDateTime).getTime()
    ) {
      return index < limitedDisplayOrder ? index + 1 : '-';
    }

    return '-';
  };

  const renderAction = (value: string) => {
    if (focusId === value?.id) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                setSelectedId(value?.id);
                toggleModalUpdate();
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          {value.isPublished === true &&
            today <= new Date(value.endDateTime).getTime() && (
              <Tooltips title="Deactivate">
                <IconButton
                  aria-label="details"
                  onClick={() => {
                    setSelectedId(value?.id);
                    toggleModalConfirmDelete();
                  }}
                  size="medium"
                >
                  <Delete style={StyledIcon} color="error" />
                </IconButton>
              </Tooltips>
            )}
          {value.isPublished === false && (
            <Tooltips title="Activate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  onActive(value?.id);
                }}
                size="medium"
              >
                <Active style={StyledIcon} color="success" />
              </IconButton>
            </Tooltips>
          )}
        </StyledAction>
      );
    }
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    const isInValid =
      today <= new Date(dataSource[newIndex]?.startDateTime).getTime() ||
      today >= new Date(dataSource[newIndex]?.endDateTime).getTime() ||
      today <= new Date(dataSource[oldIndex]?.startDateTime).getTime() ||
      today >= new Date(dataSource[oldIndex]?.endDateTime).getTime();
    if (
      params.isPublished_eq !== true ||
      dataSource[oldIndex]?.isPublished !== true ||
      dataSource[newIndex]?.isPublished !== true ||
      isInValid === true
    ) {
      return;
    }
    dataSource[oldIndex].displayOrder = newIndex + 1;
    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        dataSource[i].displayOrder = i + 2;
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        dataSource[i].displayOrder = i;
      }
    }

    setIsChanged(true);

    setDataSource(arrayMoveImmutable(dataSource, oldIndex, newIndex));
  };

  const toggleModalUpdate = () => {
    setIsModalUpdate(!isModalUpdate);
  };

  const GetAppCTALinks = () => {
    new CacheService().get('GetItemFromCache?key=AppCTALinks', {}).then(res => {
      if (res?.data?.data !== null) {
        const appCTALinkOptions = JSON.parse(res?.data?.data);
        appCTALinkOptions.forEach(item => {
          item.label = item.label.replace(/_/g, ' ');
        });
        setRadioOptionsAppCTALink(appCTALinkOptions);
      }
    });
  };

  const renderModalUpdate = useMemo(() => {
    if (selectedId !== null && isModalUpdate) {
      return (
        <ModalUpdate
          id={selectedId}
          visible={isModalUpdate}
          onClose={toggleModalUpdate}
          reloadPage={getMemberDashboard}
          radioOptionsAppCTALink={radioOptionsAppCTALink}
        />
      );
    }
  }, [selectedId, isModalUpdate, getMemberDashboard, toggleModalUpdate]);

  useEffect(() => {
    const isActiveTab =
      params.startDateTime_lte !== null &&
      params.endDateTime_gte !== null &&
      params.isPublished_eq === true &&
      params._size === 99999;
    setIsPagination(!isActiveTab);
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );

    getMemberDashboard();
  }, [params]);

  useEffect(() => {
    GetAppCTALinks();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Member Dashboard" />
      {renderModalUpdate}
      <ModalConfirm
        visible={isModalConfirmDelete}
        iconƯidth="80px"
        iconHeight="100px"
        icon={images.image_deactivate}
        footer={
          <Grid container>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                gap={12}
              >
                <Button
                  variant="contained"
                  onClick={onDelete}
                  width="125px"
                  loading={isLoading}
                >
                  Deactivate
                </Button>
                <Button
                  variant="outlined"
                  onClick={toggleModalConfirmDelete}
                  width="125px"
                  loading={isLoading}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        }
        title={
          <Typography variant="h4">
            <b>Are you sure you want to deactivate this masthead?</b>
          </Typography>
        }
        subTitle={
          <Typography>
            Please note that this change will take effect instantly on App.{' '}
            <br /> <br /> You can always reactivate it, provided there is an
            available slot within the limit of 6 mastheads.
          </Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Member Dashboard
          </Typography>
          <Breadcrumbs
            routes={[
              typeRoutes('Decide which Masthead item to publish', null, true),
            ]}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={!isChanged}
            onClick={onSave}
            margin={'0 10px 0 0'}
            loading={isLoading || loadButtonSave}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        setParams={setParams}
      />
      {isAlert && (
        <Grid container sx={{backgroundColor: '#FFF'}}>
          <Alert
            variant="filled"
            severity="warning"
            sx={{margin: '0 0 0 24px'}}
            icon={<WarningIcon />}
            className="no-print"
          >
            Exceeding 6 published mastheads simultaneously. The maximum live
            items allowed on App is 6. Please adjust accordingly.
          </Alert>
        </Grid>
      )}
      <Table
        dataSource={dataSource}
        isPagination={isPagination}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="No mastheads found."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis="y">
            {dataSource?.map((row, index) => {
              return (
                <Row
                  disabled={
                    !row?.isPublished ||
                    today <= new Date(row?.startDateTime).getTime() ||
                    today >= new Date(row?.endDateTime).getTime() ||
                    params?.isPublished_eq !== true
                  }
                  row={row}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  index={index}
                  rowindex={index}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                  onClick={() => renderAction(row)}
                  renderstatus={() => renderStatus(row)}
                  renderimage={() => renderImage(row)}
                  renderdisplayorder={() => renderDisplayOrder(row, index)}
                />
              );
            })}
          </TableBodySortable>
        )}
      </Table>
    </React.Fragment>
  );
};

export default MemberDashboard;
