import BaseApiService from '../baseApi.service';

export default class IslanderService extends BaseApiService {
  readonly basePath: string = 'merchants/v1';
  constructor(props = null) {
    super(props);
  }

  getMerchantReceipts = async (merchantId: number | string, params: any) => {
    return this.get(`${merchantId}/merchant-receipt`, params);
  };

  getIslanderPartnerAvailable = async () => {
    return this.get('get-filter-data', {
      isAvailableMerchantReceipt_eq: 1,
    }).then(res => res);
  };
}
