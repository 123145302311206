/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {images} from '../../../../constants/image';
import {Modal, Button, Input} from '../../../../components';
import IslanderService from '../../../../services/Islander/islander.service';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNewMerchantReceipt.style';

interface ModalAddNewProps {
  visible: boolean;
  idMerchant?: string | number;
  onClose?: () => void;
  reloadPage?: () => void;
  idMerchantReceipt?: string | number;
  dataDetail?: any;
}

function ModalAddNewMerchantReceipt({
  visible,
  onClose,
  reloadPage,
  idMerchant,
  idMerchantReceipt,
  dataDetail,
}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  // states
  const [isLoading, setIsloading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    receiptAddress: '',
    receiptName: '',
    operatingArea: '',
  });
  const [errorMessages, setErrorMessages] = useState<string | null>(null);

  const onAddNew = values => {
    new IslanderService()
      .post(`${idMerchant}/merchant-receipt`, {
        ...values,
        merchantId: Number(idMerchant),
        status: undefined,
      })
      .then(res => {
        onClose();
        setIsloading(false);
        if (res && res?.success) {
          reloadPage();
          showNotification({
            message: 'Receipt template created successfully!',
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onUpdate = values => {
    new IslanderService()
      .patch(`${idMerchant}/merchant-receipt/${idMerchantReceipt}`, {
        ...values,
        status: 1,
      })
      .then(res => {
        onClose();
        setIsloading(false);
        if (res && res?.success) {
          reloadPage();
          showNotification({
            message: 'Receipt template updated successfully!',
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const verificationFrom = values => {
    if (
      values.receiptAddress === '' &&
      values.receiptName === '' &&
      values.operatingArea === ''
    ) {
      setErrorMessages('Enter information for at least one field.');
      return true;
    }
    setErrorMessages(null);
    return false;
  };

  const onSubmit = values => {
    if (verificationFrom(values) === true) {
      return;
    }

    setIsloading(true);
    if (idMerchantReceipt) {
      onUpdate(values);
    }
    if (!idMerchantReceipt) {
      onAddNew(values);
    }
  };

  const getMerchantReceiptDetail = () => {
    setIsloading(true);
    new IslanderService()
      .getOne(`${idMerchant}/merchant-receipt/${idMerchantReceipt}`)
      .then(res => {
        setIsloading(false);
        if (res) {
          setInitialValues({
            receiptAddress: dataDetail?.receiptAddress,
            receiptName: dataDetail?.receiptName,
            operatingArea: dataDetail?.operatingArea,
          });
        }
      });
  };

  useEffect(() => {
    if (visible && idMerchantReceipt) {
      getMerchantReceiptDetail();
    }
  }, [visible, idMerchantReceipt]);

  useEffect(() => {
    if (!visible) {
      setInitialValues({
        receiptAddress: '',
        receiptName: '',
        status: 1,
        operatingArea: '',
      });
      setErrorMessages(null);
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="sm">
      <>
        <StyledModalTitle>
          {!idMerchantReceipt
            ? 'Create New Receipt Template (for OCR Validation)'
            : 'Edit Receipt Template (for OCR Validation)'}
        </StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({})}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Receipt Template Name{' '}
                  </Typography>
                  <Input
                    type="text"
                    name="receiptName"
                    placeholder="Enter Receipt Template Name"
                    size="small"
                    variant="outlined"
                    value={values.receiptName}
                    onChange={handleChange}
                    helperText={touched.receiptName && errors.receiptName}
                    onBlur={handleBlur}
                    error={Boolean(touched.receiptName && errors.receiptName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Receipt Template Address
                  </Typography>
                  <Input
                    type="text"
                    name="receiptAddress"
                    placeholder="Enter Receipt Template Address"
                    size="small"
                    variant="outlined"
                    value={values.receiptAddress}
                    onChange={handleChange}
                    helperText={touched.receiptAddress && errors.receiptAddress}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.receiptAddress && errors.receiptAddress
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Operating Area
                  </Typography>
                  <Input
                    type="text"
                    name="operatingArea"
                    placeholder="Enter Operating Area"
                    size="small"
                    variant="outlined"
                    value={values.operatingArea}
                    onChange={handleChange}
                    helperText={touched.operatingArea && errors.operatingArea}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.operatingArea && errors.operatingArea
                    )}
                  />
                </FormControl>
                {errorMessages !== null && (
                  <span className="mandatory-label" style={{display: 'block'}}>
                    {errorMessages}
                  </span>
                )}

                <Divider variant="middle" />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button
                      variant="text"
                      type="button"
                      onClick={onClose}
                      loading={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      {!idMerchantReceipt ? 'Add New' : 'Update'}
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNewMerchantReceipt;
