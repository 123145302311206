export const cTALinkTypeOptions = [
  {
    key: 'None',
    value: 0,
  },
  {
    key: 'App',
    value: 1,
  },
  {
    key: 'Web',
    value: 2,
  },
];

export const radioOptions = [
  {
    key: 'Dark',
    value: 1,
  },
  {
    key: 'Light',
    value: 2,
  },
];
