/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseApiService from '../baseApi.service';

export default class ExportExcelService extends BaseApiService {
  constructor(props = null) {
    super(props);
  }

  exportExcelTransactionHistory = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'transactions/exportCSV',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  exportExcelVoucherTransaction = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'membershipTickets/v1/exportCSV/voucherTransactions',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  exportExcelVoucher = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'membershipTickets/v1/exportCSV/voucher',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  exportEmailExcelMemberShip = async (params: object) => {
    return await this.post('reports/v1/request-guest-users', params).then(
      async res => res
    );
  };

  exportEmailExcelSDCAdministrators = async (params: object) => {
    return await this.post('reports/v1/request-admin-users', params).then(
      async res => res
    );
  };

  exportEmailExcelAllTransaction = async (params: object) => {
    return await this.post(
      'reports/v1/request-transaction-history',
      params
    ).then(async res => res);
  };

  exportEmailExcelVoucherTransaction = async (params: object) => {
    return await this.post(
      'reports/v1/request-voucher-transactions',
      params
    ).then(async res => res);
  };

  exportEmailExcelVouchers = async (params: object) => {
    return await this.post('reports/v1/request-vouchers', params).then(
      async res => res
    );
  };

  exportEmailExcelVoucherCategories = async (params: object) => {
    return await this.post('reports/v1/request-voucher-category', params).then(
      async res => res
    );
  };

  exportEmailExcelAuditLogs = async (params: object) => {
    return await this.post('reports/v1/request-audit-logs', params).then(
      async res => res
    );
  };

  exportIslandPartner = async (params: object) => {
    return await this.post('reports/v1/request-islandpartners', params).then(
      async res => res
    );
  };

  exportReferralPartner = async (params: object) => {
    return await this.post('reports/v1/request-referralcampaigns', params).then(
      async res => res
    );
  };

  exportReferralCampaignTransactions = async (params: object) => {
    return await this.post(
      'reports/v1/request-referralcampaigntransactions',
      params
    ).then(async res => res);
  };

  exportReferralCode = async (params: object) => {
    return await this.post(
      'reports/v1/request-referralcampaigncodes',
      params
    ).then(async res => res);
  };

  exportLimitedEvent = async (params: object) => {
    return await this.post('reports/v1/request-limited-events', params).then(
      async res => res
    );
  };

  exportReceiptValidationLog = async (params: object) => {
    return await this.post(
      'reports/v1/request-receipt-validation-log',
      params
    ).then(async res => res);
  };
}
