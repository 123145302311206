import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
} from '@mui/material';

const StyledIconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
`;

const Typography = styled(MuiTypography)(spacing);

const StyledModalTitle = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 900;
`;
const Divider = styled(MuiDivider)`
  ${spacing};
  margin: 10px 0px 12px 0px;
`;
const StyledModalActions = styled(DialogActions)`
  display: block;
  margin: 0;
  margin-top: auto;

  .btn-action {
    width: 113px;
    & .MuiButton-root {
      background-color: #376df0;
      color: #ffffff;
      width: inherit;
    }
    & .Mui-disabled {
      background-color: #f1f1f1;
      color: #6e6e6e;
    }
  }
`;

export {
  StyledIconClose,
  Typography,
  StyledModalTitle,
  StyledModalActions,
  Divider,
};
