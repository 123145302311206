/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {Modal, Button, Input} from '../../../../components';
import {Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {RangePicker, AspectSelectImage} from '../../../../components';
// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';
import ArticleService from '../../../../services/Article/article.service';

interface ModalAddNewProps {
  article?: any;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({
  article,
  visible,
  onClose,
  reloadPage,
}: ModalAddNewProps) {
  const {showNotification} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [articleImages, setArticleImages] = useState<any[]>([]);
  const [isEmptyImage, setEmptyImage] = useState(false);

  // states
  const [initialValues, setInitialValues] = useState({
    title: '',
    path: '',
    url: '',
  });

  const validateForm = () => {
    let errors = 0;

    if (articleImages.length > 0) {
      setEmptyImage(false);
    } else {
      setEmptyImage(true);
      errors = errors + 1;
    }

    return errors === 0;
  };

  const onUpdate = values => {
    if (!validateForm()) {
      return false;
    }
    setIsLoading(true);
    new ArticleService()
      .storeInRedis({
        ...values,
      })
      .then(res => {
        if (res && res?.success) {
          if (
            articleImages.length > 0 &&
            typeof articleImages[0] !== 'string'
          ) {
            new ArticleService()
              .uploadImage(
                res.data.id,
                new File(articleImages, 'articleImages.jpeg', {
                  type: articleImages[0]?.type,
                })
              )
              .then(resImg => {
                if (resImg) {
                  reloadPage();
                  onClose();
                  setIsLoading(false);
                  showNotification({
                    message: success.UPDATE_SUCCESS,
                  });
                }
              });
          } else {
            setIsLoading(false);
            reloadPage();
            onClose();
            showNotification({
              message: success.UPDATE_SUCCESS,
            });
          }
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onSubmit = values => {
    onUpdate(values);
  };

  const onUploadImages = images => {
    setArticleImages(images);
  };

  const handleChangeValues = (value: any, key: string) => {
    setInitialValues({...initialValues, [key]: value});
  };

  useEffect(() => {
    setInitialValues(article);
    setArticleImages([article.image]);
  }, [article]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <StyledModalTitle>Update Article</StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .max(150, 'Max 150 char length')
                .required('Title is required'),
              path: Yup.string(),
              url: Yup.string()
                .matches(/((https):\/\/)+[a-zA-Z0-9-_]/, 'Url is invalid url')
                .required('Url is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Article Title (150 Max Char.){' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Enter Article Title"
                    size="small"
                    variant="outlined"
                    value={values.title}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'title');
                      handleChange(e);
                    }}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Article Item Path
                  </Typography>
                  <Input
                    type="text"
                    name="path"
                    placeholder="Enter Article item Path"
                    size="small"
                    variant="outlined"
                    value={values.path}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'path');
                      handleChange(e);
                    }}
                    helperText={touched.path && errors.path}
                    onBlur={handleBlur}
                    error={Boolean(touched.path && errors.path)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Article URL <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="url"
                    placeholder="Enter Url"
                    size="small"
                    variant="outlined"
                    value={values.url}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'url');
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    helperText={touched.url && errors.url}
                    error={Boolean(touched.url && errors.url)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2} mt={3}>
                    Article Featured Image{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <AspectSelectImage
                    required
                    showError={isEmptyImage}
                    defaultImages={articleImages}
                    setDefaultImages={onUploadImages}
                  />
                </FormControl>
                <Divider variant="middle" pt={8} />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      Update Article
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
