/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Box, Grid, Radio} from '@mui/material';
import {Button, Modal} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  Divider,
  StyledModalActions,
} from './ModalMonthSelection.style';
import {range} from 'lodash';
import MonthCalendar from '../../../../components/MonthCalendar/MonthCalendar';
import styled from 'styled-components';

const checkboxOptions = [
  {value: 1, label: 'Selected'},
  {value: 2, label: 'Unavailable'},
];

const BpIcon = styled('span')(({theme}) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'none',
  backgroundColor: '#f5f8fa',
  cursor: 'default',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#ECECEC',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#376DF0',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    content: '""',
  },
  '&.Mui-checked': {
    cursor: 'default',
    boxShadow: 'none',
  },
});

type monthOptions = {
  month: string;
  cashoutQty: number;
  unCashoutQty: number;
};

interface ModalMonthSelectionProps {
  visible: boolean;
  onClose: () => void;
  cashoutPeriod: monthOptions[];
  onMonthSelected?: (any) => void;
}

function ModalMonthSelection({
  visible,
  onClose,
  cashoutPeriod = [],
  onMonthSelected,
}: ModalMonthSelectionProps) {
  const [disabledCashout, setDisabledCashout] = useState(false);
  const [monSelected, setMonSelected] = useState<string[]>();
  const [monthSelected, setMonthSelected] = useState<string>();
  const [selectionInfo, setSelectionInfo] = useState<monthOptions>();
  const onConfirm = () => {
    if (onMonthSelected) {
      handleClose();
      onMonthSelected(monSelected);
    }
  };

  const onSetMonthSelected = (monthDetail: monthOptions, year: number) => {
    if (monthDetail.month === monthSelected) {
      setMonthSelected(undefined);
      setMonSelected([]);
      setSelectionInfo(undefined);
      return;
    }
    setMonthSelected(monthDetail.month);
    setMonSelected([`${monthDetail.month}`.toUpperCase()]);
    setSelectionInfo(monthDetail);
    setDisabledCashout(monthDetail.unCashoutQty === 0);
  };

  const handleClean = () => {
    setMonthSelected(undefined);
    setMonSelected([]);
    setSelectionInfo(undefined);
    setDisabledCashout(true);
  };

  const handleClose = () => {
    onClose();
  };

  const getCurrentMonthValue = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setFullYear(date.getFullYear());
    return date;
  };

  const getMinMonthValue = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 2);
    return date;
  };

  const getYearRange = () => {
    const minYear = getMinMonthValue().getFullYear();
    const maxYear = getCurrentMonthValue().getFullYear() + 1;
    return range(minYear, maxYear);
  };

  const renderInfomations = (info: monthOptions) => {
    return (
      <div style={{marginTop: '20px'}}>
        <Typography
          color="#333333"
          fontWeight={600}
          fontSize={14}
          paddingBottom={1}
          sx={{cursor: 'default'}}
        >
          <img
            src={images.icon_quantity}
            width={16}
            height={16}
            style={{
              display: 'inline',
              verticalAlign: 'middle',
              marginRight: '4px',
            }}
          />
          {` ${info.cashoutQty + info.unCashoutQty} transaction(s)`}
        </Typography>
        <Typography
          color="#545E75"
          fontSize={14}
          paddingBottom={1}
          paddingLeft="24px"
        >{`${info.cashoutQty} reimbursed`}</Typography>
        <Typography
          color="#DB252F"
          fontSize={14}
          paddingLeft="24px"
          paddingBottom={1}
        >{`${info.unCashoutQty} not reimbursed yet`}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (visible) {
      handleClean();
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={handleClose} maxWidth="md">
      <Box padding="60px 12px 36px 12px">
        <StyledIconClose onClick={handleClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <DialogContent>
          <Grid container flex="flex" justifyContent="space-between">
            <Grid item xs={5}>
              <Typography
                variant="h6"
                fontSize={14}
                color="#003349"
                fontWeight="bolder"
                paddingLeft={4}
              >
                Choose a month
              </Typography>
              {getYearRange().map((year, index) => {
                return (
                  <MonthCalendar
                    onChange={onSetMonthSelected}
                    monthSelected={monthSelected}
                    year={year}
                    key={index}
                    cashoutPeriod={cashoutPeriod.filter(f =>
                      f.month.includes(year.toString())
                    )}
                  />
                );
              })}
              <Divider variant="middle" color="#003349" />
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {checkboxOptions.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Radio
                        sx={{
                          cursor: 'default',
                          ':hover': {
                            background: 'none',
                          },
                        }}
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        name="availabledOptions"
                        checked={item.value === 1 ? true : false}
                        disabled={item.value === 1 ? false : true}
                        onChange={() => {}}
                        inputProps={{'aria-label': 'controlled'}}
                      />
                      <Typography
                        display="inline"
                        sx={{cursor: 'default', verticalAlign: 'middle'}}
                        color="#003349"
                        fontSize={16}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={5} display="flex" flexDirection="column">
              <Typography
                variant="h6"
                fontSize={14}
                color="#003349"
                fontWeight="bolder"
              >
                Reimbursement breakdown
              </Typography>
              {selectionInfo !== undefined ? (
                renderInfomations(selectionInfo)
              ) : (
                <Typography
                  fontSize={14}
                  color="#545E75"
                  padding={'20px 24px'}
                  marginTop={5}
                  borderRadius="16px"
                  sx={{background: '#F7F7F7'}}
                >
                  Select a month to view transaction(s) to reimburse.
                </Typography>
              )}
              <StyledModalActions>
                <div className={`btn-action`}>
                  <Button
                    type="submit"
                    onClick={onConfirm}
                    disabled={disabledCashout}
                    aria-hidden={false}
                  >
                    Reimburse
                  </Button>
                </div>
              </StyledModalActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Modal>
  );
}
export default ModalMonthSelection;
