import styled from 'styled-components/macro';

import {spacing} from '@mui/system';
import {
  Card as MuiCard,
  Typography as MuiTypography,
  Divider as MuiDivider,
  CardContent,
} from '@mui/material';

const Typography = styled(MuiTypography)(spacing);
const StyledDivBreadcrumbs = styled.div`
  margin-top: 10px;
`;
const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Divider = styled(MuiDivider)(spacing);

const StyledCardContent = styled(CardContent)`
  position: relative;
  z-index: 0;
`;

const StyledChip = styled.div`
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
  margin-top: 11px;
`;

const ReceiptImage = styled.img`
  margin: -17px 0;
  width: 100px;
  height: 50px;
`;

export {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledChip,
  StyledCardContent,
  ReceiptImage,
};
