/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import VoucherCategoriesService from '../../../services/Voucher/voucher-category.service';

import {
  Breadcrumbs,
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
  Checkbox,
} from '../../../components';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
  LinkElementComponent,
} from './AddNew.style';
import {typeRoutes} from '../../../types/typeAllTransaction';
import MobileAssetsService from '../../../services/MobileAssets/mobile-assets.service';

const radioOptions = [
  {
    key: 'AP’s Featured Voucher',
    value: 1,
  },
  {
    key: 'Vouchers to be tagged/already tagged with this category',
    value: 2,
  },
];

interface DataDetail {
  title?: string;
  description?: string;
  iconActive?: string;
  iconInactive?: string;
  associatedSitecoreShopCategory?: string;
  loyaltyVoucherDataSourceMobileGuestMode?: number;
  loyaltyVoucherDataSourceMobileLoggedIn?: number;
  showOnMobileDashboardGuestMode?: boolean;
  showOnMobileDashboardLoggedIn?: boolean;
  showOnMobileVoucherListing?: boolean;
}

function AddNew() {
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);
  const [shopCategories, setShopCategories] = useState<any[]>([]);
  const [icons, setIcons] = useState<any[]>([]);
  const [isErrorRequiredDisplayOn, setIsErrorRequiredDisplayOn] =
    useState(false);
  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    iconActive: '',
    iconInactive: '',
    associatedSitecoreShopCategory: '',
    loyaltyVoucherDataSourceMobileGuestMode: 1,
    loyaltyVoucherDataSourceMobileLoggedIn: 1,
    showOnMobileDashboardGuestMode: false,
    showOnMobileDashboardLoggedIn: false,
    showOnMobileVoucherListing: false,
  });

  const onSubmit = values => {
    new VoucherCategoriesService()
      .create({
        ...values,
        associatedSitecoreShopCategory: shopCategories.find(
          item => item?.value === initialValues.associatedSitecoreShopCategory
        )?.id,
        iconActive: icons.find(
          item => item?.value === initialValues?.iconActive
        )?.image,
        iconInactive: icons.find(
          item => item?.value === initialValues?.iconInactive
        )?.image,
        isDisplayOn: undefined,
        loyaltyVoucherDataSourceMobileGuestMode:
          initialValues?.showOnMobileDashboardGuestMode === true
            ? initialValues.loyaltyVoucherDataSourceMobileGuestMode
            : undefined,
        loyaltyVoucherDataSourceMobileLoggedIn:
          initialValues?.showOnMobileDashboardLoggedIn === true
            ? initialValues.loyaltyVoucherDataSourceMobileLoggedIn
            : undefined,
      })

      .then(res => {
        if (res?.success) {
          showNotification({
            message: `Category created successfully!`,
            linkElement: (
              <>
                <br />
                <LinkElementComponent to={'/voucher'}>
                  Proceed to tag vouchers to category
                </LinkElementComponent>
              </>
            ),
          });
          onClear();
          setLoading(false);
        } else {
          showNotification({
            message: 'Update failure',
            variation: 'error',
          });
          setLoading(false);
        }
      });
  };

  const getIconAssets = () => {
    new MobileAssetsService()
      .GetAllMobileAsset({_page: 0, _size: 99999})
      .then(res => {
        let listIcon: any[] = [
          {
            value: 99999,
            label: '--',
            image: null,
            width: 48,
            height: 32,
          },
        ];
        res?.data?.items?.map((item, index) => {
          const isSvgFile = item.fileName.split('.')[1] === 'svg';
          if (isSvgFile) {
            listIcon = [
              ...listIcon,
              {
                value: index,
                label: item?.fileName,
                image: item?.uRL,
                width: 48,
                height: 32,
              },
            ];
          }
        });
        setIcons(listIcon);
      });
  };

  const onClear = () => {
    setInitialValues({
      title: '',
      description: '',
      iconActive: '',
      iconInactive: '',
      associatedSitecoreShopCategory: '',
      loyaltyVoucherDataSourceMobileGuestMode: 1,
      loyaltyVoucherDataSourceMobileLoggedIn: 1,
      showOnMobileDashboardGuestMode: false,
      showOnMobileDashboardLoggedIn: false,
      showOnMobileVoucherListing: false,
    });
  };

  const GetCategories = () => {
    new VoucherCategoriesService().getSirecoreCategory().then(res => {
      if (res.status === 200) {
        const newCategories: any[] = res?.data?.data?.map((item, index) => ({
          value: index + 1,
          label: item?.label,
          id: item?.id,
        }));
        setShopCategories([
          {
            value: 0,
            label: '---',
            id: null,
          },
          ...newCategories,
        ]);
      }
    });
  };

  useEffect(() => {
    GetCategories();
    getIconAssets();
  }, []);

  return (
    <>
      <Helmet title="Voucher Categories" />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Voucher Categories
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[typeRoutes('Add a new category', undefined, true)]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Grid container mb="30px">
          <Grid item xs={12} lg={12}>
            <Shadow>
              <Formik
                initialValues={{
                  ...initialValues,
                  isDisplayOn:
                    initialValues.showOnMobileDashboardGuestMode ||
                    initialValues.showOnMobileDashboardLoggedIn ||
                    initialValues.showOnMobileVoucherListing,
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .required('Category Title is required')
                    .max(24, 'Please input only maximum of 24 characters'),
                  isDisplayOn: Yup.boolean().oneOf(
                    [true],
                    'Please select at least 1'
                  ),
                })}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Card py={12} px={12}>
                      <CardContent sx={{padding: 0}}>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={10}
                          mb="50px"
                        >
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="subtitle2" mb={2}>
                                Category Title{' '}
                                <span className="mandatory-label">(*)</span>
                                <Typography variant="body2" fontStyle="italic">
                                  Maximum 24 characters including spaces
                                </Typography>
                              </Typography>
                              <Input
                                placeholder="Enter Title"
                                size="small"
                                name="title"
                                value={values.title}
                                variant="outlined"
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.title && errors.title)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="subtitle2" mb={2}>
                                Description
                              </Typography>
                              <TextArea
                                label=""
                                placeholder="Enter Description"
                                maxRows={2}
                                name="description"
                                value={values.description}
                                helperText={
                                  touched.description && errors.description
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                Icon asset – Inactive
                              </Typography>
                              <Select
                                labelId="iconInactive"
                                name="iconInactive"
                                size="small"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select Image"
                                onChangeSelect={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  touched.iconInactive && errors.iconInactive
                                }
                                error={Boolean(
                                  touched.iconInactive && errors.iconInactive
                                )}
                                value={values?.iconInactive}
                                selectOptions={icons}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                Icon asset – Active
                              </Typography>
                              <Select
                                labelId="iconActive"
                                name="iconActive"
                                size="small"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select Image"
                                onChangeSelect={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  touched.iconActive && errors.iconActive
                                }
                                error={Boolean(
                                  touched.iconActive && errors.iconActive
                                )}
                                value={values?.iconActive}
                                selectOptions={icons}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                Link to Sitecore’s Shop category
                              </Typography>
                              <Select
                                labelId="associatedSitecoreShopCategory"
                                name="associatedSitecoreShopCategory"
                                size="small"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select category (if Sitecore category is attached, it will be shown as a category item)"
                                onChangeSelect={e => {
                                  handleChange(e);
                                  const newInitialValues = initialValues;
                                  newInitialValues.associatedSitecoreShopCategory =
                                    e.target.value;
                                  setInitialValues(newInitialValues);
                                }}
                                onBlur={handleBlur}
                                helperText={
                                  touched.associatedSitecoreShopCategory &&
                                  errors.associatedSitecoreShopCategory
                                }
                                error={Boolean(
                                  touched.associatedSitecoreShopCategory &&
                                    errors.associatedSitecoreShopCategory
                                )}
                                value={values.associatedSitecoreShopCategory}
                                selectOptions={shopCategories}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="subtitle2" mb={2}>
                                Display on?{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Grid container>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'center',
                                  }}
                                  mr={2}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      alignItems: 'center',
                                    }}
                                    mr={2}
                                  >
                                    <FormControlLabel
                                      sx={{paddingLeft: '8px'}}
                                      required
                                      control={
                                        <Checkbox
                                          sx={{
                                            paddingLeft: 0,
                                          }}
                                          checked={
                                            values.showOnMobileDashboardLoggedIn
                                          }
                                          name="showOnMobileDashboardLoggedIn"
                                          value={
                                            values.showOnMobileDashboardLoggedIn
                                          }
                                          onChange={e => {
                                            handleChange(e);
                                            setInitialValues({
                                              ...values,
                                              showOnMobileDashboardLoggedIn:
                                                e.target.checked,
                                            });
                                            setFieldValue(
                                              'isDisplayOn',
                                              e.target.checked ||
                                                values.showOnMobileVoucherListing ||
                                                values.showOnMobileDashboardGuestMode
                                            );
                                          }}
                                          inputProps={{
                                            'aria-label': 'controlled',
                                          }}
                                        />
                                      }
                                      label="App Dashboard’s Discovery section (Logged In mode)"
                                    />
                                  </Grid>
                                  <FormControlLabel
                                    sx={{paddingLeft: '8px'}}
                                    required
                                    control={
                                      <Checkbox
                                        sx={{
                                          paddingLeft: 0,
                                        }}
                                        checked={
                                          values.showOnMobileDashboardGuestMode
                                        }
                                        name="showOnMobileDashboardGuestMode"
                                        value={
                                          values.showOnMobileDashboardGuestMode
                                        }
                                        onChange={e => {
                                          handleChange(e);
                                          setInitialValues({
                                            ...values,
                                            showOnMobileDashboardGuestMode:
                                              e.target.checked,
                                          });
                                          setFieldValue(
                                            'isDisplayOn',
                                            e.target.checked ||
                                              values.showOnMobileDashboardLoggedIn ||
                                              values.showOnMobileVoucherListing
                                          );
                                        }}
                                        inputProps={{
                                          'aria-label': 'controlled',
                                        }}
                                      />
                                    }
                                    label="App Dashboard’s Discovery section (Guest mode)"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'center',
                                  }}
                                >
                                  <FormControlLabel
                                    required
                                    control={
                                      <Checkbox
                                        sx={{
                                          paddingLeft: 0,
                                        }}
                                        checked={
                                          values.showOnMobileVoucherListing
                                        }
                                        name="showOnMobileVoucherListing"
                                        value={
                                          values.showOnMobileVoucherListing
                                        }
                                        onChange={e => {
                                          handleChange(e);
                                          setInitialValues({
                                            ...values,
                                            showOnMobileVoucherListing:
                                              e.target.checked,
                                          });
                                          setFieldValue(
                                            'isDisplayOn',
                                            e.target.checked ||
                                              values.showOnMobileDashboardLoggedIn ||
                                              values.showOnMobileDashboardGuestMode
                                          );
                                        }}
                                        inputProps={{
                                          'aria-label': 'controlled',
                                        }}
                                      />
                                    }
                                    label="App Voucher’s Listing filter"
                                  />
                                </Grid>
                              </Grid>
                              {Boolean(
                                touched.isDisplayOn && errors.isDisplayOn
                              ) && (
                                <FormHelperText
                                  error
                                  disabled={true}
                                  sx={{margin: '4px 14px 0px'}}
                                >
                                  {touched.isDisplayOn && errors.isDisplayOn}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {values.showOnMobileDashboardGuestMode && (
                            <Grid item xs={12}>
                              <FormControl variant="standard" fullWidth>
                                <Typography variant="subtitle2" mb={2} mt={3}>
                                  For Guest Mode, data content is based on?{' '}
                                  <Typography
                                    variant="body2"
                                    sx={{display: 'inline-block'}}
                                  >
                                    [Select one]
                                  </Typography>{' '}
                                  <span className="mandatory-label">(*)</span>
                                </Typography>
                                <RadioGroups
                                  name="loyaltyVoucherDataSourceMobileGuestMode"
                                  options={radioOptions}
                                  values={
                                    initialValues.loyaltyVoucherDataSourceMobileGuestMode
                                  }
                                  onChange={e => {
                                    handleChange(e);
                                    const newInitialValues = initialValues;
                                    newInitialValues.loyaltyVoucherDataSourceMobileGuestMode =
                                      e.target.value;
                                    setInitialValues(newInitialValues);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {values.showOnMobileDashboardLoggedIn && (
                            <Grid item xs={12}>
                              <FormControl variant="standard" fullWidth>
                                <Typography variant="subtitle2" mb={2} mt={3}>
                                  For Logged In mode, data content is based on?{' '}
                                  <Typography
                                    variant="body2"
                                    sx={{display: 'inline-block'}}
                                  >
                                    [Select one]
                                  </Typography>{' '}
                                  <span className="mandatory-label">(*)</span>
                                </Typography>
                                <RadioGroups
                                  name="loyaltyVoucherDataSourceMobileLoggedIn"
                                  options={radioOptions}
                                  values={
                                    initialValues.loyaltyVoucherDataSourceMobileLoggedIn
                                  }
                                  onChange={e => {
                                    handleChange(e);
                                    const newInitialValues = initialValues;
                                    newInitialValues.loyaltyVoucherDataSourceMobileLoggedIn =
                                      e.target.value;
                                    setInitialValues(newInitialValues);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                      <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="submit" loading={loading}>
                          Create Category
                        </Button>
                      </Grid>
                    </Card>
                  </form>
                )}
              </Formik>
            </Shadow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AddNew;
