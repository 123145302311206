import React from 'react';
import {matchPath} from 'react-router-dom';

import {SidebarItemsType} from '../../types/typeSidebar';
import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  currentRoute: string;
};

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const {items, page, depth, currentRoute} = props;

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        subPage={page.subPage}
        isDropdown={page.isDropdown}
        tierChildren={page.tierChildren}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        isDropdown={page.isDropdown}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
