import {Box, Grid, Typography} from '@mui/material';
import React from 'react';
import {IsNullOrEmptyOrUndefined} from '../../../utils/helpers';

type Rows = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

interface TableDetailProps {
  title?: string;
  rowsA: Rows[];
  rowsB?: Rows[];
}

const TableDetail = ({title, rowsA, rowsB}: TableDetailProps) => {
  const renderCaption = (value: string, icon?: React.ReactNode) => {
    if (value) {
      const iconElement = icon ? icon : '';
      return (
        <Typography
          color="rgba(0, 0, 0, 0.5)"
          mb="31px"
          variant="h6"
          sx={{img: {verticalAlign: 'top', cursor: 'pointer'}}}
        >
          {value}
          {iconElement ? <> {iconElement}:</> : ':'}
        </Typography>
      );
    }
    return null;
  };

  const renderSubtitle = value => {
    return (
      <Typography
        mb="31px"
        variant="h6"
        sx={{'*': {margin: '0', padding: 0}}}
        dangerouslySetInnerHTML={{
          __html: value ? value : '--',
        }}
      />
    );
  };

  const renderRowColumns = (rows?: Rows[]) => {
    if (IsNullOrEmptyOrUndefined(rows)) {
      return '';
    }

    return rows?.map(item => {
      return (
        <tr>
          <td width="44%" style={{verticalAlign: 'top'}}>
            {renderCaption(item.label, item.icon)}
          </td>
          <td width="54%" style={{verticalAlign: 'top'}}>
            {renderSubtitle(item.value)}
          </td>
        </tr>
      );
    });
  };

  return (
    <Box sx={{padding: '20px 28px 0px 28px'}}>
      <Typography
        variant="h1"
        fontSize={30}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        paddingBottom={8}
      />
      <Grid
        wrap="nowrap"
        container
        justifyContent="space-between"
        paddingLeft={2}
        gap={5}
      >
        <table width="50%" style={{height: 'fit-content'}}>
          <tbody>{renderRowColumns(rowsA)}</tbody>
        </table>
        {!IsNullOrEmptyOrUndefined(rowsB) && (
          <table width="45%" style={{height: 'fit-content'}}>
            <tbody>{renderRowColumns(rowsB)}</tbody>
          </table>
        )}
      </Grid>
    </Box>
  );
};

export default TableDetail;
