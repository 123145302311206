/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import {DialogContent, Grid, Typography} from '@mui/material';
import {images} from '../../../constants/image';
import {Modal} from '../../../components';

interface ModalInformationsProps {
  visible: boolean;
  onClose?: () => void;
  title: string | ReactNode;
  description: string | ReactNode;
  icon?: string | undefined;
  iconWidth?: number | undefined;
  iconHeight?: number | undefined;
}

function ModalInformations({
  visible,
  onClose,
  title,
  description,
  icon,
  iconWidth,
  iconHeight,
}: ModalInformationsProps) {
  return (
    <Modal visible={visible} onClose={onClose} maxWidth="sm">
      <>
        <img
          onClick={onClose}
          src={images.icon_close}
          style={{margin: '8px 8px 8px auto', cursor: 'pointer'}}
          width={24}
          height={24}
        />
        <DialogContent>
          <Grid container justifyContent="space-around">
            <Grid item mt={5} xs={12} md={12} textAlign="center">
              <img
                src={icon ? icon : images.icon_info_cicle}
                width={iconWidth}
                height={iconHeight}
              />
            </Grid>
            <Grid item mt={3} xs={12} md={12} textAlign="center">
              <Typography fontWeight={'bolder'} variant="h3">
                {title}
              </Typography>
            </Grid>
            <Grid item mt={5} mb={10} xs={12} md={12} textAlign="center">
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalInformations;
