/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, useMemo, useRef} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Box, CardContent, Checkbox, FormControl, Grid} from '@mui/material';
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import {stateToHTML} from 'draft-js-export-html';
import {
  convertFromHTML,
  ContentState,
  ContentBlock,
  convertToRaw,
  EditorState,
} from 'draft-js';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';

import AllModal from './VoucherDetail.method';
import {path} from '../../../routes/path';
import {images} from '../../../constants/image';
import useAuth from '../../../hooks/useAuth';
import success from '../../../constants/success';
import {DataDetail, typeRoutes} from '../../../types/typeVoucher';
import VoucherService from '../../../services/Voucher/voucher.service';
import IslanderService from '../../../services/Islander/islander.service';
import {
  Breadcrumbs,
  DateTimePicker,
  Input,
  TextArea,
  FooterDetail,
  RadioGroups,
  Stats,
  MultipleSelectCheckbox,
  Tooltips,
  AspectSelectImage,
} from '../../../components';
import queryString from 'query-string';
import {formatDateTime} from '../../../utils';
import VoucherCategoryService from '../../../services/Voucher/voucher-category.service';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './VoucherDetail.style';

const radioOptions = [
  {
    key: 'Single',
    value: 1,
  },
  {
    key: 'Unlimited',
    value: 2,
  },
];

interface DateTimeRange {
  startOfDateTime?: Date | null;
  endOfDateTime?: Date | null;
}

function VoucherDetail() {
  const paramsUrl = useParams();
  const location = useLocation();
  const idDetail = paramsUrl?.id;
  const {showNotification} = useAuth();
  const navigate = useNavigate();
  const formatTime = 'DD/MM/YYYY HH:mm';

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    isModalConfirm: false,
    isModalConfirmDelete: false,
    isModalSuccess: false,
    isModalSuccessDelete: false,
    isModalEditTier: false,
    isModalEditPoints: false,
  });

  const [optionIslandPartners, setOptionIslandPartners] = useState([]);
  const [islandPartners, setIslandPartners] = useState([]);
  const [optionVoucherCategory, setOptionVoucherCategory] = useState([]);
  const [voucherCategories, setVoucherCategories] = useState([]);
  const [listMerchantId, setListMerchantId] = useState([]);
  const [listTicketCategoryIds, setListTicketCategoryIds] = useState([]);
  const [valueMultipleSelectCheckbox, setValueMultipleSelectCheckbox] =
    useState([]);
  const [categorySelectCheckbox, setCategorySelectCheckbox] = useState([]);
  const [isEmptyDate, setIsEmptyDate] = useState(false);
  const selectIPPlaceholder = useMemo(() => {
    return Array.isArray(valueMultipleSelectCheckbox) &&
      valueMultipleSelectCheckbox.length > 0
      ? 'Select IP'
      : 'All IP';
  }, [valueMultipleSelectCheckbox]);
  const selectCategoryPlaceholder = useMemo(() => {
    return Array.isArray(categorySelectCheckbox) &&
      categorySelectCheckbox.length > 0
      ? 'Select Categories'
      : 'All';
  }, [categorySelectCheckbox]);

  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startOfDateTime: null,
    endOfDateTime: null,
  });

  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    excerpt: '',
    image: '',
    usageType: 1,
    cost: 0,
    quantity: 0,
    unlimitedQuantity: false,
    amountOffset: 0,
    onlinePurchaseURL: '',
    type: 1,
    usageCount: 0,
    redemptionCount: 0,
    priority: false,
    orderFeaturedTicket: null,
    isExclusive: 0,
  });

  const [disabledQty, setDisabledQty] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [voucherImages, setVoucherImages] = useState([]);

  const onAddNew = values => {
    if (
      valueRangePicker.startOfDateTime !== null &&
      valueRangePicker.endOfDateTime !== null
    ) {
      setIsEmptyDate(false);
      new VoucherService()
        .create({
          ...values,
          merchantIds: islandPartners,
        })
        .then(res => {
          if (res && res?.success) {
            if (voucherImages.length > 0) {
              new VoucherService()
                .uploadImage(
                  res.data.id,
                  new File(voucherImages, 'voucherImage.jpeg', {
                    type: voucherImages[0]?.type,
                  })
                )
                .then(resImg => {
                  if (resImg) {
                    setIsLoading(false);
                    navigate(`/${path.VOUCHER}`);
                    showNotification({
                      message: success.CREATE_SUCCESS,
                    });
                  }
                });
            } else {
              setIsLoading(false);
              navigate(`/${path.VOUCHER}`);
              showNotification({
                message: success.CREATE_SUCCESS,
              });
            }
          } else {
            setIsLoading(false);
            showNotification({
              message: res.errorMessage,
              variation: 'error',
            });
          }
        });
    } else {
      setIsEmptyDate(true);
    }
  };

  const onUpdate = values => {
    new VoucherService()
      .update(idDetail, {
        ...values,
        merchantIds: islandPartners,
        ticketCategoryIds: voucherCategories,
        image: voucherImages.length > 0 ? undefined : '',
      })
      .then(res => {
        if (res && res?.success) {
          if (
            voucherImages.length > 0 &&
            typeof voucherImages[0] !== 'string'
          ) {
            new VoucherService()
              .uploadImage(
                res.data.id,
                new File(voucherImages, 'voucherImage.jpeg', {
                  type: voucherImages[0]?.type,
                })
              )
              .then(resImg => {
                if (resImg) {
                  getDataDetail();
                  setIsLoading(false);
                  showNotification({
                    message: success.UPDATE_SUCCESS,
                  });
                }
              });
          } else {
            getDataDetail();
            setIsLoading(false);
            showNotification({
              message: success.UPDATE_SUCCESS,
            });
          }
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onSubmit = values => {
    setIsLoading(true);
    if (idDetail) {
      onUpdate({
        ...values,
        expireStartDateTime: formatDateTime(valueRangePicker.startOfDateTime),
        expireEndDateTime: formatDateTime(valueRangePicker.endOfDateTime),
      });
    } else {
      onAddNew({
        ...values,
        expireStartDateTime: formatDateTime(valueRangePicker.startOfDateTime),
        expireEndDateTime: formatDateTime(valueRangePicker.endOfDateTime),
      });
    }
  };

  const toggleModalConfirm = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirm: !modal.isModalConfirm,
    }));
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalSuccess = () => {
    toggleModalConfirm();
    setModal(preState => ({
      ...preState,
      isModalSuccess: !modal.isModalSuccess,
    }));
  };

  const toggleModalSuccessDelete = () => {
    setIsLoading(true);
    new VoucherService().softDelete(idDetail).then(res => {
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirmDelete();
        setModal(preState => ({
          ...preState,
          isModalSuccessDelete: !modal.isModalSuccessDelete,
        }));
      }
    });
  };

  const getDataDetail = () => {
    if (idDetail) {
      setIsLoading(true);
      new VoucherService().getOne(idDetail).then(res => {
        if (res && res.data && res.data.data) {
          const data = res?.data?.data;
          setListMerchantId(data?.merchantIds);
          setIslandPartners(data?.merchantIds);
          setIsLoading(false);
          setValueRangePicker({
            startOfDateTime: data?.expireStartDateTime,
            endOfDateTime: data?.expireEndDateTime,
          });
          setVoucherCategories(data?.ticketCategories.map(item => item.id));
          setListTicketCategoryIds(
            data?.ticketCategories.reduce((items, item) => {
              items.push(item.id);
              return items;
            }, [])
          );
          setInitialValues({
            ...initialValues,
            title: data?.title,
            usageType: data?.usageType,
            description: data?.description,
            excerpt: data?.excerpt,
            image: data?.image,
            cost: data?.cost,
            amountOffset: data?.amountOffset,
            onlinePurchaseURL: data?.onlinePurchaseURL || '',
            quantity: data?.quantity,
            unlimitedQuantity: data?.unlimitedQuantity,
            usageCount: data?.usageCount,
            redemptionCount: data?.redemptionCount,
            priority: data?.priority,
            orderFeaturedTicket: data?.orderFeaturedTicket,
            isExclusive: data?.isExclusive ? 1 : 0,
          });
          if (data?.image !== '') {
            setVoucherImages([data?.image]);
          }
          const contentHTML = convertFromHTML(data?.description);
          const state = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
          );
          setDefaultValue(JSON.stringify(convertToRaw(state)));
        }
      });
    }
  };

  const viewSourceHtmlEditor = (data: string) => {
    let contentString = '';
    JSON.parse(defaultValue).blocks.map(
      item => (contentString = contentString + item.text)
    );
    if (contentString.search('</') !== -1) {
      const contentHTML = convertFromHTML(data);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      setDefaultValue(JSON.stringify(convertToRaw(state)));
    } else {
      const state = ContentState.createFromText(data);
      setDefaultValue(JSON.stringify(convertToRaw(state)));
    }
  };

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService()
      .getAll({
        _page: 0,
        _size: 2147483647,
        _sort: 'name:asc',
        status_eq: 1,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionIslandPartners(newData);
          setIsLoading(false);
        }
      });
  };

  const getVoucherCategory = () => {
    setIsLoading(true);
    new VoucherCategoryService()
      .getAll({_size: 2147483647, status_eq: 1})
      .then(res => {
        if (res?.data && Array.isArray(res?.data?.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            const titleOption = item.title
              ? `${item.title} ${
                  item?.events?.length > 0
                    ? `[${item?.events.map(e => e.title).join(', ')}]`
                    : ''
                }`
              : '-';
            items.push({
              title: titleOption,
              value: item.id,
            });
            return items;
          }, []);
          setOptionVoucherCategory(newData);
          setIsLoading(false);
        }
      });
  };

  // const onFormatValue = () => {
  //   const contentHTML = convertFromHTML(initialValues.description);
  //   const state = ContentState.createFromBlockArray(
  //     contentHTML.contentBlocks,
  //     contentHTML.entityMap
  //   );
  //   return JSON.stringify(convertToRaw(state));
  // };

  useEffect(() => {
    setDisabledQty(initialValues.unlimitedQuantity);
  }, [initialValues.unlimitedQuantity]);

  useEffect(() => {
    getDataDetail();
    getIslanderPartners();
    getVoucherCategory();
    setVoucherImages([]);
    // onFormatValue();
  }, []);

  useEffect(() => {
    if (listMerchantId) {
      const newDataMerchantIds = optionIslandPartners?.filter(item => {
        return listMerchantId?.includes(item.value);
      });
      setValueMultipleSelectCheckbox(newDataMerchantIds);
    }
    if (listTicketCategoryIds) {
      const newCategoryIds = optionVoucherCategory?.filter(item => {
        return listTicketCategoryIds?.includes(item.value);
      });
      setCategorySelectCheckbox(newCategoryIds);
    }
  }, [
    listMerchantId,
    optionIslandPartners,
    listTicketCategoryIds,
    optionVoucherCategory,
  ]);

  return (
    <>
      <Helmet title="Edit Administrator Detail" />

      <AllModal
        isModalConfirm={modal.isModalConfirm}
        toggleModalConfirm={toggleModalConfirm}
        isModalConfirmDelete={modal.isModalConfirmDelete}
        isModalSuccessDelete={modal.isModalSuccessDelete}
        toggleModalConfirmDelete={toggleModalConfirmDelete}
        toggleModalSuccessDelete={toggleModalSuccessDelete}
        toggleModalSuccess={toggleModalSuccess}
        isModalSuccess={modal.isModalSuccess}
        setModal={setModal}
      />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          {idDetail ? 'Voucher Detail' : 'Add New Voucher'}
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Voucher',
                `/${path.VOUCHER}?${queryString.stringify(location?.state)}`
              ),
              typeRoutes('Detail', undefined, true),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Grid container spacing={6} mb={4}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Stats
              title="Use Voucher Count"
              amount={initialValues.usageCount}
              icon={images.icon_points}
              isManage={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Stats
              title="Get Voucher Count"
              amount={initialValues.redemptionCount}
              icon={images.icon_transactional}
              isManage={false}
            />
          </Grid>
        </Grid>

        <Grid container mb="30px">
          <Grid item xs={12} lg={12}>
            <Shadow>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .required('Voucher Title is required')
                    .max(40),
                  excerpt: Yup.string()
                    .required('Voucher Short Description is required')
                    .max(85),
                  onlinePurchaseURL: Yup.string()
                    .matches(
                      /(https:\/\/|http:\/\/|mailto:)+[a-zA-Z0-9-_]/,
                      'URL is invalid'
                    )
                    .notRequired(),
                  description: Yup.string().required('T&Cs is required'),
                  quantity: Yup.number()
                    .integer()
                    .moreThan(
                      initialValues.unlimitedQuantity ? -2 : -1,
                      'Quantity must be greater than or equal to 0'
                    ),
                })}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Card px={6} pt={6}>
                      <CardContent>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={10}
                          mb="50px"
                        >
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Voucher Title (40 Max Char.){' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Input
                                placeholder="Enter Voucher Title"
                                size="small"
                                name="title"
                                value={values.title}
                                variant="outlined"
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.title && errors.title)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Voucher Short Description (85 Max Char.){' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <TextArea
                                label=""
                                placeholder="Enter Voucher Short Description"
                                maxRows={2}
                                name="excerpt"
                                value={values.excerpt}
                                helperText={touched.excerpt && errors.excerpt}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.excerpt && errors.excerpt
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Points Required
                              </Typography>
                              <Input
                                placeholder="Enter Points"
                                size="small"
                                name="cost"
                                type="number"
                                value={values.cost}
                                variant="outlined"
                                helperText={touched.cost && errors.cost}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.cost && errors.cost)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Voucher Cash Value
                              </Typography>
                              <Input
                                placeholder="Enter Amount Used"
                                size="small"
                                name="amountOffset"
                                type="number"
                                value={values.amountOffset}
                                variant="outlined"
                                helperText={
                                  touched.amountOffset && errors.amountOffset
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.amountOffset && errors.amountOffset
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Qty
                              </Typography>
                              <Input
                                placeholder="Enter Usable Quantity"
                                size="small"
                                name="quantity"
                                type="number"
                                value={values.quantity}
                                variant="outlined"
                                helperText={touched.quantity && errors.quantity}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.quantity && errors.quantity
                                )}
                                disabled={disabledQty}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={1.5}>
                            <FormControl fullWidth margin="normal">
                              <div>
                                <Typography variant="body2">
                                  Unlimited Qty
                                </Typography>
                                <Checkbox
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  checked={values.unlimitedQuantity}
                                  name="unlimitedQuantity"
                                  value={values.unlimitedQuantity}
                                  onChange={e => {
                                    handleChange(e);
                                    setInitialValues({
                                      ...values,
                                      quantity: e.target.checked ? -1 : 0,
                                      unlimitedQuantity: e.target.checked,
                                    });
                                  }}
                                  inputProps={{'aria-label': 'controlled'}}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item md={1.5}>
                            <FormControl fullWidth margin="normal">
                              <div>
                                <Typography variant="body2">
                                  Exclusive
                                </Typography>
                                <Checkbox
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  checked={values.isExclusive ? true : false}
                                  name="isExclusive"
                                  value={values.isExclusive}
                                  onChange={e => {
                                    handleChange(e);
                                    setInitialValues({
                                      ...values,
                                      isExclusive: e.target.checked ? 1 : 0,
                                    });
                                  }}
                                  inputProps={{'aria-label': 'controlled'}}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                T&amp;Cs{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <RichTextEditor
                                label="Enter T&amp;Cs"
                                defaultValue={defaultValue}
                                helperText={
                                  touched.description && errors.description
                                }
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                                onChange={e => {
                                  let stringContent = '';
                                  e.getCurrentContent()
                                    .getBlocksAsArray()
                                    .map(
                                      item =>
                                        (stringContent =
                                          stringContent + item.text)
                                    );
                                  let content =
                                    stringContent.search('</') === -1
                                      ? stateToHTML(e.getCurrentContent())
                                      : stringContent;
                                  content = content.replace('<strong>', '<b>');
                                  content = content.replace(
                                    '</strong>',
                                    '</b>'
                                  );
                                  const regex = /href="(?!https:\/\/|tel:)/g;
                                  handleChange(
                                    content === '<p><br></p>'
                                      ? ''
                                      : content.replace(regex, `href="https://`)
                                  );
                                  setInitialValues({
                                    ...values,
                                    description:
                                      content === '<p><br></p>'
                                        ? ''
                                        : content.replace(
                                            regex,
                                            `href="https://`
                                          ),
                                  });
                                  // setEditorError(content === '<p><br></p>');
                                }}
                                controls={[
                                  'title',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strikethrough',
                                  'undo',
                                  'redo',
                                  'link',
                                  'media',
                                  'numberList',
                                  'bulletList',
                                  'source',
                                ]}
                                customControls={[
                                  {
                                    name: 'source',
                                    icon: <SourceOutlinedIcon />,
                                    type: 'callback',
                                    onClick: () => {
                                      viewSourceHtmlEditor(
                                        initialValues?.description
                                      );
                                    },
                                  },
                                ]}
                                // onBlur={() => {
                                //   setEditorError(values.description === '');
                                // }}
                              />
                              {/* <TextArea
                                label=""
                                name="description"
                                placeholder="Enter T&amp;Cs"
                                maxRows={4}
                                value={values.description}
                                helperText={
                                  touched.description && errors.description
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                              /> */}
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth margin="dense">
                              <Typography variant="body2" mb={2}>
                                Online Purchase URL
                              </Typography>
                              <Input
                                type="text"
                                name="onlinePurchaseURL"
                                placeholder="Enter Online Purchase URL"
                                size="small"
                                variant="outlined"
                                value={values.onlinePurchaseURL}
                                onChange={handleChange}
                                helperText={
                                  touched.onlinePurchaseURL &&
                                  errors.onlinePurchaseURL
                                }
                                onBlur={handleBlur}
                                error={Boolean(
                                  touched.onlinePurchaseURL &&
                                    errors.onlinePurchaseURL
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Start Date Time{' '}
                              </Typography>
                              <DateTimePicker
                                required
                                isEmptyValue={isEmptyDate}
                                value={valueRangePicker.startOfDateTime}
                                helperText="Start Date Time is required"
                                onChange={e => {
                                  if (e >= valueRangePicker.endOfDateTime) {
                                    setValueRangePicker({
                                      ...valueRangePicker,
                                      startOfDateTime: e,
                                      endOfDateTime: null,
                                    });
                                    setInitialValues({
                                      ...initialValues,
                                      startOfDateTime: formatDateTime(
                                        e,
                                        formatTime
                                      ),
                                      endOfDateTime: null,
                                    });
                                  } else {
                                    setValueRangePicker({
                                      ...valueRangePicker,
                                      startOfDateTime: formatDateTime(
                                        e,
                                        formatTime
                                      ),
                                    });
                                    setInitialValues({
                                      ...initialValues,
                                      startOfDateTime: formatDateTime(
                                        e,
                                        formatTime
                                      ),
                                    });
                                  }
                                }}
                              />
                              {/* <RangePicker
                        required
                        isEmptyValue={isEmptyDate}
                        value={valueRangePicker}
                        onChange={onChangeRangePicker}
                      /> */}
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                End Date Time{' '}
                              </Typography>
                              <DateTimePicker
                                required
                                isEmptyValue={false}
                                disabled={
                                  valueRangePicker.startOfDateTime === null
                                }
                                value={valueRangePicker.endOfDateTime}
                                helperText="End Date Time is required"
                                minDate={
                                  initialValues?.startOfDateTime || new Date()
                                }
                                onChange={e => {
                                  setValueRangePicker({
                                    ...valueRangePicker,
                                    endOfDateTime: e,
                                  });
                                  setInitialValues({
                                    ...initialValues,
                                    endOfDateTime: formatDateTime(
                                      e,
                                      formatTime
                                    ),
                                  });
                                }}
                              />
                              {/* <RangePicker
                        required
                        isEmptyValue={isEmptyDate}
                        value={valueRangePicker}
                        onChange={onChangeRangePicker}
                      /> */}
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Select IP
                              </Typography>
                              <MultipleSelectCheckbox
                                placeholder={selectIPPlaceholder}
                                value={valueMultipleSelectCheckbox}
                                onChange={(e, values) => {
                                  const newData = values.map(
                                    item => item.value
                                  );
                                  setValueMultipleSelectCheckbox([...values]);
                                  setIslandPartners(newData);
                                }}
                                options={optionIslandPartners}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Voucher Type
                              </Typography>
                              <RadioGroups
                                name="usageType"
                                options={radioOptions}
                                values={values.usageType}
                                onChange={e => {
                                  handleChange(e);
                                  setInitialValues({
                                    ...values,
                                    usageType: +e.target.value,
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Voucher Category
                              </Typography>
                              <MultipleSelectCheckbox
                                placeholder={selectCategoryPlaceholder}
                                value={categorySelectCheckbox}
                                onChange={(e, values) => {
                                  const newData = values.map(
                                    item => item.value
                                  );
                                  setCategorySelectCheckbox([...values]);
                                  setVoucherCategories(newData);
                                }}
                                options={optionVoucherCategory}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Voucher Image
                              </Typography>
                              <AspectSelectImage
                                defaultImages={voucherImages}
                                setDefaultImages={setVoucherImages}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <Tooltips
                              title="When this is checked, it will bump the voucher to the top of the voucher listing page in B2C"
                              arrow={false}
                            >
                              <FormControl fullWidth margin="dense">
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography variant="inherit">
                                    Priority
                                  </Typography>
                                  <Checkbox
                                    sx={{
                                      paddingLeft: 0,
                                      marginLeft: 1,
                                    }}
                                    name="priority"
                                    checked={values.priority}
                                    value={values.priority}
                                    onChange={e => {
                                      handleChange(e);
                                      setInitialValues({
                                        ...values,
                                        priority: e.target.checked,
                                      });
                                    }}
                                    inputProps={{'aria-label': 'controlled'}}
                                  />
                                </div>
                              </FormControl>
                            </Tooltips>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <FooterDetail
                      isEdit={idDetail ? true : false}
                      toggleModalConfirm={toggleModalConfirm}
                      toggleModalConfirmDelete={toggleModalConfirmDelete}
                      titleDelete="Delete Voucher"
                      loading={isLoading}
                      isVoucher
                      onClickVoucher={() => {
                        navigate(
                          `/${path.VOUCHER_TRANSACTION_HISTORY}?voucherId=${idDetail}`,
                          {
                            state: {
                              voucherId: idDetail,
                            },
                          }
                        );
                      }}
                    />
                  </form>
                )}
              </Formik>
            </Shadow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default VoucherDetail;
