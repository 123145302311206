import {Box, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';

interface MonthCalendarType {
  year: number;
  cashoutPeriod: monthOptions[];
  onChange: (v: monthOptions, y: number) => void;
  monthSelected: string | undefined;
}

type monthOptions = {
  month: string;
  cashoutQty: number;
  unCashoutQty: number;
};

const MonthCalendar = ({
  year,
  cashoutPeriod,
  onChange,
  monthSelected,
}: MonthCalendarType) => {
  const months: monthOptions[] = [
    {
      month: 'Jan',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Feb',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Mar',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Apr',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'May',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Jun',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Jul',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Aug',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Sep',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Oct',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Nov',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
    {
      month: 'Dec',
      cashoutQty: 0,
      unCashoutQty: 0,
    },
  ];
  const [monthOptions, setMonthOptions] = useState<monthOptions[]>(months);
  const getCurrentMonthValue = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setFullYear(date.getFullYear());
    return date;
  };

  const getMinMonthValue = () => {
    const date = new Date();
    date.setMonth(date.getMonth());
    date.setFullYear(date.getFullYear() - 2);
    return date;
  };

  const checkValidMonth = index => {
    if (year === getCurrentMonthValue().getFullYear()) {
      return index <= getCurrentMonthValue().getMonth();
    }
    return true;
  };

  useEffect(() => {
    let mapDataBetweenAPIToDefault: monthOptions[] = [];
    if (cashoutPeriod.length > 0) {
      months.reduce((result, m, currentIndex) => {
        const mappedData = cashoutPeriod.find(f =>
          f.month.toLowerCase().includes(m.month.toLowerCase())
        );
        if (mappedData !== undefined) {
          m.cashoutQty = mappedData.cashoutQty;
          m.unCashoutQty = mappedData.unCashoutQty;
        }

        result[currentIndex] = m;
        return result;
      }, mapDataBetweenAPIToDefault);
    } else {
      mapDataBetweenAPIToDefault = months.map(m => {
        m.cashoutQty = 0;
        m.unCashoutQty = 0;
        return m;
      });
    }

    if (year === getMinMonthValue().getFullYear()) {
      mapDataBetweenAPIToDefault = months.filter((f, index) => {
        if (index >= getMinMonthValue().getMonth()) return f;
      });
    }

    setMonthOptions(
      mapDataBetweenAPIToDefault.map(m => {
        m.month = `${m.month}-${year}`;
        return m;
      })
    );
  }, [cashoutPeriod]);

  const renderMonthLabel = (item: monthOptions, index: number) => {
    let desc = ` (${item.cashoutQty + item.unCashoutQty})`;
    if (
      index > new Date().getMonth() &&
      item.month.split('-')[1] === new Date().getFullYear().toString()
    ) {
      desc = '';
    }
    return item.month.split('-')[0] + desc;
  };

  return (
    <Box marginTop={5}>
      <Typography
        textAlign="center"
        variant="h3"
        fontSize={20}
        fontWeight="bolder"
        color="#003349"
        sx={{cursor: 'default'}}
        marginBottom={5}
      >
        {year}
      </Typography>
      <Grid container columns={{xs: 4, sm: 8, md: 12}}>
        {monthOptions.map((item, index) => (
          <Grid item md={3} key={index} p={1}>
            <Typography
              sx={{
                backgroundColor: `${
                  checkValidMonth(index)
                    ? item.month === monthSelected
                      ? '#376DF0'
                      : '#ffffff'
                    : '#ECECEC'
                }`,
                fontSize: 14,
                color: `${
                  checkValidMonth(index)
                    ? item.month === monthSelected
                      ? '#ffffff'
                      : '#003349'
                    : '#979797'
                }`,
                borderRadius: 10,
                padding: '8px 4px',
                textAlign: 'center',
                fontWeight: 600,
                cursor: `${checkValidMonth(index) ? 'pointer' : 'cursor'}`,
                userSelect: 'none',
              }}
              onClick={() => {
                if (checkValidMonth(index)) {
                  onChange(item, year);
                }
              }}
            >
              {renderMonthLabel(item, index)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MonthCalendar;
