const path = {
  DEFAULT: '/',
  DASHBOARD: 'dashboard',
  ISLAND_MEMBERS: 'island-members',
  ISLAND_PARTNERS: 'island-partners',
  SDC_ADMINISTRATORS: 'sdc-administrators',
  ALL_TRANSACTION: 'all-transaction',
  EVENTS: 'all-event',
  ARTICLE: 'article',
  VOUCHER_TRANSACTION_HISTORY: 'voucher-transaction-history',
  AUTH: 'auth',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  RESET_PASSWORD: 'reset-password',
  NEW_PASSWORD: 'new-password',
  RESET_PASSWORD_SUCCESS: 'reset-password-success',
  CHANGE_PASSWORD_SUCCESS: 'change-password-success',
  CHANGE_PASSWORD: 'change-password',
  VOUCHER: 'voucher',
  FEATURED_VOUCHER: 'featured-voucher',
  ADD_NEW_VOUCHER: 'add-new-voucher',
  AUDIT_LOGS: 'audit-logs',
  AUDIT_LOGS_MEMBERS: 'audit-logs-members',
  FA_VERITICATION: '2fa-veritication',
  FORGOT_PASSWORD: 'forgot-password',
  REFERRAL_PROGRAMME: 'referral-programme',
  REFERRAL_TRANSACTIONS: 'referral-transactions',
  PARTNERSHIP: 'partnership',
  ISLANDER_MEMBER: 'islander-member',
  USER: 'user',
  CHANGE_LOG: 'change-log',
  WATCHLIST_TAG: 'watchlist-tags',
  TERMINATED_MEMBERS: 'terminated-members',
  LIMITED_EVENT: 'limited-events',
  MOBILE_APP_CMS: 'mobile-app-cms',
  MOBILE_ASSETS: 'manage-assets',
  MEDIA_LIBRARY: 'media-library',
  MEMBER_DASHBOARD: 'member-dashboard',
  MEMBER_DASHBOARD_CREATE_NEW: 'create-new',
  MEMBER_DASHBOARD_MANAGE_LIVE: 'manage-live',
  UPLOAD_NEW: 'upload-new',
  VOUCHER_CATEGORIES: 'voucher-categories',
  VOUCHER_CATEGORIES_ADD_NEW: 'add-new',
  VOUCHER_CATEGORIES_MANAGE: 'manage-categories',
  RECEIPT_VALIDATION_LOGS: 'receipt-validation-logs',
  PASSWORD_RESET_EXPIRED: 'password-reset-expired',
  404: '404',
  500: '500',
};
export {path};
