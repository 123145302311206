import BaseApiService from '../baseApi.service';

export default class MobileAssetsService extends BaseApiService {
  readonly basePath: string = 'member-dashboard-asset/v1';
  static DeleteFileInRedis: any;
  constructor(props = null) {
    super(props);
  }

  CreateNewMemberDashboardAsset = async (params: any) => {
    return await this.post('', params).then(res => res);
  };

  ArrangeMemberDashboardAssets = async (params: any) => {
    return await this.post('arrange-assets', params).then(res => res);
  };

  DeleteMemberDashboardAsset = async (id: string) => {
    return await this.delete(`${id}`).then(res => res);
  };

  GetAllMemberDashboardAsset = async (params: any) => {
    return await this.get('', params).then(res => res.data);
  };

  GetOneMemberDashboardAsset = async (id: string) => {
    return await this.get(`${id}`).then(res => res);
  };

  UpdateMemberDashboardAsset = async (id: string, params: any) => {
    return await this.patch(`${id}`, params).then(res => res);
  };

  Active = async (id: string) => {
    return await this.post(`active/${id}`).then(res => res);
  };

  uploadImage = async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    return await this.post(`${id}/uploadImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(async res => res.data);
  };
}
