/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, forwardRef} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {
  Modal,
  Select,
  Button,
  Input,
  MultipleSelectCheckbox,
  RangePicker,
} from '../../../../components';
import MembersService from '../../../../services/Members/members.service';
import {
  formatDateTime,
  formatPhoneNumber,
  getEndOfDate,
  getStartOfDate,
} from '../../../../utils';
import TagsService from '../../../../services/Tags/tags.service';
import InterestsService from '../../../../services/Interests/interests.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  islanderPartnerNames?: any[];
  ocrLabelStatusOptions?: any[];
  valueRangePicker?: any[];
  setValueRangerPicker?: () => {};
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter = () => {},
  islanderPartnerNames,
  ocrLabelStatusOptions = [],
  valueRangePicker,
  setValueRangerPicker,
}: ModalFilterProps) {
  const format = 'DD/MM/YYYY';
  const handleChange = (key: string, value: any) => {
    setValueFilter(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCleanForm = () => {
    handleClean();
    setValueRangerPicker([null, null]);
  };

  const handleClose = () => {
    onClose();
    if (!visible) {
      handleClean();
    }
  };

  const onSubmitForm = () => {
    handleFilter();
    onClose();
  };

  const onChangeRangePicker = (e: any[]) => {
    setValueRangerPicker(e);
    handleChange('createdDateTime_lte', getEndOfDate(e[1], format));
    handleChange('createdDateTime_gte', getStartOfDate(e[0], format));
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TRANSACTION DATE
            </Typography>
            <RangePicker
              value={valueRangePicker}
              onChange={onChangeRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-16px'}}>
            <Typography variant="subtitle2" mb={2}>
              BY ACCOUNT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Account ID"
              size="small"
              value={valueFilter.accountId_eq}
              onChange={e => handleChange('accountId_eq', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-16px'}}>
            <Typography variant="subtitle2" mb={2}>
              BY RECEIPT VALIDATION ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Receipt Validation ID"
              size="small"
              value={valueFilter.id_eq}
              onChange={e => handleChange('id_eq', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-16px'}}>
            <Typography variant="subtitle2" mb={2}>
              BY RECEIPT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Receipt ID"
              size="small"
              value={valueFilter.receiptId_eq}
              onChange={e => handleChange('receiptId_eq', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ISLAND PARTNER’S NAME
            </Typography>
            <Select
              labelId="merchantId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => {
                handleChange('matchedMerchantID_eq', e.target.value);
              }}
              value={valueFilter.matchedMerchantID_eq}
              placeholder="Select Island Partner's Name"
              size="small"
              selectOptions={islanderPartnerNames}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-16px'}}>
            <Typography variant="subtitle2" mb={2}>
              BY SYSTEM ASSURANCE LEVEL MIN
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter system assurance min"
              size="small"
              value={valueFilter.receiptConfidence_gte}
              onChange={e =>
                handleChange('receiptConfidence_gte', e.target.value)
              }
            />
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{marginBottom: '-16px'}}>
            <Typography variant="subtitle2" mb={2}>
              BY SYSTEM ASSURANCE LEVEL MAX
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter system assurance max"
              size="small"
              value={valueFilter.receiptConfidence_lte}
              onChange={e =>
                handleChange('receiptConfidence_lte', e.target.value)
              }
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TRANSACTION STATUS
            </Typography>
            <Select
              labelId="ocrLabelStatus_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e =>
                handleChange('ocrLabelStatus_eq', e.target.value)
              }
              value={
                valueFilter.ocrLabelStatus_eq !== ''
                  ? Number(valueFilter.ocrLabelStatus_eq)
                  : ''
              }
              placeholder="Select by transaction status"
              size="small"
              selectOptions={ocrLabelStatusOptions}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button
              disabled={
                !valueFilter.accountId_eq &&
                !valueFilter.id_eq &&
                !valueFilter.receiptId_eq &&
                !valueFilter.createdDateTime_lte &&
                !valueFilter.createdDateTime_gte &&
                !valueFilter.receiptMerchantName_label &&
                !valueFilter.matchedMerchantID_eq &&
                valueFilter.ocrLabelStatus_eq !== 0 &&
                !valueFilter.ocrLabelStatus_eq &&
                !valueFilter.receiptConfidence_gte &&
                !valueFilter.receiptConfidence_lte
              }
              variant="text"
              onClick={handleCleanForm}
            >
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              disabled={
                !valueFilter.accountId_eq &&
                !valueFilter.id_eq &&
                !valueFilter.receiptId_eq &&
                !valueFilter.createdDateTime_lte &&
                !valueFilter.createdDateTime_gte &&
                !valueFilter.receiptMerchantName_label &&
                !valueFilter.matchedMerchantID_eq &&
                valueFilter.ocrLabelStatus_eq !== 0 &&
                !valueFilter.ocrLabelStatus_eq &&
                !valueFilter.receiptConfidence_gte &&
                !valueFilter.receiptConfidence_lte
              }
              onClick={onSubmitForm}
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
