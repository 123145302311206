export type ColumnsType = {
  dataIndex: string | null;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  disabled?: boolean;
  textAlign?: string;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  date: string;
  email: string;
  spentAmount: string;
  status: string;
  pointValue: number;
  receiptConfidence: number;
  receiptImageUrl: string;
};

export type DataDetail = {
  createdDateTime?: string;
  customerId?: string;
  email?: string;
  employeeId?: number;
  expiryDateTime?: string;
  id?: number;
  islandPartnerId?: number | string;
  islandPartnerName?: string;
  locationId?: number | string;
  membershipId?: string;
  membershipTicketId?: number | string;
  merchantId?: number;
  notes?: string;
  operation?: number;
  pointValue?: number;
  processId: number;
  spentAmount?: number;
  status?: number;
  updatedBy?: string;
  updatedDateTime?: string;
  employeeName?: string;
  phoneNumber?: number;
  ticketId?: string;
  ticketNumberId?: string | number;
  secondTicketId?: string;
  secondTicketNumberId?: string | number;
  pointMultiplierEventCreationId?: number;
  workflowId?: number;
  ticketTitle?: string | undefined;
  secondTicketTitle?: string | undefined;
  receiptId?: number | undefined;
  rejectReason?: number | undefined;
};

export type VoucherDetail = {
  id?: string;
  title?: string;
  numberId?: string | number;
};

export type MembershipTicketDetail = {
  ticketId?: string | number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean,
  state?: object
) {
  return {name, path, isCurrentPage, state};
}
