/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {DateRange} from '@mui/lab/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {ColorType} from '../../types/typeChip';
import {ModalInformations as ModalInformationsProps} from '../../types/typeModals';
import {Breadcrumbs, Button, Chip, Table, Tooltips} from '../../components';
import {path} from '../../routes/path';
import {RowType, typeRoutes} from '../../types/typeVoucherTransactionHistory';
import ModalFilter from './components/ModalFilter/ModalFilter';
import MembershipTicketService from '../../services/MembershipTicket/membership-ticket.service';
import {
  hasPermission,
  formatDate,
  getEndOfDate,
  getStartOfDate,
  formatLocalDateFromUTCTime,
  envRole,
} from '../../utils';
import {
  MEMBERSHIP_TICKET_COLOR,
  MEMBERSHIP_TICKET_LABEL,
  MEMBERSHIP_TICKET_STATUS,
  VOUCHER_TXN_MODAL_INFOMATIONS,
} from '../../constants/status';
import useAuth from '../../hooks/useAuth';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';
import queryString from 'query-string';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledStack,
  StyledAction,
  StyledIcon,
} from './VoucherTransactionHistory.style';
import {USER_TYPE} from '../../constants/UserType';
import {
  REDEMPTION_PLATFORM,
  REDEMPTION_PLATFORMS,
  TYPES_USED_PLATFROM,
  USED_PLATFORM,
  USED_PLATFORM_OPTIONS,
} from '../../constants/redemptionPlatform';
import {ColumnsType} from '../../types/typeTable';
import IslanderService from '../../services/Islander/islander.service';
import ModalInformations from '../../components/Modal/ModalInformations/ModalInformations';
import {IsNullOrEmptyOrUndefined} from '../../utils/helpers';

const optionByStatus = (Object.keys(MEMBERSHIP_TICKET_LABEL) || []).reduce(
  (items, key) => {
    items.push({
      value: key,
      label: MEMBERSHIP_TICKET_LABEL[key],
    });
    return items;
  },
  []
);

function VoucherTransactionHistory() {
  const navigate = useNavigate();
  const {getUser, showNotification} = useAuth();
  const locationUrl = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {merchantId, email} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const getMerchantId = locationUrl?.state?.merchantId;
  const getMerchantLabel = locationUrl?.state?.getMerchantLabel;
  const getMembershipEmail = locationUrl?.state?.membershipEmail;
  const getVoucherId = locationUrl?.state?.voucherId;
  const [islanderPartnerOptions, setIslanderPartnerOptions] = useState<any[]>(
    []
  );

  const format = 'DD/MM/YYYY';
  const formatDateTime = 'DD/MM/YYYY HH:mm';

  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    description: '',
  });
  const redemptionPlatformModalInformations: ModalInformationsProps =
    VOUCHER_TXN_MODAL_INFOMATIONS.getvoucher;
  const usagePlatformModalInformations: ModalInformationsProps =
    VOUCHER_TXN_MODAL_INFOMATIONS.usevoucher;
  const [valueFilter, setValueFilter] = useState({
    title_eq: searchParams.get('title_eq') ?? '',
    id_eq: searchParams.get('id_eq') ?? '',
    merchantTicketPointId_eq:
      (getMerchantId || searchParams.get('merchantTicketPointId_eq')) ?? '',
    merchantTicketPointId_label:
      (getMerchantLabel || searchParams.get('merchantTicketPointId_eq')) ?? '',
    email_eq: (getMembershipEmail || searchParams.get('email_eq')) ?? '',
    voucherStatus_eq: searchParams.get('voucherStatus_eq') ?? '',
    snAppId_eq: searchParams.get('snAppId_eq') ?? '',
    createdDatetime_lte: searchParams.get('createdDatetime_lte') ?? '',
    createdDatetime_gte: searchParams.get('createdDatetime_gte') ?? '',
    updatedDateTime_lte: searchParams.get('updatedDateTime_lte') ?? '',
    updatedDateTime_gte: searchParams.get('updatedDateTime_gte') ?? '',
    updatedDateTime: [null, null],
    createdDatetime: [null, null],
    redeemedFrom_eq: searchParams.get('redeemedFrom_eq') ?? '',
    usagePlatform_eq: searchParams.get('usagePlatform_eq') ?? '',
    ticketTitle_contains: searchParams.get('ticketTitle_contains') ?? '',
    merchantId_eq: searchParams.get('merchantId_eq')
      ? Number(searchParams.get('merchantId_eq'))
      : '',
    merchantId_label: searchParams.get('merchantId_label') ?? '',
    pointId_isnull: searchParams.get('pointId_isnull') ?? '',
  });

  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'updatedDateTime:desc',
    merchantTicketPointId_eq:
      (getMerchantId || searchParamsObject.merchantTicketPointId_eq) ??
      undefined,
    merchantTicketPointId_label:
      (getMerchantLabel || searchParamsObject.merchantTicketPointId_label) ??
      undefined,
    email_eq: (getMembershipEmail || searchParamsObject.email_eq) ?? undefined,
    ticketId_eq: getVoucherId || undefined,
    ...{
      title_eq: searchParamsObject.title_eq ?? undefined,
      id_eq: searchParamsObject.id_eq ?? undefined,
      voucherStatus_eq: searchParamsObject.voucherStatus_eq ?? undefined,
      snAppId_eq: searchParamsObject.snAppId_eq ?? undefined,
      createdDatetime_lte: searchParamsObject.createdDatetime_lte ?? undefined,
      createdDatetime_gte: searchParamsObject.createdDatetime_gte ?? undefined,
      updatedDateTime_lte: searchParamsObject.updatedDateTime_lte ?? undefined,
      updatedDateTime_gte: searchParamsObject.updatedDateTime_gte ?? undefined,
      redeemedFrom_eq: searchParamsObject.redeemedFrom_eq ?? undefined,
      membershipId_eq: searchParamsObject.membershipId_eq ?? undefined,
      usagePlatform_eq: searchParamsObject.usagePlatform_eq ?? undefined,
      ticketTitle_contains:
        searchParamsObject.ticketTitle_contains ?? undefined,
      merchantId_eq: searchParamsObject.merchantId_eq ?? undefined,
      merchantId_label: searchParamsObject.merchantId_label ?? undefined,
      pointId_isnull: searchParamsObject.pointId_isnull ?? undefined,
    },
  });

  const onChangeRangePicker = createdDatetime => {
    setValueFilter({
      ...valueFilter,
      createdDatetime,
    });
  };

  const onChangeUsedDateRangePicker = updatedDateTime => {
    setValueFilter({
      ...valueFilter,
      updatedDateTime,
    });
  };

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'membershipNo',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {
      dataIndex: 'ticketNumberId',
      numeric: false,
      disablePadding: false,
      label: '‘Get voucher’ no.',
    },
    {
      dataIndex: 'ticketTitle',
      numeric: false,
      disablePadding: false,
      label: 'Voucher title',
    },
    {
      dataIndex: 'ticketPoint',
      numeric: false,
      disablePadding: false,
      label: 'Points redeemed',
    },
    {
      dataIndex: 'gottenDateTime',
      numeric: false,
      disablePadding: false,
      label: '‘Get voucher’ date and time',
    },
    {
      dataIndex: 'redempltionPlatform',
      numeric: false,
      disablePadding: false,
      label: '‘Get voucher’ platform',
      modalInfo: redemptionPlatformModalInformations,
    },
    {
      dataIndex: 'updatedDateTime',
      numeric: false,
      disablePadding: false,
      label: '‘Use voucher’ date and time',
    },
    {
      dataIndex: 'usedFrom',
      numeric: false,
      disablePadding: false,
      label: '‘Use voucher’ platform',
      modalInfo: usagePlatformModalInformations,
    },
    {
      dataIndex: 'merchantName',
      numeric: false,
      disablePadding: false,
      label: '‘Use voucher’ at IP',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Voucher status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onClickIconButton = idTransaction => {
    return navigate(`/${path.VOUCHER_TRANSACTION_HISTORY}/${idTransaction}`, {
      state: {
        filterParams: {
          ...queryString.parse(searchParams.toString()),
        },
      },
    });
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  onClickIconButton(value);
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                onClickIconButton(value);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (value) {
      const statusWithPointId =
        value.pointId === null
          ? MEMBERSHIP_TICKET_STATUS.ACTIVE
          : MEMBERSHIP_TICKET_STATUS.USED;
      let renderColor = MEMBERSHIP_TICKET_COLOR[statusWithPointId];
      let label = MEMBERSHIP_TICKET_LABEL[statusWithPointId];
      if (
        (value?.usedFrom === TYPES_USED_PLATFROM.WEB_MANUAL ||
          value?.usedFrom === TYPES_USED_PLATFROM.APP_MANUAL) &&
        statusWithPointId !== MEMBERSHIP_TICKET_STATUS.ACTIVE &&
        value?.status === MEMBERSHIP_TICKET_STATUS.ACTIVE
      ) {
        label = MEMBERSHIP_TICKET_LABEL[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
        renderColor =
          MEMBERSHIP_TICKET_COLOR[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
      }
      return <Chip label={label} color={renderColor as ColorType} />;
    }
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelVoucherTransaction({
        ...params,
        email: email,
        merchantId_label: undefined,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'title_eq') {
      deleteSearchParams('title_eq');
      setValueFilter({
        ...valueFilter,
        title_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        title_eq: undefined,
      }));
    }

    if (type === 'id_eq') {
      deleteSearchParams('id_eq');
      setValueFilter({
        ...valueFilter,
        id_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        id_eq: undefined,
      }));
    }

    if (type === 'merchantTicketPointId_eq') {
      deleteSearchParams('merchantTicketPointId_eq');
      deleteSearchParams('merchantTicketPointId_label');
      setValueFilter({
        ...valueFilter,
        merchantTicketPointId_eq: '',
        merchantTicketPointId_label: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        merchantTicketPointId_eq: undefined,
        merchantTicketPointId_label: undefined,
      }));
    }

    if (type === 'snAppId_eq') {
      deleteSearchParams('snAppId_eq');
      setValueFilter({
        ...valueFilter,
        snAppId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        snAppId_eq: undefined,
      }));
    }

    if (type === 'email_eq') {
      deleteSearchParams('email_eq');
      setValueFilter({
        ...valueFilter,
        email_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        email_eq: undefined,
      }));
    }

    if (type === 'redeemedFrom_eq') {
      deleteSearchParams('redeemedFrom_eq');
      setValueFilter({
        ...valueFilter,
        redeemedFrom_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        redeemedFrom_eq: undefined,
      }));
    }

    if (type === 'voucherStatus_eq') {
      deleteSearchParams('voucherStatus_eq');
      setValueFilter({
        ...valueFilter,
        voucherStatus_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        voucherStatus_eq: undefined,
      }));
    }

    if (type === 'usagePlatform_eq') {
      deleteSearchParams('usagePlatform_eq');
      setValueFilter({
        ...valueFilter,
        usagePlatform_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        usagePlatform_eq: undefined,
      }));
    }

    if (type === 'createdDatetime') {
      deleteSearchParams('createdDatetime_lte');
      deleteSearchParams('createdDatetime_gte');
      setValueFilter({
        ...valueFilter,
        createdDatetime_lte: '',
        createdDatetime_gte: '',
        createdDatetime: [null, null],
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDatetime_gte: undefined,
        createdDatetime_lte: undefined,
      }));
    }

    if (type === 'updatedDateTime') {
      deleteSearchParams('updatedDateTime_lte');
      deleteSearchParams('updatedDateTime_gte');
      setValueFilter({
        ...valueFilter,
        updatedDateTime_lte: '',
        updatedDateTime_gte: '',
        updatedDateTime: [null, null],
        pointId_isnull: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        updatedDateTime_gte: undefined,
        updatedDateTime_lte: undefined,
        pointId_isnull: undefined,
      }));
    }

    if (type === 'merchantId_eq') {
      deleteSearchParams('merchantId_eq');
      deleteSearchParams('merchantId_label');
      setValueFilter({
        ...valueFilter,
        merchantId_eq: '',
        merchantId_label: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        merchantId_eq: undefined,
        merchantId_label: undefined,
      }));
    }

    if (type === 'ticketTitle_contains') {
      deleteSearchParams('ticketTitle_contains');
      setValueFilter({
        ...valueFilter,
        ticketTitle_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        ticketTitle_contains: undefined,
      }));
    }
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleClean = () => {
    setValueFilter({
      title_eq: '',
      id_eq: '',
      merchantTicketPointId_eq: '',
      merchantTicketPointId_label: '',
      email_eq: '',
      voucherStatus_eq: '',
      snAppId_eq: '',
      createdDatetime_lte: '',
      createdDatetime_gte: '',
      createdDatetime: [null, null],
      redeemedFrom_eq: '',
      usagePlatform_eq: '',
      updatedDateTime_lte: '',
      updatedDateTime_gte: '',
      updatedDateTime: [null, null],
      merchantId_eq: '',
      ticketTitle_contains: '',
      merchantId_label: '',
      pointId_isnull: '',
    });
    setParams({
      _page: 0,
      _size: 10,
      _sort: 'updatedDateTime:desc',
      createdDatetime_gte: undefined,
      createdDatetime_lte: undefined,
      email_eq: undefined,
      id_eq: undefined,
      membershipId_eq: undefined,
      merchantId_eq: undefined,
      merchantId_label: undefined,
      merchantTicketPointId_eq: undefined,
      merchantTicketPointId_label: undefined,
      redeemedFrom_eq: undefined,
      snAppId_eq: undefined,
      ticketId_eq: undefined,
      ticketTitle_contains: undefined,
      title_eq: undefined,
      updatedDateTime_gte: undefined,
      updatedDateTime_lte: undefined,
      usagePlatform_eq: undefined,
      voucherStatus_eq: undefined,
      pointId_isnull: undefined,
    });
  };

  const handleFilter = () => {
    if (valueFilter?.usagePlatform_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        usagePlatform_eq:
          Number(valueFilter?.usagePlatform_eq) === 0
            ? undefined
            : valueFilter?.usagePlatform_eq,
      }));
    }
    if (valueFilter?.title_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        title_eq: valueFilter?.title_eq,
      }));
    }
    if (valueFilter?.id_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        id_eq: valueFilter?.id_eq,
      }));
    }

    if (valueFilter?.merchantTicketPointId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantTicketPointId_eq:
          valueFilter?.merchantTicketPointId_eq === 'All IP'
            ? undefined
            : valueFilter?.merchantTicketPointId_eq,
      }));
    }

    if (valueFilter?.snAppId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        snAppId_eq: valueFilter?.snAppId_eq,
      }));
    }

    if (valueFilter?.email_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_eq: valueFilter?.email_eq,
      }));
    }

    if (valueFilter?.redeemedFrom_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        redeemedFrom_eq: valueFilter?.redeemedFrom_eq,
      }));
    }

    if (valueFilter?.voucherStatus_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        voucherStatus_eq: valueFilter?.voucherStatus_eq,
      }));
    }
    if (valueFilter?.updatedDateTime[0] || valueFilter?.updatedDateTime[1]) {
      let obj = {};
      if (valueFilter?.updatedDateTime[0]) {
        obj = {
          ...obj,
          updatedDateTime_gte: getStartOfDate(valueFilter.updatedDateTime[0]),
        };
      }
      if (valueFilter?.updatedDateTime[1]) {
        obj = {
          ...obj,
          updatedDateTime_lte: getEndOfDate(valueFilter?.updatedDateTime[1]),
        };
      }
      setParams(preState => ({
        ...preState,
        _page: 0,
        ...obj,
        pointId_isnull: '2',
      }));
    } else if (
      valueFilter?.updatedDateTime_lte !== '' &&
      valueFilter?.updatedDateTime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        updatedDateTime_lte: valueFilter?.updatedDateTime_lte,
        updatedDateTime_gte: valueFilter?.updatedDateTime_gte,
        pointId_isnull: '2',
      }));
    }

    if (valueFilter?.createdDatetime[0] || valueFilter?.createdDatetime[1]) {
      let obj = {};
      if (valueFilter?.createdDatetime[0]) {
        obj = {
          ...obj,
          createdDatetime_gte: getStartOfDate(valueFilter.createdDatetime[0]),
        };
      }
      if (valueFilter?.createdDatetime[1]) {
        obj = {
          ...obj,
          createdDatetime_lte: getEndOfDate(valueFilter?.createdDatetime[1]),
        };
      }
      setParams(preState => ({
        ...preState,
        _page: 0,
        ...obj,
      }));
    } else if (
      valueFilter?.createdDatetime_lte !== '' &&
      valueFilter?.createdDatetime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDatetime_lte: valueFilter?.createdDatetime_lte,
        createdDatetime_gte: valueFilter?.createdDatetime_gte,
      }));
    }

    if (valueFilter?.merchantId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantId_eq: valueFilter?.merchantId_eq.toString(),
        merchantId_label: islanderPartnerOptions.find(
          f => f.value === Number(valueFilter?.merchantId_eq)
        )?.label,
      }));
    }

    if (valueFilter?.ticketTitle_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        ticketTitle_contains: valueFilter?.ticketTitle_contains,
      }));
    }

    toggleModal();
    // handleClean();
  };

  const toggleOpenModalInfomations = async (
    isOpen: boolean,
    title: string,
    desc: string
  ) => {
    if (isOpen) {
      return setModalInfo({isOpen: isOpen, title: title, description: desc});
    }
    return setModalInfo({...modalInfo, isOpen: isOpen});
  };

  const getListMerchant = () => {
    new IslanderService().getIslanderPartnerAvailable().then(res => {
      if (res?.data?.data?.length > 0) {
        const result = res?.data?.data?.reduce((items, item) => {
          items.push({
            label: item.name || '-',
            value: item.id,
          });
          return items;
        }, []);
        setIslanderPartnerOptions(result);
      }
    });
  };

  const getListVoucherTransactionHistory = () => {
    setIsLoading(true);
    if (role) {
      new MembershipTicketService()
        .getAll({
          ...params,
          merchantTicketPointId_label: undefined,
          merchantId_label: undefined,
        })
        .then(res => {
          if (res?.data && Array.isArray(res.data.items)) {
            setDataSource(res.data.items);
            setTotalPage(res?.data?.total);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          // Get api error => show notification or no items listing
          setError(error);
        });
    }
  };

  const renderRedemptionPlatform = (type: number) => {
    return REDEMPTION_PLATFORM[type] || '--';
  };

  const renderUsageDateTime = row => {
    return row.pointId !== null
      ? formatLocalDateFromUTCTime(row.updatedDateTime, formatDateTime)
      : '--';
  };

  const renderUsedPlatform = (usedFrom: number) => {
    if (usedFrom === TYPES_USED_PLATFROM.WEB_MANUAL) {
      usedFrom = TYPES_USED_PLATFROM.WEB;
    }

    if (usedFrom === TYPES_USED_PLATFROM.APP_MANUAL) {
      usedFrom = TYPES_USED_PLATFROM.APP;
    }
    return USED_PLATFORM_OPTIONS[usedFrom] || '--';
  };

  useEffect(() => {
    getListMerchant();
  }, []);

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        redemptionPlatform_label: params?.redeemedFrom_eq
          ? REDEMPTION_PLATFORMS[params?.redeemedFrom_eq - 1]
          : undefined,
      })
    );
    getListVoucherTransactionHistory();
  }, [params, role]);

  return (
    <React.Fragment>
      <Helmet title="Voucher Transaction" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        valueRangePicker={valueRangePicker}
        setValueFilter={setValueFilter}
        setValueRangePicker={setValueRangePicker}
        optionByStatus={optionByStatus}
        onChangeRangePicker={onChangeRangePicker}
        onChangeUsedDateRangePicker={onChangeUsedDateRangePicker}
        islanderPartnerOptions={islanderPartnerOptions}
      />

      <ModalInformations
        onClose={() => toggleOpenModalInfomations(false, '', '')}
        visible={modalInfo.isOpen}
        title={modalInfo.title}
        description={modalInfo.description}
        iconWidth={80}
        iconHeight={80}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={9}>
          <Typography variant="h3" gutterBottom>
            Voucher Transaction
          </Typography>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Overview of all Islander members’ transactions: Voucher redemptions on platforms (Web / App / System) and on-ground Usage',
                null,
                true
              ),
            ]}
          />
        </Grid>
        <Grid
          item
          xs={7}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <ButtonExport
            params={params}
            exportMethod={
              new ExportExcelService().exportExcelVoucherTransaction
            }
            fileName="VOUCHER_TRANSACTION_HISTORY_REPORT"
          /> */}

          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>

          <StyledStack direction="row" spacing={3}>
            {searchParams.get('title_eq') !== null && (
              <Chip
                label={searchParams.get('title_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('title_eq');
                }}
              />
            )}

            {searchParams.get('redeemedFrom_eq') !== null && (
              <Chip
                label={searchParams.get('redemptionPlatform_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('redeemedFrom_eq');
                }}
              />
            )}

            {searchParams.get('id_eq') !== null && (
              <Chip
                label={searchParams.get('id_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('id_eq');
                }}
              />
            )}

            {searchParams.get('email_eq') !== null && (
              <Chip
                label={searchParams.get('email_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_eq');
                }}
              />
            )}

            {searchParams.get('merchantTicketPointId_label') !== null && (
              <Chip
                label={searchParams.get('merchantTicketPointId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('merchantTicketPointId_eq');
                }}
              />
            )}

            {searchParams.get('voucherStatus_eq') !== null && (
              <Chip
                label={
                  MEMBERSHIP_TICKET_LABEL[
                    Number(searchParams.get('voucherStatus_eq'))
                  ]
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('voucherStatus_eq');
                }}
              />
            )}

            {searchParams.get('snAppId_eq') !== null && (
              <Chip
                label={searchParams.get('snAppId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('snAppId_eq');
                }}
              />
            )}

            {!IsNullOrEmptyOrUndefined(
              searchParams.get('usagePlatform_eq')
            ) && (
              <Chip
                label={
                  USED_PLATFORM[Number(searchParams.get('usagePlatform_eq'))]
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('usagePlatform_eq');
                }}
              />
            )}

            {!IsNullOrEmptyOrUndefined(
              searchParams.get('merchantId_label')
            ) && (
              <Chip
                label={searchParams.get('merchantId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('merchantId_eq');
                }}
              />
            )}

            {!IsNullOrEmptyOrUndefined(
              searchParams.get('ticketTitle_contains')
            ) && (
              <Chip
                label={searchParams.get('ticketTitle_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('ticketTitle_contains');
                }}
              />
            )}

            {searchParams.get('createdDatetime_lte') !== null &&
              searchParams.get('createdDatetime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('createdDatetime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('createdDatetime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('createdDatetime');
                  }}
                />
              )}

            {searchParams.get('updatedDateTime_lte') !== null &&
              searchParams.get('updatedDateTime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('updatedDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('updatedDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('updatedDateTime');
                  }}
                />
              )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onOpenModalInfo={toggleOpenModalInfomations}
        rowsPerPage={params._size}
        textNodata="There are no voucher transactions that match the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">{row?.membershipNo}</TableCell>
                  <TableCell component="th" scope="row">
                    000{row.id}
                  </TableCell>
                  <TableCell align="left">{row.ticketTitle}</TableCell>
                  <TableCell align="left">{row.ticketPoint}</TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {renderRedemptionPlatform(row.redeemedFrom)}
                  </TableCell>
                  <TableCell align="left">{renderUsageDateTime(row)}</TableCell>
                  <TableCell align="left">
                    {renderUsedPlatform(row?.usedFrom)}
                  </TableCell>
                  <TableCell align="left">
                    {row.merchantName ? row.merchantName : '--'}
                  </TableCell>
                  <TableCell align="left">{renderStatus(row)}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default VoucherTransactionHistory;
