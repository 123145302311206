export const TRANSACTION_TYPE_EARN_POINTS = 'earn-points';
export const TRANSACTION_TYPE_USE_VOUCHER = 'redeem-voucher';
export const TRANSACTION_TYPE_GET_VOUCHER = 'get-voucher';
export const TRANSACTION_TYPE_LIST = {
  [TRANSACTION_TYPE_EARN_POINTS]: 'Earn Points',
  [TRANSACTION_TYPE_USE_VOUCHER]: 'Use Voucher',
  [TRANSACTION_TYPE_GET_VOUCHER]: 'Get Voucher',
};

export const TRANSACTION_FILTER_TYPE_LIST = {
  ['earnpoints']: 'Earn points',
  ['earnbonuspoints']: 'Earn bonus points',
  ['earnedpointsviareceipt']: 'Earning points via scanned receipt',
  ['earnedbonuspointsviareceipt']: 'Earning bonus points via scanned receipt',
  ['getvoucher']: 'Get voucher',
  ['usevoucher']: 'Used voucher',
  ['usedorremoved']: 'Used/Removed online voucher',
};

export const TRANSACTION_TYPES = [
  '--',
  'Earn points at {0}',
  'Used voucher ID: {0}, {1}',
  'Get voucher ID: {0}, {1}',
  'Bonus Points associated with Transaction No: {0}',
  'Used/Removed online voucher ID: {0}',
  'Earn points via scanned receipt of {0}',
  'Earn bonus Points via scanned receipt associated with Transaction No: {0}',
];
