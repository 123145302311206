import React from 'react';
import {Zoom, ZoomContent} from './ModalZoomImage.style';
import {Box} from '@mui/material';

interface ModalZoomImageProps {
  visible: boolean;
  onClose?: () => void;
  image: string | undefined;
  alt: string | undefined;
}

function ModalZoomImage({
  visible,
  onClose = () => {},
  image,
  alt,
}: ModalZoomImageProps) {
  return (
    <Box sx={{display: 'flex'}}>
      <Zoom in={visible}>
        <ZoomContent onClick={onClose}>
          <img
            src={image}
            alt={alt ?? 'Zoom image'}
            width="90%"
            height="90%"
            style={{objectFit: 'contain'}}
          />
        </ZoomContent>
      </Zoom>
    </Box>
  );
}

export default ModalZoomImage;
