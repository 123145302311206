/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {TRANSACTION_FILTER_TYPE_LIST} from '../../../../constants/transaction';
import {images} from '../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  RangePicker,
  MultipleSelectCheckbox,
  Input,
} from '../../../../components';
import ConfigurationService from '../../../../services/Configuration/Configuration.service';
import IslandPartnerService from '../../../../services/Islander/islander.service';
import {envRole} from '../../../../utils';
import {USER_TYPE} from '../../../../constants/UserType';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
import {isEmpty} from 'lodash';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleClean?: () => void;
  handleFilter?: () => void;
  valueFilter?: any;
  valueRangePicker?: any;
  setValueFilter?: (any) => void;
  setValueRangePicker?: (any) => void;
  setOptionByStatus: (e: any) => void;
  optionByStatus?: any;
  nameContainsList?: any;
  setNameContainsList?: (any) => void;
}

type SelectOptionsProps = {
  label: string;
  value: string;
};

function ModalFilter({
  visible,
  onClose,
  handleClean,
  handleFilter,
  valueFilter,
  setValueRangePicker,
  setValueFilter,
  valueRangePicker,
  setOptionByStatus = () => {},
  optionByStatus,
  nameContainsList = [],
  setNameContainsList,
}: ModalFilterProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [gotConfig, setGotConfig] = useState(false);
  const [gotIslandPartner, setGotIslandPartner] = useState(false);
  const [transactionType, setTransactionType] = useState([]);
  const optionsByPointStatus: SelectOptionsProps[] = [
    {
      label: '--',
      value: '3',
    },
    {
      label: 'Active',
      value: '1',
    },
    {
      label: 'Expired',
      value: '2',
    },
  ];
  const isAdministrator = +envRole === USER_TYPE.ADMINISTRATOR;
  const role = isAdministrator ? 'administrator' : 'merchant';

  const onChange = (name: string, event: any) => {
    if (name === 'pointStatusEnum_eq') {
      return setValueFilter((prevState: any) => ({
        ...prevState,
        pointStatusEnum_eq: event.target.value,
      }));
    }

    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: Array.isArray(event) ? event : event.target.value,
    }));
  };

  const onChangeRangePicker = value => {
    setValueRangePicker(value);
  };

  const getListConfiguration = () => {
    new ConfigurationService()
      .getAll(null)
      .then(res => {
        if (res && res.data && res.data.enums) {
          const newData = res?.data?.enums?.point?.process;
          const convertData = Object.keys(newData).map(room => {
            let newLabel = null;
            if (room === 'approved') {
              newLabel = 'Approved';
            }
            if (room === 'pending') {
              newLabel = 'Pending';
            }
            if (room === 'cancelled') {
              newLabel = 'Declined';
            }
            return newLabel ? {label: newLabel, value: newData[room]} : '';
          });
          setOptionByStatus([...convertData.filter(item => !!item)]);
        }
        setGotConfig(true);
      })
      .catch(() => {
        setGotConfig(true);
        // setError(error);
      });
    getListIslandPartner();
  };

  const getListIslandPartner = () => {
    if (role === 'administrator') {
      // call api when role =  administrator
      new IslandPartnerService()
        .getAll({
          id_eq: undefined,
          status_eq: 1,
          _size: 2147483647,
          _sort: 'name:asc',
        })
        .then(res => {
          if (res?.data?.items) {
            setNameContainsList(
              res?.data?.items?.reduce((items, item) => {
                items.push({
                  label: item.name || '-',
                  value: item.id,
                });
                return items;
              }, [])
            );
          }
          setGotIslandPartner(true);
        })
        .catch(() => {
          setGotIslandPartner(true);
        });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTransactionType(
      Object.keys(TRANSACTION_FILTER_TYPE_LIST).reduce((transactions, key) => {
        if (!isAdministrator && key === 'getvoucher') {
          return transactions;
        }
        transactions.push({
          title: TRANSACTION_FILTER_TYPE_LIST[key],
          value: key,
        });
        return transactions;
      }, [])
    );
  }, []);

  useEffect(() => {
    if (visible) {
      setGotConfig(false);
      setGotIslandPartner(false);
      setIsLoading(true);
      getListConfiguration();
    }
  }, [visible]);

  useEffect(() => {
    if (gotConfig && gotIslandPartner) {
      setIsLoading(false);
    }
  }, [gotConfig, gotIslandPartner]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Transaction No.
            </Typography>
            <Input
              type="number"
              name="id_eq"
              placeholder="Enter Transaction No."
              size="small"
              variant="outlined"
              value={valueFilter.id_eq}
              onChange={e => onChange('id_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Account ID
            </Typography>
            <Input
              type="text"
              name="accountId_eq"
              placeholder="Enter Account ID"
              size="small"
              variant="outlined"
              value={valueFilter.accountId_eq}
              onChange={e => onChange('accountId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Transaction Date
            </Typography>
            <RangePicker
              value={valueRangePicker}
              onChange={onChangeRangePicker}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Transaction Type
            </Typography>
            <MultipleSelectCheckbox
              placeholder="Select Transaction Type"
              value={valueFilter.transactionFilterType_by ?? []}
              onChange={(e, values) => {
                onChange('transactionFilterType_by', [...values]);
              }}
              options={transactionType}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Transaction Status
            </Typography>
            <Select
              labelId="processId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('processId_eq', e)}
              value={valueFilter.processId_eq}
              placeholder="Select Transaction Status"
              size="small"
              selectOptions={optionByStatus}
            />
          </FormControl>
          {isAdministrator && (
            <FormControl fullWidth margin="dense">
              <Typography variant="subtitle2" mb={2}>
                By Islander Partner's Name
              </Typography>
              <Select
                labelId="merchantId_eq"
                displayEmpty
                variant="outlined"
                onChangeSelect={e => onChange('merchantId_eq', e)}
                value={valueFilter.merchantId_eq}
                placeholder="Select Island Partner's Name"
                size="small"
                selectOptions={nameContainsList}
              />
            </FormControl>
          )}
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Points multiplier event ID
            </Typography>
            <Input
              type="number"
              name="pointMultiplierEventCreationId_eq"
              placeholder="Enter points multiplier event name"
              size="small"
              variant="outlined"
              value={valueFilter.pointMultiplierEventCreationId_eq}
              onChange={e => onChange('pointMultiplierEventCreationId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              By Points multiplier event name
            </Typography>
            <Input
              type="text"
              name="pointMultiplierEventTitle_contains"
              placeholder="Enter points multiplier event name"
              size="small"
              variant="outlined"
              value={valueFilter.pointMultiplierEventTitle_contains}
              onChange={e => onChange('pointMultiplierEventTitle_contains', e)}
            />
          </FormControl>
          {isAdministrator && (
            <FormControl fullWidth margin="dense">
              <Typography variant="subtitle2" mb={2}>
                By Point Status
              </Typography>
              <Select
                displayEmpty
                variant="outlined"
                onChangeSelect={e => onChange('pointStatusEnum_eq', e)}
                value={valueFilter.pointStatusEnum_eq}
                placeholder="Select Point Status"
                size="small"
                selectOptions={optionsByPointStatus}
              />
            </FormControl>
          )}
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean} loading={isLoading}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              loading={isLoading}
              disabled={
                !valueFilter?.processId_eq &&
                isEmpty(valueFilter?.transactionFilterType_by) &&
                !valueRangePicker[0] &&
                !valueRangePicker[1] &&
                !valueFilter?.merchantId_eq &&
                !valueFilter?.pointMultiplierEventCreationId_eq &&
                !valueFilter?.pointMultiplierEventTitle_contains &&
                !valueFilter?.accountId_eq &&
                !valueFilter?.pointStatusEnum_eq &&
                !valueFilter?.id_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
