/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSearchParams} from 'react-router-dom';
import {Edit, Delete} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {sortBy} from 'lodash';
import useAuth from '../../hooks/useAuth';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import success from '../../constants/success';
import {
  Breadcrumbs,
  Button,
  ModalConfirm,
  Table,
  Tooltips,
  Chip,
} from '../../components';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import {
  formatDate,
  formatDateTime,
  formatLocalDateFromUTCTime,
} from '../../utils';
import EventService from '../../services/Event/event.service';
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import queryString from 'query-string';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './Event.style';
import {ColorType} from '../../types/typeChip';
import EnhancedTableToolbar from './components/EnhancedTableToolbar/EnhancedTableToolbar';

// interface onSortObject {
//   oldIndex?: number | undefined;
//   newIndex?: number | undefined;
// }

const DragHandle = SortableHandle(({style, children, ...other}: any) => (
  <TableCell {...other}> {children} </TableCell>
));

const TableBodySortable = SortableContainer(({children}: any) => (
  <TableBody>{children}</TableBody>
));

const Row = SortableElement(({row, renderstatus, ...other}: any) => {
  return (
    <StyledTableRow {...other}>
      <DragHandle component="th" scope="row">
        <img src={row.image} width={60} height={80} />
      </DragHandle>
      <DragHandle align="left">{row.title}</DragHandle>
      <DragHandle align="left">{row.shortDescription}</DragHandle>
      <DragHandle align="left">{row.uRL}</DragHandle>
      <DragHandle align="left">{row.date}</DragHandle>
      <DragHandle align="left">{renderstatus()}</DragHandle>
      <DragHandle align="left">
        {formatDateTime(row.createdDateTime, other.formatdatetime)}
      </DragHandle>
      <DragHandle align="left">
        {formatDateTime(row.updatedDateTime, other.formatdatetime)}
      </DragHandle>
      <DragHandle align="left">{other.rowindex + 1}</DragHandle>
      <TableCell
        align="right"
        padding="checkbox"
        style={{paddingRight: '10px'}}
      >
        {other.onClick()}
      </TableCell>
    </StyledTableRow>
  );
});

const Event = (props: any) => {
  const formatDateTime = 'DD/MM/YYYY HH:mm';

  // states
  const [searchParams, setSearchParams] = useSearchParams();
  const {showNotification} = useAuth();
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [eventItem, setEventItem] = useState(null);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loadButtonSave, setLoadButtonSave] = useState(false);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: searchParamsObject._page ?? 0,
    _size: searchParamsObject._size ?? 10,
    status_eq: searchParamsObject.status_eq ?? 1,
    isHidden_eq: searchParamsObject.isHidden_eq ?? false,
    eventEndDateTime_lt: searchParamsObject.eventEndDateTime_lt ?? undefined,
    eventStartDateTime_lte:
      searchParamsObject.eventStartDateTime_lte ?? undefined,
    eventEndDateTime_gte: searchParamsObject.eventEndDateTime_gte ?? undefined,
    eventStartDateTime_gt:
      searchParamsObject.eventStartDateTime_gt ?? undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'image',
      numeric: false,
      disablePadding: false,
      label: 'Featured Image',
    },
    {
      dataIndex: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      dataIndex: 'shortDescription',
      numeric: false,
      disablePadding: false,
      label: 'Short Description',
    },
    {
      dataIndex: 'uRL',
      numeric: false,
      disablePadding: false,
      label: 'URL',
    },
    {
      dataIndex: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      dataIndex: 'createdDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Created Date',
    },
    {
      dataIndex: 'updatedDateTime',
      numeric: false,
      disablePadding: false,
      label: 'UpdatedDate',
    },
    {
      dataIndex: 'orderFeaturedEvent',
      numeric: false,
      disablePadding: false,
      label: 'Order Featured Event',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getEvent = () => {
    // call api get event data
    setIsLoading(true);
    new EventService()
      .getAll({
        ...params,
      })
      .then(res => {
        if (res && res.success) {
          if (res?.data && res?.data?.items) {
            const listItems = res.data.items.map(item => ({
              ...item,
              date:
                formatLocalDateFromUTCTime(
                  new Date(item?.eventStartDateTime),
                  formatDateTime
                ) +
                ' - ' +
                formatLocalDateFromUTCTime(
                  new Date(item?.eventEndDateTime),
                  formatDateTime
                ),
            }));
            setDataSource(
              sortBy(listItems, option => option.orderFeaturedEvent)
            );
            setTotalPage(res?.data?.total);
          }
          setIsLoading(false);
        }
      });
  };

  const toggleModal = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const deleteEvent = id => {
    setIsLoading(true);
    new EventService().delete(selectedId).then(res => {
      if (res && res?.success) {
        showNotification({
          message: 'Event has been deleted',
        });
        toggleModalConfirmDelete();
        getEvent();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                setEventItem(value);
                toggleModal(!isModalAddNew);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              onClick={() => {
                setSelectedId(value);
                toggleModalConfirmDelete();
              }}
              size="medium"
            >
              <Delete style={StyledIcon} color="error" />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (value.status === 2) {
      return <Chip label="Deleted" color={'error' as ColorType} />;
    }
    if (
      new Date().toISOString() <=
        new Date(value?.eventEndDateTime).toISOString() &&
      new Date().toISOString() >=
        new Date(value?.eventStartDateTime).toISOString()
    ) {
      return <Chip label="Available" color={'success' as ColorType} />;
    }
    if (
      new Date(value?.eventStartDateTime).toISOString() >=
      new Date().toISOString()
    ) {
      return <Chip label="Coming" color={'warning' as ColorType} />;
    }
    if (
      new Date(value?.eventEndDateTime).toISOString() <=
      new Date().toISOString()
    ) {
      return <Chip label="Expired" color={'default' as ColorType} />;
    }
  };

  const validateLengthFeaturedEvents = () => {
    return dataSource.length <= 6;
  };

  const onUpdate = () => {
    setLoadButtonSave(true);
    if (!validateLengthFeaturedEvents()) {
      showNotification({
        message: failure.SAVE_FAILURE,
        variation: 'error',
      });
      setLoadButtonSave(false);
      return;
    }
    new EventService()
      .arrangerFeaturedEvent({
        featuredEvents: dataSource.map((item, index) => ({
          id: item.id,
          orderFeaturedEvent: index + 1,
        })),
      })
      .then(res => {
        if (res.success) {
          showNotification({
            message: success.UPDATE_SUCCESS,
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
        setLoadButtonSave(false);
      });
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    dataSource[oldIndex].orderFeaturedEvent = newIndex + 1;
    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        dataSource[i].orderFeaturedEvent = i + 2;
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        dataSource[i].orderFeaturedEvent = i;
      }
    }

    setDataSource(arrayMoveImmutable(dataSource, oldIndex, newIndex));
  };

  useEffect(() => {
    setSearchParams(queryString.stringify({...params}));
    getEvent();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Event" />

      <ModalAddNew
        idEvent={eventItem}
        visible={isModalAddNew}
        onClose={() => {
          setEventItem(null);
          toggleModal(!isModalAddNew);
        }}
        reloadPage={getEvent}
      />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">
            Are you sure you want to delete event?
          </Typography>
        }
        // subTitle={<Typography>Voucher will not be able to login.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={deleteEvent}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Event
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Event', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={onUpdate}
            margin={'0 10px 0 0'}
            loading={isLoading || loadButtonSave}
          >
            Save changes
          </Button>
          <Button
            onClick={() => {
              toggleModal();
              setEventItem(null);
            }}
            loading={isLoading}
          >
            Add New Event
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        enableDeletedTab
        isAddNew
        setParams={setParams}
      />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={() => {}}
        onChangeRowsPerPage={() => {}}
        rowsPerPage={params._size}
        textNodata="There are no all event(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis="y">
            {dataSource?.map((row, index) => {
              return (
                <Row
                  row={row}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  index={index}
                  rowindex={index}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                  onClick={() => renderAction(row.id)}
                  renderstatus={() => renderStatus(row)}
                  formatdatetime={formatDateTime}
                />
              );
            })}
          </TableBodySortable>
        )}
      </Table>
    </React.Fragment>
  );
};

export default Event;
