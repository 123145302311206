export const OCRStatus = {
  PENDING_FORM_RECOGNIZER_OCR: 1,
  PENDING_AUTO_VALIDATION: 2,
  DECLINE_NO_RECEIPT_FOUND: 3,
  DECLINE_MULTIPLE_RECEIPTS_DETECTED: 4,
  DECLINE_HANDWRITING_DETECTED: 5,
  DECLINE_UNCLEAR_RECEIPT: 6,
  DECLINE_EXPIRED_RECEIPT: 7,
  DECLINE_NO_IP_FOUND: 8,
  DECLINE_DUPLICATED_RECEIPT: 9,
  DECLINE_DIFFUSER_DUPLICATED_RECEIPT: 10,
  DECLINE_TOTAL_NOT_TALLY: 11,
  DECLINE_BUG_MISSING_TRANSACTION_DATE: 12,
  DECLINE_BUG_BILL_AMOUNT_NOT_MATCH: 13,
  PENDING_MEMBER_REVIEW: 14,
  MANUAL_REJECTION: 15,
  AZURE_ERROR: 16,
  PENDING_OCRTIMEOUT: 17,
  APPROVED_LESS_THAN_ACCEPTABLE_AMOUNT: 18,
  MANUAL_CHECK_REQUIRED: 19,
  APPROVED: 20,
  DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT: 21,
  DECLINE_NEGATIVE_KEYWORD_FOUND: 22,
  DECLINE_BUG_MISSING_IP_NAME: 29,
  DECLINE_BUG_INVALID_ID: 24,
  DECLINE_BUG_INVALID_TABLE: 25,
  DECLINE_BUG_INVALID_GST_NUMBER: 26,
  PENDING_MEMBER_REVIEW_LESS_THAN_ACCEPTABLE_AMOUNT: 27,
  PENDING_MEMBER_REVIEW_DROPOUT: 30,
};

export const OCRVerificationStageKeys = {
  PENDING_FORM_RECOGNIZER_OCR: 'PENDING_FORM_RECOGNIZER_OCR',
  PENDING_AUTO_VALIDATION: 'PENDING_AUTO_VALIDATION',
  DECLINE_NO_RECEIPT_FOUND: 'DECLINE_NO_RECEIPT_FOUND',
  DECLINE_MULTIPLE_RECEIPTS_DETECTED: 'DECLINE_MULTIPLE_RECEIPTS_DETECTED',
  DECLINE_UNCLEAR_RECEIPT: 'DECLINE_UNCLEAR_RECEIPT',
  // DECLINE_LOW_MAIN_FEATURE_CONFIDENCE: 'DECLINE_LOW_MAIN_FEATURE_CONFIDENCE',
  DECLINE_HANDWRITING_DETECTED: 'DECLINE_HANDWRITING_DETECTED',
  DECLINE_BUG_MISSING_TRANSACTION_DATE: 'DECLINE_BUG_MISSING_TRANSACTION_DATE',
  DECLINE_TOTAL_NOT_TALLY: 'DECLINE_TOTAL_NOT_TALLY',
  // DECLINE_FRAUD: 'DECLINE_FRAUD',
  DECLINE_EXPIRED_RECEIPT: 'DECLINE_EXPIRED_RECEIPT',
  DECLINE_BUG_MISSING_IP_NAME: 'DECLINE_BUG_MISSING_IP_NAME',
  DECLINE_NO_IP_FOUND: 'DECLINE_NO_IP_FOUND',
  DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT: 'DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT',
  DECLINE_BUG_BILL_AMOUNT_NOT_MATCH: 'DECLINE_BUG_BILL_AMOUNT_NOT_MATCH',
  DECLINE_DUPLICATED_RECEIPT: 'DECLINE_DUPLICATED_RECEIPT',
  PENDING_MEMBER_REVIEW: 'PENDING_MEMBER_REVIEW',
  MANUAL_CHECK_REQUIRED: 'MANUAL_CHECK_REQUIRED',
  // APPROVED: 'APPROVED',
  // MANUAL_REJECTION: 'MANUAL_REJECTION',
  AZURE_ERROR: 'AZURE_ERROR',
  DECLINE_BUG_INVALID_ID: 'DECLINE_BUG_INVALID_ID',
  DECLINE_BUG_INVALID_TABLE: 'DECLINE_BUG_INVALID_TABLE',
  DECLINE_BUG_INVALID_GST_NUMBER: 'DECLINE_BUG_INVALID_GST_NUMBER',
};

export const OCRStatusLabel = {
  [OCRStatus.PENDING_FORM_RECOGNIZER_OCR]: 'Pending Analysis',
  [OCRStatus.PENDING_AUTO_VALIDATION]: 'Pending Validation',
  [OCRStatus.PENDING_MEMBER_REVIEW]: "Pending member's review",
  [OCRStatus.PENDING_MEMBER_REVIEW_LESS_THAN_ACCEPTABLE_AMOUNT]:
    'Pending member’s review',
  [OCRStatus.PENDING_MEMBER_REVIEW_DROPOUT]: 'Pending member’s review',
  [OCRStatus.DECLINE_NEGATIVE_KEYWORD_FOUND]: 'Receipt type is not applicable',
  [OCRStatus.DECLINE_NO_RECEIPT_FOUND]: 'No receipt detected',
  [OCRStatus.DECLINE_MULTIPLE_RECEIPTS_DETECTED]: 'Multiple receipts detected',
  [OCRStatus.DECLINE_HANDWRITING_DETECTED]: 'Handwriting detected',
  [OCRStatus.DECLINE_UNCLEAR_RECEIPT]: 'Receipt unclear',
  [OCRStatus.DECLINE_EXPIRED_RECEIPT]: 'Receipt expired',
  [OCRStatus.DECLINE_NO_IP_FOUND]: 'Non-participating IP',
  [OCRStatus.DECLINE_DUPLICATED_RECEIPT]: 'Duplicate receipt by member',
  [OCRStatus.DECLINE_DIFFUSER_DUPLICATED_RECEIPT]:
    'Duplicate receipt by another member',
  [OCRStatus.DECLINE_TOTAL_NOT_TALLY]: 'Bill Amount do not tally',
  [OCRStatus.DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT]: 'Attempt',
  [OCRStatus.DECLINE_BUG_MISSING_IP_NAME]: 'Missing IP Name',
  [OCRStatus.DECLINE_BUG_MISSING_TRANSACTION_DATE]: 'Missing Transaction date',
  [OCRStatus.DECLINE_BUG_BILL_AMOUNT_NOT_MATCH]: 'Bill amount do not match',
  [OCRStatus.DECLINE_BUG_INVALID_ID]: 'Invalid id',
  [OCRStatus.DECLINE_BUG_INVALID_TABLE]: 'Invalid table',
  [OCRStatus.DECLINE_BUG_INVALID_GST_NUMBER]: 'Invalid GST number',
  [OCRStatus.APPROVED]: 'Approved',
  [OCRStatus.APPROVED_LESS_THAN_ACCEPTABLE_AMOUNT]: 'Submitted < $20',
  [OCRStatus.MANUAL_REJECTION]: 'Rejected by Admin',
  [OCRStatus.MANUAL_CHECK_REQUIRED]: 'Manual check required',
  [OCRStatus.AZURE_ERROR]: 'Unable to process receipt (Azure error)',
  [OCRStatus.PENDING_OCRTIMEOUT]: 'Timeout (OCR scan processing)',
};

export const OCRStatusLabelColor = {
  [OCRStatus.PENDING_FORM_RECOGNIZER_OCR]: 'warning',
  [OCRStatus.PENDING_AUTO_VALIDATION]: 'warning',
  [OCRStatus.PENDING_MEMBER_REVIEW]: 'warning',
  [OCRStatus.PENDING_MEMBER_REVIEW_LESS_THAN_ACCEPTABLE_AMOUNT]: 'warning',
  [OCRStatus.PENDING_MEMBER_REVIEW_DROPOUT]: 'warning',
  [OCRStatus.DECLINE_NEGATIVE_KEYWORD_FOUND]: 'error',
  [OCRStatus.DECLINE_NO_RECEIPT_FOUND]: 'error',
  [OCRStatus.DECLINE_MULTIPLE_RECEIPTS_DETECTED]: 'error',
  [OCRStatus.DECLINE_HANDWRITING_DETECTED]: 'error',
  [OCRStatus.DECLINE_UNCLEAR_RECEIPT]: 'error',
  [OCRStatus.DECLINE_EXPIRED_RECEIPT]: 'error',
  [OCRStatus.DECLINE_NO_IP_FOUND]: 'error',
  [OCRStatus.DECLINE_DUPLICATED_RECEIPT]: 'error',
  [OCRStatus.DECLINE_DIFFUSER_DUPLICATED_RECEIPT]: 'error',
  [OCRStatus.DECLINE_TOTAL_NOT_TALLY]: 'error',
  [OCRStatus.DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT]: 'error',
  [OCRStatus.DECLINE_BUG_MISSING_IP_NAME]: 'error',
  [OCRStatus.DECLINE_BUG_MISSING_TRANSACTION_DATE]: 'error',
  [OCRStatus.DECLINE_BUG_BILL_AMOUNT_NOT_MATCH]: 'error',
  [OCRStatus.DECLINE_BUG_INVALID_ID]: 'error',
  [OCRStatus.DECLINE_BUG_INVALID_TABLE]: 'error',
  [OCRStatus.DECLINE_BUG_INVALID_GST_NUMBER]: 'error',
  [OCRStatus.APPROVED]: 'error',
  [OCRStatus.APPROVED_LESS_THAN_ACCEPTABLE_AMOUNT]: 'error',
  [OCRStatus.MANUAL_REJECTION]: 'error',
  [OCRStatus.MANUAL_CHECK_REQUIRED]: 'error',
  [OCRStatus.AZURE_ERROR]: 'error',
  [OCRStatus.PENDING_OCRTIMEOUT]: 'error',
};

export const OCRStatusFilter = {
  PENDING_FORM_RECOGNIZER_OCR: 1,
  PENDING_AUTO_VALIDATION: 2,
  DECLINE_NO_RECEIPT_FOUND: 3,
  DECLINE_MULTIPLE_RECEIPTS_DETECTED: 4,
  DECLINE_HANDWRITING_DETECTED: 5,
  DECLINE_UNCLEAR_RECEIPT: 6,
  DECLINE_EXPIRED_RECEIPT: 7,
  DECLINE_NO_IP_FOUND: 8,
  DECLINE_DUPLICATED_RECEIPT: 9,
  DECLINE_DIFFUSER_DUPLICATED_RECEIPT: 10,
  DECLINE_TOTAL_NOT_TALLY: 11,
  DECLINE_BUG_MISSING_TRANSACTION_DATE: 12,
  DECLINE_BUG_BILL_AMOUNT_NOT_MATCH: 13,
  PENDING_MEMBER_REVIEW: 14,
  MANUAL_REJECTION: 15, // not have filter label
  AZURE_ERROR: 16,
  PENDING_OCRTIMEOUT: 17,
  APPROVED_LESS_THAN_ACCEPTABLE_AMOUNT: 18,
  MANUAL_CHECK_REQUIRED: 19, // not have filter label
  APPROVED: 20, // not have filter label
  DECLINE_LESS_THAN_ACCEPTABLE_AMOUNT: 21, // not have filter label
  DECLINE_NEGATIVE_KEYWORD_FOUND: 22,
};

export const OCRStatusFilterLabel = {
  [OCRStatusFilter.PENDING_FORM_RECOGNIZER_OCR]: 'Pending Analysis',
  [OCRStatusFilter.PENDING_AUTO_VALIDATION]: 'Pending Validation',
  [OCRStatusFilter.DECLINE_NO_RECEIPT_FOUND]: 'No receipt detected',
  [OCRStatusFilter.DECLINE_MULTIPLE_RECEIPTS_DETECTED]:
    'Multiple receipts detected',
  [OCRStatusFilter.DECLINE_HANDWRITING_DETECTED]: 'Handwriting detected',
  [OCRStatusFilter.DECLINE_UNCLEAR_RECEIPT]: 'Receipt unclear',
  [OCRStatusFilter.DECLINE_EXPIRED_RECEIPT]: 'Receipt expired',
  [OCRStatusFilter.DECLINE_NO_IP_FOUND]: 'Non-participating IP',
  [OCRStatusFilter.DECLINE_DUPLICATED_RECEIPT]: 'Duplicate receipt by member',
  [OCRStatusFilter.DECLINE_DIFFUSER_DUPLICATED_RECEIPT]:
    'Duplicate receipt by another member',
  [OCRStatusFilter.DECLINE_TOTAL_NOT_TALLY]: 'Bill Amount do not tally',
  [OCRStatusFilter.DECLINE_BUG_MISSING_TRANSACTION_DATE]:
    'Missing Transaction date',
  [OCRStatusFilter.DECLINE_BUG_BILL_AMOUNT_NOT_MATCH]: 'Missing Total amount',
  [OCRStatusFilter.PENDING_MEMBER_REVIEW]: "Pending member's review",
  [OCRStatusFilter.AZURE_ERROR]: 'Unable to process receipt (Azure error)',
  [OCRStatusFilter.PENDING_OCRTIMEOUT]: 'Timeout (OCR scan processing)',
  [OCRStatusFilter.APPROVED_LESS_THAN_ACCEPTABLE_AMOUNT]: 'Submitted < $20',
  [OCRStatusFilter.DECLINE_NEGATIVE_KEYWORD_FOUND]:
    'Receipt type is not applicable',
};

export const OCRRejectReasons = {
  RECEIPT_COULD_NOT_BE_DETECTED: 1,
  MULTIPLE_RECEIPT_DETECTED: 2,
  RECEIPT_HAS_BEEN_ALTERED: 3,
  RECEIPT_MISSING_MERCHANT_DETAILS: 4,
  RECEIPT_MISSING_TRANSACTION_NUMBER_DETAILS: 5,
  RECEIPT_NOT_FROM_PARTICIPATING_MERCHANT: 6,
  INFORMATION_WAS_NOT_CLEARLY_CAPTURED: 7,
  RECEIPT_IS_EXPIRED: 8,
  RECEIPT_WAS_UPLOADED_BEFORE_BY_YOU: 9,
  RECEIPT_WAS_UPLOADED_BEFORE_BY_ANOTHER_MEMBER: 10,
  RECEIPT_TYPE_NOT_APPLICABLE: 11,
};

export const OCRRejectReasonsLabel = [
  'Receipt couldn’t be detected',
  'Multiple receipts detected',
  'Receipt has been altered',
  'Receipt has missing detail(s): Merchant name and/or merchant address',
  'Receipt has missing detail(s): Transaction number',
  'Receipt is not from a participating outlet',
  'Information on the receipt was not clearly captured',
  'Receipt is expired',
  'Receipt was previously uploaded by you',
  'Receipt was previously uploaded by another member',
  'Receipt type from participating outlet not applicable',
];

export const DisplayFieldsLabel = {
  ReceiptId: 'Receipt/Order number',
  ReceiptDateOfPurchase: 'Receipt date/time',
  ReceiptTotal: 'Total amount',
  MatchedMerchantID: 'Islander Partner Name/Address/Operating Area',
};
