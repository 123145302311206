/* eslint-disable @typescript-eslint/no-explicit-any */
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Box, FormControl, Grid} from '@mui/material';
import {Modal, Button} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from '../ModalConfirmCashout/ModalConfirmCashout.style';

interface ModalConfirmCashoutProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm?: (any) => void;
}

function ModalConfirmCashout({
  visible,
  onCancel,
  onConfirm,
}: ModalConfirmCashoutProps) {
  return (
    <Modal visible={visible} onClose={onCancel} maxWidth="sm">
      <Box padding="64px 48px 40px 48px" textAlign="center">
        <StyledIconClose onClick={onCancel}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sx={{width: '100%'}}>
              <Box marginBottom={5}>
                <img src={images.icon_cicle_warning} width={100} height={100} />
              </Box>
              <Typography variant="h3">Confirm reimbursement?</Typography>
              <FormControl variant="standard" fullWidth>
                <Typography variant="body2" mb={2} mt={3}>
                  This action cannot be undone.
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <StyledModalActions>
            <div className="btn-action-1">
              <Button variant="outlined" type="button" onClick={onCancel}>
                No
              </Button>
            </div>
            <div className="btn-action-2">
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  if (onConfirm) {
                    onConfirm(true);
                    onCancel();
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </StyledModalActions>
        </DialogContent>
      </Box>
    </Modal>
  );
}
export default ModalConfirmCashout;
